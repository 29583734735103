import { ContentDataType } from './saleChartTooltipTypes';
import React, { PropsWithChildren } from 'react';
import { Text } from '@ydistri/ds';

interface TooltipContentTextProps extends PropsWithChildren {
  type: ContentDataType;
}

/**
 * Displays NON_SALE_DATA in small grey text, everything else in default text
 * @param type
 * @param children
 * @constructor
 */
const TooltipContentText: React.FC<TooltipContentTextProps> = ({ type, children }) => {
  return (
    <Text
      $semiBold={true}
      $type={type === ContentDataType.NON_SALE_DATA ? 'light' : undefined}
      $size={type === ContentDataType.NON_SALE_DATA ? 'xSmall' : undefined}
    >
      {children}
    </Text>
  );
};

export default TooltipContentText;
