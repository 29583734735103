import { Column, Section } from '@ydistri/ds';
import { styled } from 'styled-components';
import React, { useCallback, useEffect, useState } from 'react';
import useCategoryId from '../../../../hooks/useCategoryId';
import ProductsList from './components/ProductsList';
import { ProductResponse } from '@ydistri/api-sdk';
import SelectedProductDetails from './components/SelectedProductDetails';
import { URLSearchParamsInit, useSearchParams } from 'react-router';
import SearchPanel from './components/SearchPanel';
import { resetSku, setIsLoadingSalesData, setProductId } from '../../detailSlice';
import { useGetSingleProductQuery } from '../../apiDetail';
import { UrlParams } from '../../detailTypes';
import { useDetail } from '../../hooks/useDetail';
import { NO_VALUE } from '../../../../lib/utils/utilsTypes';
import { useAppDispatch } from '../../../../store';

const Content = styled(Column)`
  width: 25rem;
`;

/**
 * Displays a list of products in the selected category.
 * <p>Allows to search the product and when a number is entered, it will search for the sku with that number.</p>
 * @constructor
 */
const DetailProductsListSection: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState<string>(() => {
    if (searchParams.has(UrlParams.SEARCH)) {
      return searchParams.get(UrlParams.SEARCH) ?? '';
    } else {
      return '';
    }
  });

  const { productId, storeId } = useDetail('productId', 'storeId');
  const { data: productEntity } = useGetSingleProductQuery(productId);
  const categoryId = useCategoryId();

  const deselectProduct = useCallback(() => {
    setSearchParams((prevParams): URLSearchParamsInit => {
      if (prevParams.has(UrlParams.PRODUCT)) {
        prevParams.delete(UrlParams.PRODUCT);
      }
      if (prevParams.has(UrlParams.SKU)) {
        prevParams.delete(UrlParams.SKU);
      }
      return prevParams;
    });
    dispatch(setProductId(NO_VALUE));
  }, [dispatch, setSearchParams]);

  const onProductSelected = useCallback(
    (product: ProductResponse) => {
      dispatch(setIsLoadingSalesData(true));

      setSearchParams((prevParams): URLSearchParamsInit => {
        const newProductValue = product.id.toString();
        prevParams.set(UrlParams.PRODUCT, newProductValue);
        if (prevParams.has(UrlParams.SKU)) {
          prevParams.delete(UrlParams.SKU);
          prevParams.set(UrlParams.STORE, storeId.toString());
          dispatch(resetSku());
        }

        //when product is selected, deselect the region as the region selector is hidden
        if (prevParams.has(UrlParams.REGION)) {
          prevParams.delete(UrlParams.REGION);
        }
        return prevParams;
      });
    },
    [dispatch, setSearchParams, storeId],
  );

  const onSearch = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  useEffect(() => {
    setSearchParams((prevParams): URLSearchParamsInit => {
      if (searchQuery.length > 0) {
        prevParams.set(UrlParams.SEARCH, searchQuery);
      } else {
        if (prevParams.has(UrlParams.SEARCH)) {
          prevParams.delete(UrlParams.SEARCH);
        }
      }
      return prevParams;
    });
  }, [searchQuery, setSearchParams]);

  const onSkuSearch = useCallback(
    (skuId: number) => {
      deselectProduct();
      setSearchQuery('');
      setSearchParams((prevParams): URLSearchParamsInit => {
        if (prevParams.has(UrlParams.PRODUCT)) {
          prevParams.delete(UrlParams.PRODUCT);
        }
        if (prevParams.has(UrlParams.STORE)) {
          prevParams.delete(UrlParams.STORE);
        }

        //to avoid having both sku and product in the url at once
        //thus causing multiple renders
        if (prevParams.has(UrlParams.SEARCH)) {
          prevParams.delete(UrlParams.SEARCH);
        }

        prevParams.set(UrlParams.SKU, skuId.toString());
        return prevParams;
      });
    },
    [deselectProduct, setSearchParams],
  );

  return (
    <Section header="Products in selected category" $ratio={0}>
      <Content $flexGrow={1} $flexWrap="nowrap" $gap="1rem">
        <SearchPanel value={searchQuery} onSearch={onSearch} onSkuSearch={onSkuSearch} />
        {productEntity && (
          <SelectedProductDetails product={productEntity} onProductDeselected={deselectProduct} />
        )}
        <ProductsList
          categoryId={categoryId}
          searchQuery={searchQuery.length > 0 ? searchQuery : undefined}
          onProductSelected={onProductSelected}
          selectedProductId={productEntity?.id}
        />
      </Content>
    </Section>
  );
};

export default DetailProductsListSection;
