import React, { useCallback } from 'react';
import { ProductListResponse } from '@ydistri/api-sdk';
import { useParams } from 'react-router';
import { RoutingPage } from '../../../../routes/routerSlice';
import { ProjectAdministrationSubpage } from '../../projectAdministrationLib';
import AdministrationItemsList from '../../common/catalog/AdministrationItemsList';

interface ProductListsListProps {
  data?: ProductListResponse[];
  onItemClick?: (targetList: ProductListResponse) => void;
  selectedItem?: ProductListResponse;
  loading?: boolean;
}

const ProductListsList: React.FC<ProductListsListProps> = ({ data, loading, selectedItem }) => {
  const countableData = useCallback((item: ProductListResponse) => {
    return [{ count: item.productCount, description: 'product' }];
  }, []);

  const { projectShortName } = useParams();

  const linkProvider = useCallback(
    (item: ProductListResponse) =>
      `/${projectShortName ?? ''}/${RoutingPage.PROJECT_ADMINISTRATION}/${
        ProjectAdministrationSubpage.PRODUCTLISTS
      }/${item.productListId}`,
    [projectShortName],
  );

  const idProvider = useCallback((item: ProductListResponse) => item.productListId, []);

  const itemSelected = useCallback(
    (item: ProductListResponse) => {
      return selectedItem?.productListId === item.productListId;
    },
    [selectedItem],
  );

  return (
    <AdministrationItemsList<ProductListResponse>
      data={data}
      loading={loading}
      countableDataProvider={countableData}
      linkProvider={linkProvider}
      idProvider={idProvider}
      itemSelected={itemSelected}
      data-testid="ProductListsList"
    />
  );
};

export default ProductListsList;
