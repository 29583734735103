import { format } from 'date-fns';

export const fullMonthDiff = (dateFrom: Date | undefined, dateTo: Date | undefined): number => {
  let monthDifference = 0;

  if (dateFrom && dateTo) {
    monthDifference =
      dateTo.getMonth() -
      dateFrom.getMonth() +
      12 * (dateTo.getFullYear() - dateFrom.getFullYear());
    if (dateFrom.getDate() > dateTo.getDate()) {
      monthDifference--;
    }
    if (monthDifference < 0) {
      monthDifference = 0;
    }
  }
  return monthDifference;
};

export function getNewerDate(date1: Date | undefined, date2: Date | undefined): Date | undefined {
  if (date1 && date2) {
    if (date1 > date2) {
      return date1;
    }
    return date2;
  }
  if (date1) {
    return date1;
  }
  return date2;
}

export function formatDate(date: Date | string): string {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return format(date, 'dd. MM. yyyy');
}

export function formatDateTime(date: Date | string): string {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return format(date, 'd. M. yyyy HH:mm');
}

export function getCurrentDateYYYYMMDD(): string {
  const date: Date = new Date();
  return format(date, 'yyyyMMdd');
}
