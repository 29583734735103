import React from 'react';
import { Outlet } from 'react-router';
import MenuLeft from '../components/menu/MenuLeft';
import MenuTop from '../components/menu/MenuTop';
import { LayoutFull, LayoutHorizontal, LayoutVerticalMain } from '@ydistri/ds';

const PageLayout: React.FC = () => {
  return (
    <LayoutFull data-type="LayoutFull">
      <LayoutHorizontal>
        <MenuLeft />
        <LayoutVerticalMain data-type="LayoutVertical">
          <MenuTop />
          <Outlet />
        </LayoutVerticalMain>
      </LayoutHorizontal>
    </LayoutFull>
  );
};

export default PageLayout;
