import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { filterByOutOfStock } from '../../../../../../Calculations/calculationsSlice';
import { LabeledToggle, LabelPlacement } from '@ydistri/ds';
import { useAppDispatch } from '../../../../../../../store';

/**
 * Controls the Out-Of-Stock SKU filter setting in the calculation slice.
 * <p>When the filter is set, only out of stock skus are shown.</p>
 */
const OutOfStockSKUFilter: React.FC = () => {
  const redistributionFilterOutOfStock = useSelector(
    state => state.calculations.redistributionFilterOutOfStock,
  );
  const dispatch = useAppDispatch();

  const clickHandler = useCallback(() => {
    dispatch(filterByOutOfStock(!redistributionFilterOutOfStock));
  }, [dispatch, redistributionFilterOutOfStock]);

  return (
    <LabeledToggle
      label="Show only out-of-stock SKUs"
      checked={redistributionFilterOutOfStock}
      labelPlacement={LabelPlacement.RIGHT}
      onClick={clickHandler}
    />
  );
};

export default OutOfStockSKUFilter;
