import { ContentData, ContentDataType } from './saleChartTooltipTypes';
import React from 'react';
import { TChartBarInfo, TChartLineInfo } from '../../../lib/charts/saleChartsTypes';
import ChartLineLegendGraphic from './ChartLineLegendGraphic';
import {
  ChartLineLegendCell,
  ContentTableRow,
  LineLabelCell,
  ValueCell,
} from './TooltipContentHelperComponents';
import TooltipContentText from './TooltipContentText';

interface ContentRowProps {
  data: ContentData;
  columnWidths?: number[];
}

const TooltipContentRow: React.FC<ContentRowProps> = ({ data, columnWidths = [] }) => {
  const { line } = data;

  let info: TChartLineInfo | TChartBarInfo | undefined = undefined;
  if (line) {
    if ('lineInfo' in line) {
      info = line.lineInfo;
    } else if ('barInfo' in line) {
      info = line.barInfo;
    }
  }

  return (
    <>
      <ContentTableRow $isTotal={data.type === ContentDataType.TOTAL}>
        <ChartLineLegendCell>
          {info && <ChartLineLegendGraphic $lineInfo={info} />}
        </ChartLineLegendCell>
        <LineLabelCell>
          <TooltipContentText type={data.type}>{data.label}</TooltipContentText>
        </LineLabelCell>
        {data.columnValues.map((value, i) => (
          // eslint-disable-next-line react/no-array-index-key -- needed in this case, unless we rework it
          <ValueCell key={`${data.label}-${i}`}>
            <TooltipContentText type={data.type}>{value}</TooltipContentText>
          </ValueCell>
        ))}
      </ContentTableRow>
      {data.supplementaryData?.map(supplementaryData => (
        <TooltipContentRow
          data={supplementaryData}
          key={supplementaryData.label}
          columnWidths={columnWidths}
        />
      ))}
    </>
  );
};

export default TooltipContentRow;
