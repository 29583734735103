/* eslint-disable @typescript-eslint/naming-convention -- we use currency codes on purpose here */
import { formatValueOrQuantity } from './formatValueOrQuantity';
import { formatNumber } from './formatNumber';

interface CurrencyFormatInfo {
  currencySymbol: string;
  isBefore: boolean;
}

export const CURRENCY_FORMATS: Record<string, CurrencyFormatInfo | undefined> = {
  EUR: {
    currencySymbol: '€',
    isBefore: false,
  },
  USD: {
    currencySymbol: '$',
    isBefore: true,
  },
  GBP: {
    currencySymbol: '£',
    isBefore: true,
  },
  JPY: {
    currencySymbol: '¥',
    isBefore: true,
  },
  CNY: {
    currencySymbol: '¥',
    isBefore: true,
  },
  CZK: {
    currencySymbol: 'Kč',
    isBefore: false,
  },
  PLN: {
    currencySymbol: 'zł',
    isBefore: false,
  },
  AUD: {
    currencySymbol: '$',
    isBefore: true,
  },
  DKK: {
    currencySymbol: 'kr',
    isBefore: true,
  },
  HRK: {
    currencySymbol: 'kn',
    isBefore: true,
  },
  CAD: {
    currencySymbol: '$',
    isBefore: true,
  },
  HUF: {
    currencySymbol: 'Ft',
    isBefore: true,
  },
  NOK: {
    currencySymbol: 'kr',
    isBefore: true,
  },
  RON: {
    currencySymbol: 'lei',
    isBefore: true,
  },
  SEK: {
    currencySymbol: 'kr',
    isBefore: true,
  },
  TRY: {
    currencySymbol: '₺',
    isBefore: true,
  },
  CHF: {
    currencySymbol: 'CHF',
    isBefore: true,
  },
  MXN: {
    currencySymbol: 'MXN',
    isBefore: true,
  },
};

const unknownCurrency = {
  currencySymbol: '...',
  isBefore: false,
};

export const currencyFormatter = (
  currency: string | undefined,
  value: number,
  isShortened: boolean = true,
): string => {
  const formattedAmount = isShortened ? formatValueOrQuantity(value) : formatNumber(value, 0);

  const currencyFormatInfo =
    currency && CURRENCY_FORMATS[currency] ? CURRENCY_FORMATS[currency] : unknownCurrency;

  const { currencySymbol, isBefore } = currencyFormatInfo;
  if (isBefore) return `${currencySymbol} ${formattedAmount}`;

  return `${formattedAmount} ${currencySymbol}`;
};
