import React, { useCallback, useMemo } from 'react';
import { Row } from '@ydistri/ds';
import StoreSelector, { StoreOption } from './StoreSelector';
import { useSearchParams } from 'react-router';
import { useDetail } from '../../../../hooks/useDetail';
import { useTemplateOrCalculation } from '../../../../../../hooks/useTemplateOrCalculation';
import { useGetRegionsQuery, useGetStoresQuery } from '../../../../../../apis/apiLists';
import { useDisplayStoreLabelFormatter } from '../../../../../../hooks/useDisplayStoreLabel';

interface StoreSelectorControllerProps {
  onChange?: (value: number) => void;
}

const StoreSelectorController: React.FC<StoreSelectorControllerProps> = ({ onChange }) => {
  const forcedTemplate = useTemplateOrCalculation('Template');
  const { data: stores } = useGetStoresQuery(forcedTemplate);

  const { storeId, regionId } = useDetail('storeId', 'regionId');
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const { data: regionEntities } = useGetRegionsQuery(forcedTemplate);
  const [, setSearchParams] = useSearchParams();
  const storeLabelFormatter = useDisplayStoreLabelFormatter();

  const storesOptions: StoreOption[] = useMemo(() => {
    if (!stores) {
      return [];
    }

    const regionStores =
      regionId !== -1 ? stores.array.filter(store => store.region?.id === regionId) : stores.array;

    return regionStores
      .map(store => ({
        id: store.id,
        name: store.name,
        code: store.code ?? null,
        customerStoreId: store.customerStoreId ?? null,
        label: storeLabelFormatter(store.name, store.customerStoreId, store.code),
      }))
      .sort((left, right) => left.name.localeCompare(right.name));
  }, [regionId, storeLabelFormatter, stores]);

  const changeHandler = useCallback(
    (newValue: number) => {
      setSearchParams(prevParams => {
        if (newValue !== -1) {
          prevParams.set('store', newValue.toString());
        } else {
          prevParams.delete('store');
        }
        return prevParams;
      });

      if (onChange) {
        onChange(newValue);
      }
    },
    [onChange, setSearchParams],
  );

  const noSelectionLabel = useMemo(() => {
    if (regionId !== -1) {
      const region = regionEntities?.object[regionId];
      if (region) {
        return `All stores in ${region.name}`;
      }
    }
  }, [regionEntities, regionId]);

  return (
    <Row $gap="0.5rem" $alignItems="center">
      <StoreSelector
        options={storesOptions}
        onChange={changeHandler}
        value={storeId}
        noSelectionLabel={noSelectionLabel}
      />
    </Row>
  );
};

export default StoreSelectorController;
