import { SkuAttrs } from '../../../../../../lib/sku/skuTypes';
import React, { useMemo } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Column, computeRemSize, Label, Row, Text } from '@ydistri/ds';
import CountableText from '../../../../../../components/global/CountableText/CountableText';
import { styled } from 'styled-components';
import { formatDate, fullMonthDiff } from '@ydistri/utils';
import Price from '../../../../../../components/domain/SKUTable/Price';
import { useCurrency } from '../../../../../../hooks/useCurrency';
import { useGetSkuEntityListsQuery } from '../../../../apiDetail';
import { EntitiesSimpleTable, EntitiesTableWrapper } from '../EntitiesTable';

interface SkuAttributesProps {
  skuEntity: SkuAttrs;
  applicationDate?: Date;
}

interface CompoundValue {
  label: string;
  value: React.ReactNode;
}

const MinLayersTable = styled.table`
  max-width: ${computeRemSize(300)};
`;

const MUQuantityCell = styled.td`
  min-width: 4rem;
`;

const DetailSkuAttributes: React.FC<SkuAttributesProps> = ({
  skuEntity,
  applicationDate = new Date(),
}) => {
  const currency = useCurrency();
  const { data: entityLists } = useGetSkuEntityListsQuery(skuEntity.id);

  const columns = useMemo((): ColumnsType<CompoundValue> => {
    return [
      {
        dataIndex: 'label',
        width: '13rem',
        render: (text: string) => {
          return <Label>{text}:</Label>;
        },
      },
      {
        dataIndex: 'value',
        render: (value: React.ReactNode) => {
          if (typeof value === 'string') {
            return <Text>{value}</Text>;
          } else {
            return value;
          }
        },
      },
    ];
  }, []);

  const data = useMemo((): CompoundValue[] => {
    const result: CompoundValue[] = [
      {
        label: ' Available supply',
        value: <Label>{`${skuEntity.values.AvailableSupply ?? '-'} MU`}</Label>,
      },
      {
        label: 'Open purchase order',
        value: <Label>{`${skuEntity.values.OpenPurchaseOrdersQuantity ?? '-'} MU`}</Label>,
      },
      {
        label: 'Is Forced',
        value: <Label>{skuEntity.values.IsForced === '1' ? 'yes' : 'no'}</Label>,
      },

      {
        label: 'Sale frequency',
        value: (
          <Column>
            <CountableText
              value={skuEntity.values.Frequency6Month}
              highlighting="both"
              word="sale"
              suffix="in last 6 months"
            />

            <CountableText
              value={skuEntity.values.Frequency12Month}
              highlighting="both"
              word="sale"
              suffix="in last 12 months"
            />
          </Column>
        ),
      },
    ];

    const saleValues = [];
    if (skuEntity.dates.dateOfFirstSale) {
      saleValues.push(
        <CountableText
          key="dateOfFirstSale"
          value={fullMonthDiff(skuEntity.dates.dateOfFirstSale, applicationDate)}
          word="month"
          highlighting="both"
          suffix={`from first sale (${formatDate(skuEntity.dates.dateOfFirstSale)})`}
        />,
      );
    }

    if (skuEntity.dates.dateOfLastSale) {
      saleValues.push(
        <CountableText
          key="dateOfLastSale"
          value={fullMonthDiff(skuEntity.dates.dateOfLastSale, applicationDate)}
          word="month"
          highlighting="both"
          suffix={`from last sale (${formatDate(skuEntity.dates.dateOfLastSale)})`}
        />,
      );
    }

    result.push({
      label: 'Sales',
      value: saleValues.length > 0 ? <Column>{saleValues}</Column> : 'No sales info',
    });

    const purchaseValues = [];
    if (skuEntity.dates.dateOfFirstPurchase) {
      purchaseValues.push(
        <CountableText
          key="dateOfFirstPurchase"
          value={fullMonthDiff(skuEntity.dates.dateOfFirstPurchase, applicationDate)}
          word="month"
          highlighting="both"
          suffix={`from first purchase (${formatDate(skuEntity.dates.dateOfFirstPurchase)})`}
        />,
      );
    }

    if (skuEntity.dates.dateOfLastPurchase) {
      purchaseValues.push(
        <CountableText
          key="dateOfLastPurchase"
          value={fullMonthDiff(skuEntity.dates.dateOfLastPurchase, applicationDate)}
          word="month"
          highlighting="both"
          suffix={`from last purchase (${formatDate(skuEntity.dates.dateOfLastPurchase)})`}
        />,
      );
    }

    result.push({
      label: 'Purchases',
      value: purchaseValues.length > 0 ? <Column>{purchaseValues}</Column> : 'No purchase info',
    });

    return result;
  }, [
    applicationDate,
    skuEntity.dates.dateOfFirstPurchase,
    skuEntity.dates.dateOfFirstSale,
    skuEntity.dates.dateOfLastPurchase,
    skuEntity.dates.dateOfLastSale,
    skuEntity.values.AvailableSupply,
    skuEntity.values.Frequency12Month,
    skuEntity.values.Frequency6Month,
    skuEntity.values.OpenPurchaseOrdersQuantity,
    skuEntity.values.IsForced,
  ]);

  const dataTable2 = useMemo(() => {
    let salePriceWithVat = 0;
    if (skuEntity.values.Vat && skuEntity.values.SalePrice) {
      const salePrice = parseFloat(skuEntity.values.SalePrice);
      const vat = parseFloat(skuEntity.values.Vat);
      salePriceWithVat = salePrice * (1 + vat / 100);
    }

    const salePriceWithVatComponent = (
      <Row $gap="0">
        <Text>(</Text>
        <Price
          label="VAT"
          labelWidth={computeRemSize(28)}
          spaceBetweenLabelAndValue={computeRemSize(8)}
          currency={currency}
          price={salePriceWithVat}
        />
        <Text>)</Text>
      </Row>
    );

    const result: CompoundValue[] = [
      {
        label: 'Minimum layers',
        value: (
          <MinLayersTable>
            <tbody>
              {(entityLists ?? []).map(el => (
                <tr key={el.entityListVersionId}>
                  <td>
                    <Text $semiBold>{el.name}</Text>
                  </td>
                  <MUQuantityCell>
                    <Text>{el.quantity} MU</Text>
                  </MUQuantityCell>
                </tr>
              ))}
            </tbody>
          </MinLayersTable>
        ),
      },
      {
        label: `Prices`,
        value: (
          <Column>
            <Price
              label="Cost price"
              currency={currency}
              price={skuEntity.values.StockPrice}
              suffix="(without VAT)"
            />
            <Price
              label="Sale price"
              currency={currency}
              price={skuEntity.values.SalePrice}
              suffix={salePriceWithVatComponent}
            />
            <Price label="VAT" currency="%" price={skuEntity.values.Vat} />
          </Column>
        ),
      },
    ];

    return result;
  }, [
    entityLists,
    currency,
    skuEntity.values.StockPrice,
    skuEntity.values.SalePrice,
    skuEntity.values.Vat,
  ]);

  return (
    <>
      <EntitiesTableWrapper>
        <EntitiesSimpleTable
          id="DetailSkuAttributes"
          columns={columns}
          dataSource={data}
          rowKey="label"
        />
      </EntitiesTableWrapper>
      <EntitiesTableWrapper>
        <EntitiesSimpleTable
          id="DetailSkuAttributesTable2"
          columns={columns}
          dataSource={dataTable2}
          rowKey="label"
        />
      </EntitiesTableWrapper>
    </>
  );
};

export default DetailSkuAttributes;
