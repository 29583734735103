import React, { useCallback } from 'react';
import { Button, IconButton_IconSizes } from '@ydistri/ds';
import { MdWarning } from 'react-icons/md';
import { AvailableModals, openModal } from '../../Modals/modalSlice';
import { useStoreSaleNotification } from './useStoreSaleNotification';
import { plural } from '@ydistri/utils';
import { useAppDispatch } from '../../../store';

const SalesNoticeButton: React.FC = () => {
  const dispatch = useAppDispatch();

  const { storesToNotify, isFetchingStores } = useStoreSaleNotification();

  const openStoreInfoHandler = useCallback(() => {
    dispatch(
      openModal({
        type: AvailableModals.STORE_SALE_INFO,
        data: undefined,
      }),
    );
  }, [dispatch]);

  if (storesToNotify.length === 0) return null;

  return (
    <Button
      $colorVariant="ghostDanger"
      onClick={openStoreInfoHandler}
      data-testid="store_info"
      loading={isFetchingStores}
    >
      <MdWarning size={IconButton_IconSizes.small} />
      {plural(storesToNotify.length, `${storesToNotify.length} flagged store`)}
    </Button>
  );
};

export default SalesNoticeButton;
