import React, { useCallback, useMemo } from 'react';
import { Button, Modal } from 'antd';
import { closeModal } from './modalSlice';
import { useApplicationDate } from '../../hooks/useApplicationDate';
import dayjs from 'dayjs';
import { ActionsBar, Column, computeRemSize, YColumnsType } from '@ydistri/ds';
import { SimpleTable } from '../CalculationDetail/Statistics/components/SimpleTable';
import { TablePaginationConfig } from 'antd/lib';
import { useStoreSaleNotification } from '../Configuration/ConfigurationStoresAndDepartments/useStoreSaleNotification';
import { useMainScope } from '../Configuration/ConfigurationStoresAndDepartments/useMainScope';
import {
  transformToConfigurationRule,
  usePutConfigurationRuleMutation,
} from '../Configuration/ConfigurationStoresAndDepartments/apiScopes';
import { useTemplateOrCalculation } from '../../hooks/useTemplateOrCalculation';
import { ConfigurationRuleScopeMode, StoreResponse } from '@ydistri/api-sdk';
import cloneDeep from 'lodash.clonedeep';
import { ColumnsType } from 'antd/es/table';
import {
  EntityColumns,
  EntityColumnsConfig,
  useStoreTableColumnsProvider,
} from '../../hooks/useEntityTableColumnsProvider';
import { useAppDispatch } from '../../store';

interface StoreSaleInfoTableRow {
  store: Pick<StoreResponse, 'customerStoreId' | 'name' | 'code'>;
  storeTypeName: string;
  regionName: string;
  lastSaleDays?: number;
  isExcluded: boolean;
}

const StoreSaleInfoModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const mainScope = useMainScope();
  const templateOrCalculation = useTemplateOrCalculation();
  const applicationDate = useApplicationDate();
  const date1 = dayjs(applicationDate);

  const { storesToNotify, isFetchingStores } = useStoreSaleNotification();
  const [putConfigurationRuleMutation] = usePutConfigurationRuleMutation();

  const storeColumnsProvider = useStoreTableColumnsProvider();

  const data: StoreSaleInfoTableRow[] = useMemo(
    () =>
      (storesToNotify ?? []).map(s => {
        const date2 = dayjs(s.lastSaleDate);
        const diffInDays = date1.diff(date2, 'day');

        return {
          store: {
            customerStoreId: s.customerStoreId ?? '',
            code: s.code ?? '',
            name: s.name,
            id: s.id,
          },
          storeTypeName: s.storeType?.name ?? '',
          regionName: s.region?.name ?? '',
          lastSaleDays: diffInDays,
          isExcluded: false,
        };
      }),
    [storesToNotify, date1],
  );

  const handleClose = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const handleExclude = useCallback(() => {
    const excludeStores = storesToNotify ?? [];

    if (mainScope) {
      const scope = cloneDeep(mainScope);

      if (scope.entities.Stores.selectionOption === ConfigurationRuleScopeMode.All) {
        scope.entities.Stores.selectionOption = ConfigurationRuleScopeMode.AllExcept;
        scope.entities.Stores.selectedIds = excludeStores.map(s => s.id);
      } else if (scope.entities.Stores.selectionOption === ConfigurationRuleScopeMode.AllExcept) {
        excludeStores.forEach(s => {
          if (!scope.entities.Stores.selectedIds.includes(s.id)) {
            scope.entities.Stores.selectedIds.push(s.id);
          }
        });
      } else if (scope.entities.Stores.selectionOption === ConfigurationRuleScopeMode.Specified) {
        scope.entities.Stores.selectedIds = scope.entities.Stores.selectedIds.filter(
          s => !excludeStores.find(es => es.id === s),
        );
      }

      const configurationRule = transformToConfigurationRule(scope);

      putConfigurationRuleMutation({
        id: scope.id,
        templateId: templateOrCalculation.id,
        data: configurationRule,
      });
    }
  }, [putConfigurationRuleMutation, mainScope, storesToNotify, templateOrCalculation.id]);

  const footer = useMemo(
    () => (
      <ActionsBar>
        <Button key="back" onClick={handleExclude}>
          Exclude all from scope
        </Button>
        <Button key="back" onClick={handleClose}>
          Close
        </Button>
      </ActionsBar>
    ),
    [handleClose, handleExclude],
  );

  const columns: YColumnsType<StoreSaleInfoTableRow>[] = useMemo(() => {
    const storeColumnsConfig: EntityColumnsConfig<StoreSaleInfoTableRow> = {
      [EntityColumns.NAME]: {
        showSorterTooltip: false,
      },
      [EntityColumns.CODE]: {
        width: '8rem',
      },
      [EntityColumns.ID]: {
        width: computeRemSize(70),
      },
    };

    const cols: ColumnsType<StoreSaleInfoTableRow> =
      storeColumnsProvider<StoreSaleInfoTableRow>(storeColumnsConfig);

    cols.push({
      title: 'Last sale [days]',
      dataIndex: 'lastSaleDays',
      key: 'lastSaleDays',
      showSorterTooltip: false,
      width: computeRemSize(170),
      defaultSortOrder: 'descend',
      align: 'end',
      sorter: (a, b) => (a.lastSaleDays ?? 0) - (b.lastSaleDays ?? 0),
      render: (value: number) => {
        if (value >= 10000) return ` - no sale - `;
        return `${value} days ago`;
      },
    });

    cols.push({
      title: 'Store type',
      dataIndex: 'storeTypeName',
      key: 'storeTypeName',
      showSorterTooltip: false,
      width: computeRemSize(170),
      ellipsis: true,
      sorter: (a, b) => a.storeTypeName.localeCompare(b.storeTypeName),
    });

    cols.push({
      title: 'Region',
      dataIndex: 'regionName',
      key: 'regionName',
      showSorterTooltip: false,
      width: computeRemSize(170),
      ellipsis: true,
      sorter: (a, b) => a.regionName.localeCompare(b.regionName),
    });

    return cols;
  }, [storeColumnsProvider]);

  const pagination: TablePaginationConfig = useMemo(
    () => ({
      showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} flagged stores`,
      pageSize: 15,
      showSizeChanger: false,
      hideOnSinglePage: true,
      size: 'default',
      position: ['bottomLeft'],
    }),
    [],
  );

  const loading = isFetchingStores;

  if (data.length === 0) return null;

  return (
    <Modal width="90%" title="Store sale info" onCancel={handleClose} open={true} footer={footer}>
      <Column $gap="1rem">
        <SimpleTable<StoreSaleInfoTableRow>
          showBorder={true}
          showHeader={true}
          id="store-configurations"
          loading={loading}
          columns={columns}
          dataSource={data}
          size="small"
          pagination={pagination}
        />
      </Column>
    </Modal>
  );
};

export default StoreSaleInfoModal;
