import {
  EntityIdentificationDataType,
  setImportIdentificationValue,
} from '../../../../../../../Calculations/calculationsSlice';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import EntityIdentificationTypeComponent from '../../../../../../../ProjectAdministration/common/EntityIdentificationTypeComponent';
import { EntityIdentificationType } from '@ydistri/api-sdk';
import { ReduxState, useAppDispatch } from '../../../../../../../../store';

interface IdentificationTypeComponentProps {
  type: EntityIdentificationDataType;
}

const IdentificationTypeComponent: React.FC<IdentificationTypeComponentProps> = ({ type }) => {
  const dispatch = useAppDispatch();

  const identificationData = useSelector(
    (state: ReduxState) => state.calculations.redistributionImport.identificationData,
  );

  const data = useMemo(() => {
    return identificationData.find(identification => identification.type === type);
  }, [identificationData, type]);

  const entityIdentificationChanged = useCallback(
    (selectedValue: EntityIdentificationType, id?: string) => {
      if (id) {
        dispatch(setImportIdentificationValue({ id, value: selectedValue }));
      }
    },
    [dispatch],
  );

  if (data) {
    return (
      <EntityIdentificationTypeComponent
        key={data.id}
        type={data.type}
        value={data.value}
        onChange={entityIdentificationChanged}
        id={data.id}
        label={data.label}
      />
    );
  }
};

export default IdentificationTypeComponent;
