import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { setActiveScreenType } from '../../productListAdministrationSlice';
import { ActionsBar, Section } from '@ydistri/ds';
import ExitEditModeButton from '../../../../../components/buttons/ExitEditModeButton';
import { getSectionTitle } from '../../productListsLib';
import { ActiveScreenType } from '../../../common/administrationItemsLib';
import ProductListEditor from './ProductListEditor';
import { ReduxState, useAppDispatch } from '../../../../../store';

const ProductListEditModeSection: React.FC = () => {
  const selectedProductList = useSelector(
    (state: ReduxState) => state.productListsAdministration.selectedProductList,
  );

  const dispatch = useAppDispatch();
  const onExitEditMode = useCallback(() => {
    dispatch(setActiveScreenType(ActiveScreenType.DETAIL));
  }, [dispatch]);
  const headerActions = useMemo(() => {
    return (
      <ActionsBar>
        <ExitEditModeButton onClick={onExitEditMode} />
      </ActionsBar>
    );
  }, [onExitEditMode]);
  if (!selectedProductList) {
    return null;
  } else {
    return (
      <Section
        header={`${getSectionTitle(selectedProductList)} - Edit`}
        headerActions={headerActions}
      >
        <ProductListEditor />
      </Section>
    );
  }
};

export default ProductListEditModeSection;
