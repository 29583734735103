import {
  IdentificationType,
  TargetListItemsIdentificationResponseDataResponse,
} from '@ydistri/api-sdk';
import { addToast } from '@ydistri/utils';
import { TargetListsCollections } from '../../../../../swagger/collections';
import { AxiosResponse } from 'axios';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  clearValidation,
  resetState,
  setActiveScreenType,
  setHandlingMethod,
  setIsLoading,
  setIsOperationFinishedWithSuccess,
} from '../../targetListAdministrationSlice';
import {
  containsErrors,
  createTargetListItemsPayload,
  getParseableString,
} from '../../targetListsLib';
import { apiTargetLists } from '../../apiTargetLists';
import { Result } from 'antd';
import { ActionsBar, Column, GenericConfirmationModal, Row, YButton } from '@ydistri/ds';
import TargetListValidatedItemsGrid from './TargetListValidatedItemsGrid';

import {
  ActiveScreenType,
  extractValidationStatisticsAsMap,
  prepareValidatedItemsForPresentation,
} from '../../../common/administrationItemsLib';
import {
  AdministrationItemListHandlingMethod,
  ResultsDisplayMode,
  StatisticsItem,
  StatisticsItemType,
} from '../../../common/administrationItemsTypes';
import ShowOnlyErrorsToggle from '../../../common/validation-results/ShowOnlyErrorsToggle';
import AdministrationItemValidationStats from '../../../common/validation-results/AdministrationItemValidationStats';
import LoadingIndicator from '../../../../../components/global/LoadingIndicator/LoadingIndicator';
import useValidateItems from '../../useValidateItems';
import ValidationResultsConfirmUpdateButton from '../../../common/validation-results/ValidationResultsConfirmUpdateButton';
import Prompt from '../../../../../components/global/Prompt/Prompt';
import TargetListItemsTextArea from '../edit/TargetListItemsTextArea';
import ListItemDrawerEditor from '../../../common/edit/ListItemDrawerEditor';
import { APITAGS, ErrorType } from '../../../../../apis/api';
import { ReduxState, useAppDispatch } from '../../../../../store';

const TargetListValidationResults: React.FC = () => {
  const dispatch = useAppDispatch();

  const validation = useSelector((state: ReduxState) => state.targetListAdministration.validation);
  const selectedTargetList = useSelector(
    (state: ReduxState) => state.targetListAdministration.selectedTargetList,
  );
  const handlingMethod = useSelector(
    (state: ReduxState) => state.targetListAdministration.handlingMethod,
  );
  const isLoading = useSelector((state: ReduxState) => state.targetListAdministration.isLoading);
  const warehouseIdentificationType = useSelector(
    (state: ReduxState) => state.targetListAdministration.warehouseIdentificationType,
  );
  const productIdentificationType = useSelector(
    (state: ReduxState) => state.targetListAdministration.productIdentificationType,
  );

  const validateItems = useValidateItems();

  const [displayMode, setDisplayMode] = useState<ResultsDisplayMode>(ResultsDisplayMode.SUMMARY);
  const [showConfirmReplaceDialog, setShowConfirmReplaceDialog] = useState(false);

  //user can toggle between all items or only errors
  const [showOnlyErrors, setShowOnlyErrors] = useState(true);
  const [showEditor, setShowEditor] = useState(false);

  const toggleShowErrors = useCallback(() => {
    setShowOnlyErrors(prevValue => !prevValue);
  }, []);

  const isBlocked = (validation?.items && validation.items.length > 0) ?? false;

  /**
   * Filter all validated items based on current filters, actions etc.
   */
  const data = useMemo(() => {
    if (!validation) {
      return [];
    }

    if (!handlingMethod) {
      return validation.items;
    }

    return prepareValidatedItemsForPresentation(validation.items, handlingMethod, showOnlyErrors);
  }, [validation, handlingMethod, showOnlyErrors]);

  const closeConfirmationDialog = useCallback(() => {
    setShowConfirmReplaceDialog(false);
  }, []);

  const execute = useCallback(() => {
    closeConfirmationDialog();

    if (!selectedTargetList?.targetListId) {
      return;
    }

    if (!validation) {
      return;
    }

    dispatch(setIsLoading(true));

    const payload = createTargetListItemsPayload(
      validation.items,
      warehouseIdentificationType,
      productIdentificationType,
      handlingMethod,
    );

    let request:
      | Promise<AxiosResponse<TargetListItemsIdentificationResponseDataResponse>>
      | undefined = undefined;

    if (handlingMethod) {
      if (handlingMethod === AdministrationItemListHandlingMethod.ADD) {
        request = TargetListsCollections.patchTargetListItems(
          selectedTargetList.targetListId,
          payload,
        );
      } else {
        request = TargetListsCollections.putTargetListItems(
          selectedTargetList.targetListId,
          payload,
        );
      }

      request
        .then(() => {
          dispatch(clearValidation());
          dispatch(setIsOperationFinishedWithSuccess(true));
          dispatch(setActiveScreenType(ActiveScreenType.DETAIL));
          dispatch(addToast({ message: `Target List updated successfully` }));

          //reload target lists for the left pane as number of warehouses and products might have changed
          dispatch(apiTargetLists.util.invalidateTags([APITAGS.targetLists.targetListResponse]));
          dispatch(apiTargetLists.util.invalidateTags([APITAGS.targetLists.targetListSkuResponse]));
          dispatch(setHandlingMethod(undefined));
        })
        .catch((error: ErrorType) => {
          dispatch(setIsOperationFinishedWithSuccess(false));
          dispatch(
            addToast({
              isError: true,
              message: `There was an error when saving the target list: ${error.response.data.Messages.join(
                ', ',
              )}`,
            }),
          );
        })
        .finally(() => {
          dispatch(setIsLoading(false));
        });
    }
  }, [
    selectedTargetList,
    dispatch,
    handlingMethod,
    productIdentificationType,
    validation,
    warehouseIdentificationType,
    closeConfirmationDialog,
  ]);

  const onSubmitHandler = useCallback(() => {
    if (handlingMethod === AdministrationItemListHandlingMethod.ADD) {
      execute();
    } else {
      setShowConfirmReplaceDialog(true);
    }
  }, [handlingMethod, execute]);

  const onClearHandler = useCallback(() => {
    dispatch(clearValidation());
    dispatch(setHandlingMethod(undefined));
    dispatch(setActiveScreenType(ActiveScreenType.EDIT));
  }, [dispatch]);

  const copyContentToClipboard = useCallback(() => {
    const errorItems = validation?.items.filter(validatedItem => validatedItem.isError);
    if (errorItems && errorItems.length > 0) {
      const plainErrorData = getParseableString(
        errorItems,
        validation?.result?.warehouseIdentificationTypeId ?? IdentificationType.CustomerId,
        validation?.result?.productIdentificationTypeId ?? IdentificationType.CustomerId,
        '\t',
      );

      navigator.clipboard
        .writeText(plainErrorData)
        .then(() => {
          dispatch(
            addToast({
              message: 'Error items copied to clipboard, open Excel and paste',
            }),
          );
        })
        .catch(() => {
          dispatch(
            addToast({
              isError: true,
              message: 'There was an error when copying items to clipboard',
            }),
          );
        });
    }
  }, [validation, dispatch]);

  const toggleEditor = useCallback(() => {
    setShowEditor((prevValue: boolean) => !prevValue);
  }, []);

  const showDetails = useCallback(() => {
    setDisplayMode(ResultsDisplayMode.DETAIL);
    if (data.length === 0) {
      setShowOnlyErrors(false);
    }
  }, [data]);

  const disableConfirmButton = useMemo(() => {
    if (validation) {
      const itemsMap = extractValidationStatisticsAsMap(validation.items, handlingMethod);
      const updates = itemsMap.get(StatisticsItemType.SUCCESS)?.observation ?? 0;

      switch (handlingMethod) {
        case AdministrationItemListHandlingMethod.ADD:
          return updates === 0;
        case AdministrationItemListHandlingMethod.REPLACE: {
          const removes = itemsMap.get(StatisticsItemType.REMOVED)?.observation ?? 0;
          return updates === 0 && removes === 0;
        }
        case undefined:
          return true;
      }
    }

    return true;
  }, [validation, handlingMethod]);

  const confirmButton = useMemo(() => {
    return (
      <ValidationResultsConfirmUpdateButton
        label={
          handlingMethod === AdministrationItemListHandlingMethod.ADD
            ? 'Add items to target list'
            : 'Replace target list items...'
        }
        onClick={onSubmitHandler}
        disabled={disableConfirmButton}
        size={displayMode === ResultsDisplayMode.SUMMARY ? 'large' : 'middle'}
      />
    );
  }, [disableConfirmButton, handlingMethod, onSubmitHandler, displayMode]);

  const validationStats = useMemo(() => {
    if (validation?.items) {
      return (
        <AdministrationItemValidationStats
          data={validation.items}
          handlingMethod={handlingMethod}
        />
      );
    }
  }, [handlingMethod, validation]);

  const successExtraItems = useMemo(() => {
    return (
      <Column $gap="3rem" $alignItems="center">
        <Row>{validationStats}</Row>
        <Row $gap="2rem">
          <YButton
            onClick={showDetails}
            data-type="show-detailed-mode"
            size="large"
            tooltip="Show detailed table of items that will be inserted, updated, removed or will have no change."
          >
            Show items
          </YButton>
          {confirmButton}
        </Row>
      </Column>
    );
  }, [confirmButton, showDetails, validationStats]);

  const renderSummaryBanner = useCallback(() => {
    //if the default is not selected, we don't want to show the banner
    if (!showOnlyErrors) {
      return null;
    }

    if (validation) {
      const statsMap: Map<StatisticsItemType, StatisticsItem> = extractValidationStatisticsAsMap(
        validation.items,
        handlingMethod,
      );

      const successStats = statsMap.get(StatisticsItemType.SUCCESS);
      const removedStats = statsMap.get(StatisticsItemType.REMOVED);
      const unchangedStats = statsMap.get(StatisticsItemType.UNCHANGED);

      const someSuccess = successStats && successStats.observation > 0;
      const someRemoved = removedStats && removedStats.observation > 0;
      const someUnchanged = unchangedStats && unchangedStats.observation > 0;

      if (!someSuccess && !someRemoved && someUnchanged) {
        return (
          <Result
            status="info"
            title="No change to the target list!"
            subTitle="All items were validated successfully but there is no change to the target list."
            extra={successExtraItems}
          />
        );
      } else {
        return (
          <Result
            status="info"
            title="Items validated, changes can be confirmed."
            subTitle={`There ${successStats?.observation === 1 ? 'is' : 'are'} ${
              successStats?.observation ?? ''
            } ${
              successStats?.observation === 1 ? 'update' : 'updates'
            }. Click the button below to ${
              handlingMethod === AdministrationItemListHandlingMethod.ADD
                ? 'add items to'
                : 'replace'
            } the Target List.`}
            extra={successExtraItems}
          />
        );
      }
    }
  }, [showOnlyErrors, validation, handlingMethod, successExtraItems]);

  const processAdditionalItems = useCallback(() => {
    toggleEditor();
    validateItems();
  }, [toggleEditor, validateItems]);

  const onNavigateAway = useCallback(() => {
    dispatch(clearValidation());
    dispatch(resetState());
  }, [dispatch]);

  const actionsBar = useMemo(() => {
    return (
      <ActionsBar moveToRight data-testid="TargetListValidationResultsActions">
        {displayMode === ResultsDisplayMode.DETAIL && (
          <ShowOnlyErrorsToggle showOnlyErrors={showOnlyErrors} onClick={toggleShowErrors} />
        )}
        {!showEditor && (
          <YButton
            type="default"
            onClick={toggleEditor}
            data-type="show-editor"
            tooltip="Show the editor to paste more items"
          >
            Add more items...
          </YButton>
        )}
        <YButton
          type="default"
          onClick={onClearHandler}
          data-type="clear"
          tooltip="Remove all pasted items and start over"
          tooltipPlacement="topRight"
        >
          Clear
        </YButton>
        {containsErrors(validation?.items) && (
          <YButton
            onClick={copyContentToClipboard}
            tooltip="Copy items in error to clipboard and paste them to Excel"
          >
            Copy errors for Excel
          </YButton>
        )}
        {displayMode === ResultsDisplayMode.DETAIL && confirmButton}
      </ActionsBar>
    );
  }, [
    confirmButton,
    copyContentToClipboard,
    displayMode,
    onClearHandler,
    showEditor,
    showOnlyErrors,
    toggleEditor,
    toggleShowErrors,
    validation?.items,
  ]);

  const body = useMemo(() => {
    switch (displayMode) {
      case ResultsDisplayMode.SUMMARY:
        return renderSummaryBanner();
      case ResultsDisplayMode.DETAIL:
        if (selectedTargetList) {
          return (
            <TargetListValidatedItemsGrid
              data={data}
              selectedTargetListId={selectedTargetList.targetListId}
            />
          );
        }
    }
  }, [displayMode, renderSummaryBanner, selectedTargetList, data]);

  if (isLoading) {
    return <LoadingIndicator text="Updating..." />;
  }

  return (
    <>
      <Prompt
        message="Your pending changes will be lost. Are you sure you want to leave?"
        blocked={isBlocked}
        cleanup={onNavigateAway}
      />
      <Column $gap="1rem" $flexGrow={1}>
        {selectedTargetList && (
          <>
            <Row>
              {displayMode === ResultsDisplayMode.DETAIL && validationStats}
              {actionsBar}
            </Row>
            <Row $alignItems="stretch" $gap="2rem" $flexGrow={1}>
              <Column $flexGrow={1}>{body}</Column>
            </Row>
          </>
        )}

        <ListItemDrawerEditor
          onCancel={toggleEditor}
          onConfirm={processAdditionalItems}
          open={showEditor}
          title="Add more items to the Target List"
        >
          <TargetListItemsTextArea label="Items" />
        </ListItemDrawerEditor>
        {showConfirmReplaceDialog && (
          <GenericConfirmationModal
            title="Replace Target List items"
            message="Current Target List's content will be cleared and new items inserted. Continue?"
            onConfirmed={execute}
            onCanceled={closeConfirmationDialog}
            data-testid="ReplaceTargetListConfirmationDialog"
          />
        )}
      </Column>
    </>
  );
};
export default TargetListValidationResults;
