import { SkuRedistributionPaneType } from '../../../CalculationDetail/Redistribution/redistributionTypes';
import {
  CalculationExtended,
  ColumnsExtension,
} from '../../../Calculations/components/table/CalculationsTable';
import React from 'react';
import { CalculationMode } from '@ydistri/api-sdk';
import SkuPickingLineRenderer from '../../../../components/global/CalculationTableExtensions/SkuPickingLineRenderer';
import SkuOpenRenderer from '../../../../components/global/CalculationTableExtensions/SkuOpenRenderer';

export const getSkuCalculationsTableColumnExtension = (
  skuId: number,
  sourceOrTarget: SkuRedistributionPaneType,
): ColumnsExtension[] => [
  {
    afterKey: 'createdAt',
    columns: [
      {
        title: 'Picking Lines',
        key: '',
        dataIndex: [],
        width: '7.5rem',
        render: (_: boolean, record: CalculationExtended): React.ReactNode => {
          return (
            <SkuPickingLineRenderer
              available={true}
              sourceOrTarget={sourceOrTarget}
              manual={record.data.mode === CalculationMode.Manual}
              calculationId={record.data.id}
              skuId={skuId}
            />
          );
        },
      },
      {
        title: 'Link',
        key: '',
        dataIndex: [],
        width: '3rem',
        render: (_: boolean, record: CalculationExtended): React.ReactNode => {
          return (
            <SkuOpenRenderer
              sourceOrTarget={sourceOrTarget}
              calculationId={record.data.id}
              skuId={skuId}
            />
          );
        },
      },
    ],
  },
];
