import { Result } from 'antd';
import { ActionsBar, Button, Column } from '@ydistri/ds';
import React, { useCallback, useEffect, useMemo } from 'react';
import useResetImport from '../../common/useResetImport';
import { useTemplateOrCalculation } from '../../../../../../../../hooks/useTemplateOrCalculation';
import { useGetCalculationQuery } from '../../../../../../apiCalculationDetail';
import { CalculationStatus } from '@ydistri/api-sdk';
import { setImportStep } from '../../../../../../../Calculations/calculationsSlice';
import { ProcessingStep } from '../../../redistributionImportTypes';
import OverlayMessage, {
  OverlayMessageIcon,
} from '../../../../../../../../components/global/ContentOverlay/OverlayComponents';
import { useAppDispatch } from '../../../../../../../../store';

const RedistributionImportStepFinalized: React.FC = () => {
  const dispatch = useAppDispatch();
  const resetImport = useResetImport();

  const calculation = useTemplateOrCalculation();
  const { data, isFetching } = useGetCalculationQuery(calculation.id);

  useEffect(() => {
    if (!isFetching && data) {
      if (data.status === CalculationStatus.Completed) {
        dispatch(setImportStep({ stepIndex: ProcessingStep.FINALIZED, stepRunning: false }));
      }
    }
  }, [data, dispatch, isFetching]);

  const isPending = useMemo(() => {
    if (isFetching) return true;

    if (data) {
      if (data.status === CalculationStatus.Completed) {
        return false;
      }
    }

    return true;
  }, [data, isFetching]);

  const clickHandler = useCallback(() => {
    resetImport();
  }, [resetImport]);

  const resultComponent = useMemo(() => {
    if (isPending) {
      return (
        <Result
          data-type="skuRedistributionPending"
          status="info"
          title="SKU Redistribution import is running"
          subTitle="The import will finish in a few moments"
          icon={<OverlayMessage title="Running" icon={OverlayMessageIcon.SPINNER} />}
        />
      );
    }

    return (
      <Result
        status="success"
        title="SKU Redistribution import finished"
        data-type="skuRedistributionFinished"
      />
    );
  }, [isPending]);

  return (
    <Column $gap="1rem" $alignItems="center">
      {resultComponent}
      <ActionsBar>
        <Button data-testid="buttonClose" onClick={clickHandler} disabled={isPending}>
          Close
        </Button>
      </ActionsBar>
    </Column>
  );
};

export default RedistributionImportStepFinalized;
