import { useParams, useSearchParams } from 'react-router';
import { useGetSinglePickingPositionQuery } from '../../../apiCalculationDetail';
import { skipToken } from '@reduxjs/toolkit/query';
import { useMemo } from 'react';

export const useSelectedPickingPositionFromUrl = () => {
  const { calculationId } = useParams();
  const [searchParams] = useSearchParams();
  const pairingCodeFromUrl = searchParams.get('p');

  const [sourceSkuId, targetSkuId] = pairingCodeFromUrl?.split('-').map(Number) ?? [
    undefined,
    undefined,
  ];

  const params = useMemo(
    () =>
      sourceSkuId && targetSkuId && calculationId
        ? {
            calculationId: Number(calculationId),
            sourceSkuId,
            targetSkuId,
          }
        : skipToken,
    [sourceSkuId, targetSkuId, calculationId],
  );

  return useGetSinglePickingPositionQuery(params);
};
