import React, { useCallback, useMemo, useState } from 'react';
import { ForecastMonthlyOverviewResponse } from '@ydistri/api-sdk';
import { Chart, ChartDataset, TooltipModel } from 'chart.js';
import 'chartjs-adapter-date-fns';
import isEqual from 'lodash/isEqual';

import {
  lineTypeTooltipConfig,
  SkuSaleGraphValueColumnType,
  TLineType,
  TooltipCallback,
  TParsedTransactions,
  Units,
} from '../../../../lib/charts/saleChartsTypes';
import {
  addVat,
  changeFillInLineTypesBasedOnHiddenLines,
  copyLineTypeToParsedMonthlyTransactionsByAnotherLineType,
  createChartDataFromDatasetsAndParsedMonthlyTransactions,
  createDatasetsFromLineTypesAndGraphLines,
  hideNullLineTypes,
  saleUsedForForecast,
  setupChartLines,
} from '../../../../lib/charts/saleChartsLib';
import SaleChart from 'apps/ydistri/src/app/components/global/SaleChart/SaleChart';

import { formatMonetaryValue, formatNumber } from '@ydistri/utils';
import { computeTooltipPosition } from '../../../../lib/charts/saleChartsLibUI';
import SalesChartTooltip from '../../../../components/global/SalesChartTooltip/SaleChartTooltip';
import {
  ContentData,
  ContentDataType,
  TooltipContentSection,
  TooltipPosition,
} from 'apps/ydistri/src/app/components/global/SalesChartTooltip/saleChartTooltipTypes';

/**
 * Generates data for the tooltip.
 * Tooltip shows data for all lines of the same type (Sale or Forecast) as the hovered data point.
 * If the hovered data point is in the future (we show forecast), the tooltip shows cumulative values.
 * If the hovered data point is in the past (we show sales), the tooltip shows the total value for the line.
 * @param lines
 * @param monthlyData
 * @param lineType
 * @param unit The unit selected for the chart (value or quantity)
 * @param currency
 * @param isFuture If the hovered data point is in the future relative to the application date (we show forecast),
 * @param showAverageValue For some charts we show average value (product or sku chart)
 * @param vat
 */
const generateTooltipData = (
  lines: TLineType[],
  monthlyData: TParsedTransactions,
  lineType: string,
  unit: Units,
  currency: string,
  isFuture: boolean,
  showAverageValue: boolean = false,
  vat: number,
  // eslint-disable-next-line max-params
): TooltipContentSection => {
  const contentSection: TooltipContentSection = {
    title: lineTypeTooltipConfig[lineType].title,
    columnNames: [],
    data: [],
  };

  const insertAverageValue = showAverageValue && !isFuture && unit === Units.QUANTITY;

  switch (unit) {
    case Units.VALUE: {
      contentSection.columnNames.push('Value');
      break;
    }
    case Units.QUANTITY: {
      contentSection.columnNames.push('Qty');
      break;
    }
  }

  if (isFuture) {
    contentSection.columnNames.push('Cumulative');
  }

  if (insertAverageValue) {
    contentSection.columnNames.push('Avg. price');
  }

  let total = 0;
  lines.forEach(line => {
    const lineData = monthlyData.data[line.name];
    const info = 'lineInfo' in line ? line.lineInfo : line.barInfo;

    if (line.type === lineType && lineData && info.hidden !== true) {
      const isSalesUsedForForecast = line.name === 'SaleUsedForForecast';

      const contentData: ContentData = {
        type: isSalesUsedForForecast
          ? ContentDataType.SALE_USED_FOR_FORECAST
          : ContentDataType.DETAIL,
        label: line.label,
        primaryColumnValue: '',
        columnValues: [],
        line: line,
      };

      switch (unit) {
        case Units.VALUE: {
          if (lineData.value !== null) {
            const lineDataValue = isSalesUsedForForecast
              ? lineData.valueToThisPriority
              : lineData.value;

            const primaryValue = formatMonetaryValue(currency, lineDataValue);
            contentData.primaryColumnValue = primaryValue;
            contentData.columnValues.push(primaryValue);

            if (isFuture) {
              const secondaryValue = formatMonetaryValue(currency, lineData.valueCumulative);
              contentData.secondaryColumnValue = secondaryValue;
              contentData.columnValues.push(secondaryValue);
            } else {
              total += lineData.value;
            }
          }
          break;
        }
        case Units.QUANTITY: {
          if (lineData.quantity !== null) {
            const lineDataQuantity = isSalesUsedForForecast
              ? (lineData.quantityToThisPriority ?? 0)
              : lineData.quantity;

            const primaryValue = formatNumber(lineDataQuantity);
            contentData.primaryColumnValue = primaryValue;
            contentData.columnValues.push(primaryValue);

            if (isFuture) {
              const secondaryValue = formatNumber(lineData.quantityCumulative ?? 0);
              contentData.secondaryColumnValue = secondaryValue;
              contentData.columnValues.push(secondaryValue);
            } else {
              //avg value of sales - not shown in case of row "sales used for forecast", "dash" in case of Quantity = 0, value with currency otherwise
              if (insertAverageValue) {
                let secondaryColumnValue = '—';
                if (lineData.value !== null && lineData.value > 0) {
                  secondaryColumnValue = formatMonetaryValue(
                    currency,
                    addVat(lineData.avgValue, vat),
                    2,
                  );
                }
                contentData.secondaryColumnValue = secondaryColumnValue;
                contentData.columnValues.push(secondaryColumnValue);
              }
              total += lineData.quantity;
            }
          }
          break;
        }
      }
      contentSection.data.push(contentData);
    }
  });

  //We do not show Total row for forecasts
  if (!isFuture) {
    const primaryColumnValue =
      unit === Units.VALUE ? formatMonetaryValue(currency, total) : formatNumber(total);
    contentSection.data.push({
      type: ContentDataType.TOTAL,
      label: 'Total',
      primaryColumnValue,
      columnValues: [primaryColumnValue],
    });
  }

  contentSection.data.sort((left, right) => {
    return left.type - right.type;
  });

  return contentSection;
};

interface DetailSalesChartProps {
  monthlyData: TParsedTransactions[];
  forecastData?: ForecastMonthlyOverviewResponse[];
  loadingSalesData?: boolean;
  hasForecasts?: boolean;
  activeForecastConfidence?: number[];
  setActiveForecastConfidence?: (x: number[]) => void;
  saleTypes: TLineType[];
  forecastTypes: TLineType[];
  dateToUse: Date;
  units: Units;
  setUnits: (item: Units) => void;
  showCumulativeForecast?: boolean;
  width?: number;
  height?: number;
  showAverageValueInChartTooltip?: boolean;
  vat?: number;
}

const DetailSalesChart: React.FC<DetailSalesChartProps> = ({
  monthlyData,
  hasForecasts,
  saleTypes,
  forecastTypes,
  dateToUse,
  units,
  showCumulativeForecast = false,
  width,
  height,
  showAverageValueInChartTooltip,
  vat,
}) => {
  const [tooltipPosition, setTooltipPosition] = useState<TooltipPosition>({
    visible: false,
    left: 0,
    top: 0,
    right: 0,
  });
  const [tooltipMonthlyData, setTooltipMonthlyData] = useState<TParsedTransactions>();
  const [tooltipData, setTooltipData] = useState<TooltipContentSection[]>([]);

  const parsedMonthlyTransactions = useMemo(() => {
    let parsed = monthlyData;

    if (hasForecasts) {
      parsed = copyLineTypeToParsedMonthlyTransactionsByAnotherLineType(
        parsed,
        saleUsedForForecast,
        'SaleSellout',
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- no idea how is it any[], because everything has type
    return parsed;
  }, [hasForecasts, monthlyData]);

  const chartData = useMemo(() => {
    const chartLines = setupChartLines(
      saleTypes.concat(hasForecasts ? forecastTypes : []),
      parsedMonthlyTransactions,
      showCumulativeForecast,
      dateToUse,
      units,
    ); //{SaleRegular: [1,2,3], SalePromo: [2,3,0],...

    let saleLineTypes = hideNullLineTypes(saleTypes, parsedMonthlyTransactions); //sets "hidden: true" to LineTypes in case of only 0 or null in data
    saleLineTypes = changeFillInLineTypesBasedOnHiddenLines(saleLineTypes); //sets fill property based on missing data

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const datasets = createDatasetsFromLineTypesAndGraphLines(
      saleLineTypes.concat(hasForecasts ? forecastTypes : []),
      chartLines,
    ) as ChartDataset<'line'>[];

    return createChartDataFromDatasetsAndParsedMonthlyTransactions(
      datasets,
      parsedMonthlyTransactions,
    );
  }, [
    dateToUse,
    forecastTypes,
    hasForecasts,
    parsedMonthlyTransactions,
    saleTypes,
    showCumulativeForecast,
    units,
  ]);

  /**
   * Called by the chart when the user hovers over a data point or away from it.
   * This function prepares data for the tooltip component and computes its new position.
   * Both pieces of information are stored in local state for the tooltip component to pick up
   * and display.
   */
  const detailSaleChartTooltip: TooltipCallback = useCallback(
    (
      tooltipModel: TooltipModel<'line'>,
      lines: TLineType[],
      datasets: ChartDataset[],
      parsedMonthlyTransactions: TParsedTransactions[],
      applicationDate: Date,
      chartRef: React.RefObject<Chart<'line'>>,
      currency: string,
      vat: number,
      valueColumnType: SkuSaleGraphValueColumnType,
      // eslint-disable-next-line max-params
    ): Date | undefined => {
      // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents -- no time to solve this weird error
      let monthlyData: TParsedTransactions | undefined;
      let result: Date | undefined = undefined;
      let datasetLabel = '';
      let isFuture = false;

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (tooltipModel.dataPoints) {
        const dataPoint = tooltipModel.dataPoints[0];

        datasetLabel = dataPoint.dataset.label ?? '';
        const monthIndex: number = dataPoint.dataIndex || 0;
        monthlyData = parsedMonthlyTransactions[monthIndex];

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- eslint thinks that monthlyData can NOT be undefined (but it can)
        if (monthlyData) {
          isFuture = monthlyData.dates.dateTo > applicationDate;
          result = monthlyData.dates.dateFrom;
        }
      }

      const newPosition = computeTooltipPosition(
        chartRef,
        tooltipModel,
        '[data-type="SalesChartTooltipWrapper"]',
      );

      //set state only if the position has changed
      if (!isEqual(tooltipPosition, newPosition)) {
        if (monthlyData && newPosition.visible) {
          setTooltipMonthlyData(monthlyData);

          const lineType = datasetLabel.startsWith('Forecast') ? 'Forecast' : 'Sale';

          const tooltipData = generateTooltipData(
            lines,
            monthlyData,
            lineType,
            units,
            currency,
            isFuture,
            valueColumnType === 'avg',
            vat,
          );
          setTooltipData([tooltipData]);
        }

        setTooltipPosition(newPosition);
      }

      return result;
    },
    [tooltipPosition, units],
  );

  return (
    <>
      <SaleChart
        chartData={chartData}
        currentDate={dateToUse}
        width={width}
        height={height}
        transactions={parsedMonthlyTransactions}
        lines={saleTypes.concat(forecastTypes)}
        valueColumnType={showAverageValueInChartTooltip ? 'avg' : 'value'}
        tooltipCallback={detailSaleChartTooltip}
        vat={vat}
      />
      {tooltipMonthlyData && (
        <SalesChartTooltip
          rootId="detailSalesChartTooltip"
          position={tooltipPosition}
          data={tooltipData}
          currentDate={dateToUse}
          dateFrom={tooltipMonthlyData.dates.dateFrom}
          dateTo={tooltipMonthlyData.dates.dateTo}
        />
      )}
    </>
  );
};

export default DetailSalesChart;
