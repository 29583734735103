import React, { useCallback } from 'react';
import { EntityListResponse, EntityListType } from '@ydistri/api-sdk';
import { useParams } from 'react-router';
import { RoutingPage } from '../../../../routes/routerSlice';
import AdministrationItemsList from '../../common/catalog/AdministrationItemsList';
import { projectAdministrationSubpageByEntityListType } from '../entityListsLib';

interface EntityListsListProps {
  entityListType: EntityListType;
  data?: EntityListResponse[];
  onItemClick?: (el: EntityListResponse) => void;
  selectedItem?: EntityListResponse;
  loading?: boolean;
}

const EntityListsList: React.FC<EntityListsListProps> = ({
  entityListType,
  data,
  loading,
  selectedItem,
}) => {
  const countableData = useCallback((item: EntityListResponse) => {
    return [
      { count: item.productCount, description: 'product' },
      { count: item.storeCount, description: 'store' },
    ];
  }, []);

  const { projectShortName } = useParams();

  const linkProvider = useCallback(
    (item: EntityListResponse) =>
      `/${projectShortName ?? ''}/${RoutingPage.PROJECT_ADMINISTRATION}/${
        projectAdministrationSubpageByEntityListType[entityListType]
      }/${item.entityListId}`,
    [entityListType, projectShortName],
  );

  const idProvider = useCallback((item: EntityListResponse) => item.entityListId, []);

  const itemSelected = useCallback(
    (item: EntityListResponse) => {
      return selectedItem?.entityListId === item.entityListId;
    },
    [selectedItem],
  );

  return (
    <AdministrationItemsList<EntityListResponse>
      data={data}
      loading={loading}
      countableDataProvider={countableData}
      linkProvider={linkProvider}
      idProvider={idProvider}
      itemSelected={itemSelected}
      data-testid="EntityListsList"
    />
  );
};

export default EntityListsList;
