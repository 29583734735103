import React, { useMemo } from 'react';
import { StatisticsItem, StatisticsItemType } from '../administrationItemsTypes';
import { Statistic } from 'antd';
import { styled } from 'styled-components';
import { computeRemSize } from '@ydistri/ds';
import { COLORS } from '../../../../styles/style';

interface StatisticsItemComponentProps {
  item: StatisticsItem;
  selected?: boolean;
  onClick?: () => void;
}

interface StatisticWrapperProps {
  $type?: StatisticsItemType;
  $clickable?: boolean;
  $selected?: boolean;
}

const defaultStyle = {
  fontWeight: '600',
};

const styleSuccess = {
  ...defaultStyle,
  color: '#52c41a',
};

const styleError = {
  ...defaultStyle,
  color: '#cf1322',
};

const styleRemoved = {
  ...defaultStyle,
  color: '#F5841F',
};

const StatisticWrapper = styled.div<StatisticWrapperProps>`
  padding: 0.7rem 1.1rem;
  border-radius: 0.4rem;
  min-width: 8rem;

  .ant-statistic .ant-statistic-title {
    color: #696969;
  }

  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};

  background-color: ${({ $type }) => {
    switch ($type) {
      case StatisticsItemType.SUCCESS:
        return '#F1F9EF';
      case StatisticsItemType.ERROR:
        return '#FFF0F0';
      case StatisticsItemType.REMOVED:
        return '#fdf0dd';
      case StatisticsItemType.UNCHANGED:
      case undefined:
        return undefined;
    }
  }};

  font-weight: ${({ $selected }) => ($selected ? 'bold' : 'normal')};

  border: ${({ $type, $selected }) => {
    const defaultBorder = `${computeRemSize(3)} solid`;

    if (!$selected) {
      return `${defaultBorder} transparent`;
    }

    switch ($type) {
      case StatisticsItemType.SUCCESS:
        return `${defaultBorder} ${styleSuccess.color};`;
      case StatisticsItemType.ERROR:
        return `${defaultBorder} ${styleError.color};`;
      case StatisticsItemType.REMOVED:
        return `${defaultBorder} ${styleRemoved.color};`;
      case StatisticsItemType.UNCHANGED:
      case undefined:
        return `${defaultBorder} ${COLORS.GREY};`;
    }
  }};
`;

const StatisticsItemComponent: React.FC<StatisticsItemComponentProps> = ({
  item,
  selected,
  onClick,
  ...rest
}: StatisticsItemComponentProps) => {
  const valueStyle = useMemo(() => {
    if (item.observation === 0) return undefined;

    switch (item.type) {
      case StatisticsItemType.SUCCESS:
        return styleSuccess;
      case StatisticsItemType.ERROR:
        return styleError;
      case StatisticsItemType.REMOVED:
        return styleRemoved;
      case StatisticsItemType.UNCHANGED:
        return undefined;
    }
  }, [item]);

  return (
    <StatisticWrapper
      $type={item.observation > 0 ? item.type : undefined}
      $clickable={!!onClick}
      $selected={selected}
      onClick={onClick}
    >
      <Statistic
        title={item.label}
        value={item.observation}
        groupSeparator=" "
        {...rest}
        valueStyle={valueStyle}
      />
    </StatisticWrapper>
  );
};

export default StatisticsItemComponent;
