import { useGetOverlappingCalculationsQuery } from '../../Configuration/apiTemplate';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import LoadingIndicator from '../../../components/global/LoadingIndicator/LoadingIndicator';
import { CalculationStatus, CalculationWithOverviewResponse } from '@ydistri/api-sdk';
import { Button, Column, computeRemSize, Row, Text } from '@ydistri/ds';
import CalculationsTable from '../../Calculations/components/table/CalculationsTable';
import { isCalculationInTemporaryStatus } from '../../Calculations/calculationsLib';
import { resetSelectedRowKeys, setSelectedRowKeys } from '../../Calculations/calculationsSlice';
import { useSelector } from 'react-redux';
import { ReduxState, useAppDispatch } from '../../../store';
import { plural } from '@ydistri/utils';
import { CalculationDataType } from '../../Calculations/calculationsTypes';

const emptyOverlaps: CalculationWithOverviewResponse[] = [];
export const OVERLAP_TABLE_ID = 'overlapTableId';

interface OverlappingCalculationFormProps {
  initialValues?: CalculationDataType[];
}

const OverlappingCalculationForm: React.FC<OverlappingCalculationFormProps> = ({
  initialValues,
}) => {
  const dispatch = useAppDispatch();
  const [changeDefaults, setChangeDefaults] = useState(false);

  const { data: basicOverlaps = emptyOverlaps, isFetching } = useGetOverlappingCalculationsQuery();
  const selectedOverlappingCalculations = useSelector(
    (state: ReduxState) => state.calculations.selectedRowKeys[OVERLAP_TABLE_ID]?.arr,
  );

  const overlaps = useMemo(() => {
    if (initialValues) {
      return initialValues;
    }

    if (isFetching && basicOverlaps.length > 0) {
      return basicOverlaps;
    }
  }, [basicOverlaps, initialValues, isFetching]);

  const toggleChangeDefaults = useCallback(() => {
    setChangeDefaults(p => !p);
  }, [setChangeDefaults]);

  useEffect(() => {
    if (overlaps) {
      dispatch(
        setSelectedRowKeys({
          tableId: OVERLAP_TABLE_ID,
          data: overlaps.map(c => c.id),
        }),
      );
    }
  }, [overlaps, dispatch]);

  const clearSelectionHandler = useCallback(() => {
    dispatch(resetSelectedRowKeys(OVERLAP_TABLE_ID));
  }, [dispatch]);

  const selectableRules = useCallback(
    (c: CalculationWithOverviewResponse) =>
      c.status === CalculationStatus.Crashed || isCalculationInTemporaryStatus(c.status),
    [],
  );

  if (isFetching) {
    return <LoadingIndicator />;
  }

  const overlappingCalculationCount = (selectedOverlappingCalculations ?? []).length;

  return (
    <Column $gap="1rem">
      <Row $gap="2rem">
        <Text>
          {overlappingCalculationCount} overlapping calculation{plural(overlappingCalculationCount)}
        </Text>
        {!changeDefaults && (
          <Button $sizeVariant="xSmall" onClick={toggleChangeDefaults}>
            Load all
          </Button>
        )}
        {overlappingCalculationCount > 0 && (
          <Button $sizeVariant="xSmall" onClick={clearSelectionHandler}>
            Clear selection
          </Button>
        )}
      </Row>
      {((overlaps && overlaps.length > 0) || changeDefaults) && (
        <CalculationsTable
          calculationsTableId={OVERLAP_TABLE_ID}
          variant="compact"
          showActions={false}
          displaySkuTypeValues={false}
          linksEnabled={false}
          selectableRules={selectableRules}
          predefinedCalculations={!changeDefaults ? overlaps : undefined}
          autoHeight={!changeDefaults}
          minHeight={changeDefaults ? computeRemSize(200) : undefined}
        />
      )}
    </Column>
  );
};

export default OverlappingCalculationForm;
