import React, { useMemo } from 'react';
import SkuDetailSection from './SkuDetailSection/SkuDetailSection';
import { SkuRedistributionSubset } from '../../../redistributionTypes';
import { useSelectedPickingPositionFromUrl } from '../useSelectedPickingPositionFromUrl';

interface CalculationRedistributionTargetSectionProps {
  onShowSkuInfoChanged?: (showSkuInfo: boolean) => void;
}

const CalculationRedistributionTargetSection: React.FC<
  CalculationRedistributionTargetSectionProps
> = ({ onShowSkuInfoChanged }) => {
  const { data: skuRedistribution } = useSelectedPickingPositionFromUrl();

  const redistributionData = useMemo((): SkuRedistributionSubset | undefined => {
    if (skuRedistribution) {
      return {
        skuId: skuRedistribution.targetSkuId,
        brandName: skuRedistribution.product.brand?.name ?? '',
        brandId: skuRedistribution.product.brand?.id ?? 0,
        quantity: skuRedistribution.quantity,
        totalMovedQuantity: skuRedistribution.totalQuantityToTargetSku,
        store: skuRedistribution.targetStore,
      };
    }
  }, [skuRedistribution]);

  if (redistributionData) {
    return (
      <SkuDetailSection
        sourceOrTarget="Target"
        redistributionData={redistributionData}
        onShowSkuInfoChanged={onShowSkuInfoChanged}
      />
    );
  } else {
    return null;
  }
};

export default CalculationRedistributionTargetSection;
