import React, { useCallback } from 'react';
import { Button, Column, Input, Section, Text } from '@ydistri/ds';
import { Form } from 'antd';
import { useAntdForm } from '../../../hooks/useAntdForm';
import { useUpdateUserPasswordMutation } from '../../../apis/apiIdentity';
import { Rule } from 'rc-field-form/lib/interface';
import { addToast } from '@ydistri/utils';
import { useAppDispatch } from '../../../store';

interface ChangePasswordFields {
  passOld: string;
  pass1: string;
  pass2: string;
}

const initialValues: ChangePasswordFields = {
  passOld: '',
  pass1: '',
  pass2: '',
};

const labelCol = { span: 8 };
const wrapperCol = { span: 16 };
const buttonWrapperCol = { offset: 8, span: 16 };
const formStyle = { maxWidth: 600 };
const oldPasswordRules: Rule[] = [{ required: true, message: 'Old password is required' }];
const newPasswordRules: Rule[] = [{ min: 8, message: 'Min. 8 characters' }];
const newPasswordAgainRules: Rule[] = [
  {
    required: true,
    message: 'Please confirm your password!',
  },
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('pass1') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('The new password that you entered do not match!'));
    },
  }),
];

const ChangePassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const { form } = useAntdForm<ChangePasswordFields>(initialValues);

  const [updateUserPassword, updateUserPasswordStatus] = useUpdateUserPasswordMutation();

  const onSubmitHandler = useCallback(
    (values: ChangePasswordFields) => {
      if (values.pass1 !== values.pass2) {
        return;
      }

      updateUserPassword({
        oldPassword: values.passOld,
        newPassword: values.pass1,
      })
        .unwrap()
        .then(() => {
          form.resetFields();
          dispatch(
            addToast({
              message: 'Password changed successfully',
            }),
          );
        })
        .catch(() => {
          dispatch(
            addToast({
              message: 'Password change failed',
              isError: true,
            }),
          );
        });
    },
    [dispatch, form, updateUserPassword],
  );

  const onEnterHandler = useCallback(() => {
    form.submit();
  }, [form]);

  return (
    <Section header="Change password">
      <Form
        form={form}
        initialValues={initialValues}
        requiredMark={false}
        colon={false}
        name="changePasswordForm"
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        style={formStyle}
        onFinish={onSubmitHandler}
        autoComplete="off"
      >
        <Form.Item label="Old password" name="passOld" rules={oldPasswordRules}>
          <Input type="password" onPressEnter={onEnterHandler} />
        </Form.Item>
        <Form.Item label="New password" name="pass1" rules={newPasswordRules}>
          <Input type="password" onPressEnter={onEnterHandler} />
        </Form.Item>
        <Form.Item label="New password again" name="pass2" rules={newPasswordAgainRules}>
          <Input type="password" onPressEnter={onEnterHandler} />
        </Form.Item>
        <Form.Item wrapperCol={buttonWrapperCol}>
          <Column $gap="0.5rem">
            <Button loading={updateUserPasswordStatus.isLoading} type="primary" htmlType="submit">
              Save
            </Button>
            {updateUserPasswordStatus.error && (
              <Text $type="danger">{updateUserPasswordStatus.error.message}</Text>
            )}
            {updateUserPasswordStatus.isSuccess && (
              <Text $type="success">Password changed successfully.</Text>
            )}
          </Column>
        </Form.Item>
      </Form>
    </Section>
  );
};

export default ChangePassword;
