import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { INFINITE_PAGE_SIZE, Text } from '@ydistri/ds';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../store';
import { GetProductListProductsPayload, useGetProductListProductsQuery } from '../apiProductLists';
import { skipToken } from '@reduxjs/toolkit/query';
import { ProductListSourceType } from '@ydistri/api-sdk';
import EditIconButton from '../../../../components/buttons/EditIconButton';
import { useParams } from 'react-router';
import ProductCatalog from '../../../../components/global/ProductCatalog/ProductCatalog';

export type ProductListDetailParams = GetProductListProductsPayload;

const ProductListDetail: React.FC = () => {
  const { projectShortName } = useParams();

  const selectedProductListConfiguration = useSelector(
    (state: ReduxState) => state.productListConfiguration.selectedProductListConfiguration,
  );

  const [params, setParams] = useState<ProductListDetailParams>({
    productListId: selectedProductListConfiguration?.productListId ?? 0,
    skip: 0,
    top: INFINITE_PAGE_SIZE,
  });

  const { data: productListProducts, isFetching } = useGetProductListProductsQuery(
    params.productListId > 0 ? params : skipToken,
  );

  useEffect(() => {
    if (selectedProductListConfiguration?.productListId) {
      setParams(p => ({
        ...p,
        productListId: selectedProductListConfiguration.productListId,
        skip: 0,
      }));
    }
  }, [selectedProductListConfiguration?.productListId]);

  /* ========================= Header ========================= */

  const header = useMemo(
    () => selectedProductListConfiguration?.name ?? 'Product list detail',
    [selectedProductListConfiguration],
  );

  const onEdit = useCallback(() => {
    if (projectShortName && selectedProductListConfiguration?.productListId) {
      window.open(
        `/${projectShortName}/project-administration/productlists/${selectedProductListConfiguration.productListId}`,
      );
    }
  }, [projectShortName, selectedProductListConfiguration?.productListId]);

  /* ========================= Header actions ========================= */

  const headerActions = useMemo(
    () => (
      <>
        {selectedProductListConfiguration && <Text $bold>Type:</Text>}
        {selectedProductListConfiguration?.productListSourceTypeId ===
          ProductListSourceType.Custom && (
          <>
            Custom (editable)
            <EditIconButton
              size="small"
              tooltip="Edit product list in administration"
              tooltipPosition="top"
              onClick={onEdit}
              data-testid="EditProductList"
            />
          </>
        )}
        {selectedProductListConfiguration?.productListSourceTypeId ===
          ProductListSourceType.Integration && (
          <>
            Integration (non-editable)
            <EditIconButton
              size="small"
              tooltip="Edit product list in administration"
              tooltipPosition="top"
              onClick={onEdit}
              data-testid="EditProductList"
              disabled={true}
            />
          </>
        )}
      </>
    ),
    [onEdit, selectedProductListConfiguration],
  );

  /* ========================= Scrolling ========================= */

  return (
    <ProductCatalog
      header={header}
      headerActions={headerActions}
      id="product-list-detail"
      data={productListProducts}
      loading={isFetching}
      setParams={setParams}
      alertMessage={!selectedProductListConfiguration ? 'No selected product list' : undefined}
      alertDescription={
        !selectedProductListConfiguration ? 'Select a product list to view its products' : undefined
      }
    />
  );
};

export default ProductListDetail;
