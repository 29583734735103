import React, { useCallback, useEffect } from 'react';
import { ReduxState, useAppDispatch } from '../../../../../../../../store';
import { useSelector } from 'react-redux';
import { Column } from '@ydistri/ds';
import { DisplayEntityIdType, EntityIdentificationType } from '@ydistri/api-sdk';
import {
  setImportIdentificationValue,
  setImportInstructionSpecification,
} from '../../../../../../../Calculations/calculationsSlice';
import RedistributionImportTextArea from './RedistributionImportTextArea';
import InstructionSelect from './InstructionSelect';
import { InstructionType } from '../../../redistributionImportTypes';
import RedistributionImportEditorActions from './RedistributionImportEditorActions';
import { useApplicationConfiguration } from '../../../../../../../../hooks/useApplicationConfiguration';
import IdentificationTypeComponent from './IdentificationTypeComponent';

const RedistributionImportEditor: React.FC = () => {
  const dispatch = useAppDispatch();

  const { displayProductId, displayStoreId } = useApplicationConfiguration();

  const instructionType = useSelector(
    (state: ReduxState) => state.calculations.redistributionImport.instructionSpecification,
  );

  const instructionTypeChanged = useCallback(
    (newValue: InstructionType) => {
      dispatch(setImportInstructionSpecification(newValue));
    },
    [dispatch],
  );

  const identificationData = useSelector(
    (state: ReduxState) => state.calculations.redistributionImport.identificationData,
  );

  //set default value for identification data based on displayProductId and displayStoreId
  //if the project uses codes, set default value to Code and to CustomerId otherwise
  useEffect(() => {
    identificationData.forEach(identification => {
      //set the value only if it is not already set
      if (identification.value === undefined) {
        let value = EntityIdentificationType.CustomerId;

        if (identification.type === 'Product') {
          if (displayProductId === DisplayEntityIdType.Code) {
            value = EntityIdentificationType.Code;
          }
        } else if (identification.type === 'Store') {
          if (displayStoreId === DisplayEntityIdType.Code) {
            value = EntityIdentificationType.Code;
          }
        }

        dispatch(setImportIdentificationValue({ id: identification.id, value }));
      }
    });
  }, [dispatch, displayProductId, displayStoreId, identificationData]);

  return (
    <Column $gap="1rem">
      <IdentificationTypeComponent type="Product" />
      <IdentificationTypeComponent type="Store" />
      <InstructionSelect value={instructionType} onChange={instructionTypeChanged} />
      <RedistributionImportTextArea label="Content" maxWidth="45rem" />
      <RedistributionImportEditorActions />
    </Column>
  );
};

export default RedistributionImportEditor;
