import { CategorySelectionType } from './categoryTreeSlice';
import { styled, css } from 'styled-components';
import { COLORS } from '../../../styles/style';
import { NavLink } from 'react-router';
import { computeRemSize } from '@ydistri/ds';

interface TreeNodeProps {
  $level: number;
  $selectionType: CategorySelectionType | undefined;
  $isOpened: boolean;
  $isLastLevel?: boolean;
}

type OpenIconProps = Pick<TreeNodeProps, '$isOpened'> & {
  $canBeOpened: boolean;
};

interface ExceptionIconProps {
  $hasException: boolean;
}

export const ExceptionIcon = styled.span<ExceptionIconProps>`
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => (p.$hasException ? COLORS.PRIMARY : 'inherit')};
`;

export const OpenIcon = styled.span<OpenIconProps>`
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  color: ${p => (p.$canBeOpened ? 'initial' : 'lightgray')};

  transition: 0.3s all;
  transform: rotateZ(${p => (p.$isOpened ? 180 : 360)}deg);
  border-radius: 50%;

  &:hover {
    background-color: ${p => (p.$canBeOpened ? COLORS.HOVER_GREY_LIGHT : 'initial')};
    color: ${p => (p.$canBeOpened ? p.theme.colors.primary : 'initial')};
  }
`;

export const TreeLeafTitle = styled.span`
  flex-grow: 1;
  padding: 0.4rem;
  border-radius: 0.4rem;
  color: initial;
  position: relative;

  &:hover {
    color: initial;
  }

  transition: 0.3s all;
`;

export const IconWrapper = styled.span`
  display: flex;
  margin-right: 0.25rem;
`;

export const TreeLeafWrapper = styled(NavLink)<TreeNodeProps>`
  width: 100%;
  display: flex;
  cursor: pointer;
  font-size: ${computeRemSize(14)};
  padding: 0.5rem 0.7rem 0.5rem 1.25rem;

  ${p =>
    p.$level === 0 &&
    css`
      border-top: ${computeRemSize(1)} solid ${COLORS.GREY_BORDER};
      padding-left: 0.25rem;
    `}

  ${p =>
    (p.$level === 0 || p.$level === 1) &&
    css`
      font-weight: bold;
      border-bottom: ${computeRemSize(1)} solid ${COLORS.GREY_BORDER};
    `}


  ${p =>
    p.$level > 1 &&
    css`
      font-size: 1rem;
      font-weight: 400;
      border-bottom: ${computeRemSize(1)} solid ${COLORS.GREY_BORDER};
    `}

  border-left: ${computeRemSize(3)} solid ${p => {
    if (p.$selectionType === 'exact') return COLORS.PRIMARY;
    else return 'transparent';
  }};

  background-color: ${p => {
    if (p.$selectionType === 'exact') return p.theme.item.selected.backgroundColor;
    else return 'initial';
  }};

  ${TreeLeafTitle} {
    ${p => {
      if (p.$selectionType === 'parent' && p.$level > 0)
        return css`
          &::before {
            content: '';
            position: absolute;
            left: ${computeRemSize(-7)};
            top: 50%;
            transform: translateY(-50%);
            width: ${computeRemSize(5)};
            height: ${computeRemSize(10)};
            background-color: ${COLORS.PRIMARY};
          }
        `;
    }}
  }

  &:hover {
    background-color: ${p => p.theme.item.hovered.backgroundColor};
`;
