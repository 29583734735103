import React, { useCallback, useMemo } from 'react';
import { EntityIdentificationType, EntityListType } from '@ydistri/api-sdk';
import { useSelector } from 'react-redux';
import { ReduxState, useAppDispatch } from '../../../../../store';
import { setProductItemsContent } from '../../entityListAdministrationSlice';
import {
  EntityListRow,
  getParseableStringFromPastedData,
  selectEntityListParser,
} from '../../entityListsLib';
import EntityListItemsTextArea from '../../../common/edit/EntityListItemsTextArea';

interface EntityListItemsAreaProps {
  entityListType: EntityListType;
  id?: string;
  label?: string;
}

const EntityListItemsArea: React.FC<EntityListItemsAreaProps> = ({ entityListType, label, id }) => {
  const warehouseIdentificationType = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].storeIdentificationType,
  );
  const productIdentificationType = useSelector(
    (state: ReduxState) =>
      state.entityListsAdministration[entityListType].productIdentificationType,
  );
  const productItemsContent = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].productItemsContent,
  );

  const dispatch = useAppDispatch();

  const itemsPlaceholder = useMemo(() => {
    return `Paste store ${
      warehouseIdentificationType === EntityIdentificationType.Code ? 'code' : 'ID'
    }, product ${
      productIdentificationType === EntityIdentificationType.Code ? 'code' : 'ID'
    } and quantity from Excel (or separated by semicolons)`;
  }, [productIdentificationType, warehouseIdentificationType]);

  /**
   * Saves the content of the text area to state
   * Called on text area's change event
   */
  const entityListItemsHandler = useCallback(
    (newValue: string) => {
      dispatch(setProductItemsContent({ entityListType, data: newValue }));
    },
    [dispatch, entityListType],
  );

  const serializer = useCallback((data: EntityListRow<typeof entityListType>[]) => {
    return getParseableStringFromPastedData(data, ',');
  }, []);

  return (
    <EntityListItemsTextArea<typeof entityListType>
      entityListType={entityListType}
      label={label}
      id={id ?? 'EntityListItemsInput'}
      value={productItemsContent}
      placeholder={itemsPlaceholder}
      onValueChanged={entityListItemsHandler}
      parserProvider={selectEntityListParser}
      serializer={serializer}
    />
  );
};

export default EntityListItemsArea;
