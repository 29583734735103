import React, { useEffect } from 'react';
import { setSubpage } from '../../../routes/routerSlice';
import { ConfigurationSubpage } from '../Configuration';
import { styled } from 'styled-components';
import BrandConfigurations from './BrandConfiguration/BrandConfigurations';
import BrandDetail from './BrandDetail/BrandDetail';
import { MainContent } from '@ydistri/ds';
import { useAppDispatch } from '../../../store';

const ConfigurationRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

const ConfigurationBrands: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSubpage(ConfigurationSubpage.BRANDS));
  });

  return (
    <MainContent>
      <ConfigurationRow>
        <BrandConfigurations />
        <BrandDetail />
      </ConfigurationRow>
    </MainContent>
  );
};

export default ConfigurationBrands;
