import React, { useCallback, useMemo } from 'react';
import { ConfigurationSelect } from '@ydistri/ds';
import { ConfigurationFieldStatus } from '@ydistri/utils';
import { getScopeTypeSelectOptions } from './scopeLib';
import { updateScopeEntitySelectionOption } from './scopeConfigurationSlice';
import { ConfigurationRuleScopeEntityType, ConfigurationRuleScopeMode } from '@ydistri/api-sdk';
import { createDebugLog } from '../../../lib/utils/logging';
import { useAppDispatch } from '../../../store';

const debugLog = createDebugLog('Scopes', 'ScopeTypeSelect');

interface ScopeTypeSelectProps {
  playground: boolean;
  entityType: ConfigurationRuleScopeEntityType;
  selectionOption: ConfigurationRuleScopeMode;
}

const ScopeTypeSelect: React.FC<ScopeTypeSelectProps> = ({
  playground,
  selectionOption,
  entityType,
}) => {
  const dispatch = useAppDispatch();

  const onChangeHandler = useCallback(
    (value: ConfigurationRuleScopeMode) => {
      debugLog('onChangeHandler - ', entityType, value);
      dispatch(updateScopeEntitySelectionOption({ entityType, selectionOption: value }));
    },
    [dispatch, entityType],
  );

  const options = useMemo(() => getScopeTypeSelectOptions(playground), [playground]);

  return (
    <ConfigurationSelect<ConfigurationRuleScopeMode>
      options={options}
      value={selectionOption}
      onChange={onChangeHandler}
      showArrow={true}
      status={ConfigurationFieldStatus.DEFAULT}
      size="large"
      width="100%"
    />
  );
};

export default ScopeTypeSelect;
