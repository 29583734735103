import { css, styled } from 'styled-components';

/** Left aligned header cell */
export const LineLabelHeaderCell = styled.th`
  text-align: left;
`;

/** Right aligned header cell */
export const ValueHeaderCell = styled.th`
  text-align: right;
`;

export const ChartLineLegendCell = styled.td`
  width: 3rem;
`;

/** Left aligned table cell */
export const LineLabelCell = styled.td`
  text-align: left;
`;

/** Right aligned table cell */
export const ValueCell = styled.td`
  text-align: right;
`;

export const ContentTable = styled.table`
  flex-grow: 1;
  border-collapse: collapse; //so the lines are not interrupted by a small gap
`;

export const ContentHeader = styled.tr`
  & th {
    padding-top: 1rem;
    font-weight: 700;
    border-bottom: calc(2rem / ${({ theme }) => theme.rootFontSize}) solid #eee;
  }
`;

/** Row in Tooltip table for lines or total.
 * Total row draws a line above it
 */
export const ContentTableRow = styled.tr<{ $isTotal?: boolean }>`
  ${p =>
    p.$isTotal
      ? css`
          border-top: calc(2rem / ${({ theme }) => theme.rootFontSize}) solid #eee;
        `
      : ''};
`;
