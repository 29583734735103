import { EntityListType } from '@ydistri/api-sdk';
import { EntityListImportStep, setImportStep } from './entityListAdministrationSlice';
import { useCallback } from 'react';
import { useAppDispatch } from '../../../store';

type SetImportStepCallback = (step: EntityListImportStep) => void;

export const useSetImportStep = (entityListType: EntityListType): SetImportStepCallback => {
  const dispatch = useAppDispatch();

  return useCallback(
    (x: EntityListImportStep) => {
      dispatch(
        setImportStep({
          entityListType,
          data: x,
        }),
      );
    },
    [dispatch, entityListType],
  );
};
