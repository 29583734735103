import React, { useCallback, useMemo } from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { AvailableModals, closeModal } from './modalSlice';
import { ReduxState, useAppDispatch } from '../../store';
import ScopeDefinition from '../Configuration/ConfigurationStoresAndDepartments/ScopeDefinition';
import {
  isScopeCorrect,
  scopeConfigurationErrorStrings,
  ScopeConfigurationErrorType,
  ScopeModalType,
  ViewMode,
} from '../Configuration/ConfigurationStoresAndDepartments/scopeLib';
import { setModalScope } from '../Configuration/ConfigurationStoresAndDepartments/scopeConfigurationSlice';
import { Text } from '@ydistri/ds';
import { useCalculationConfiguration } from '../Configuration/ConfigurationStoresAndDepartments/useCalculationConfiguration';
import {
  transformToConfigurationRule,
  usePostConfigurationRuleMutation,
  usePutConfigurationRuleMutation,
} from '../Configuration/ConfigurationStoresAndDepartments/apiScopes';
import { useTemplateOrCalculation } from '../../hooks/useTemplateOrCalculation';

const modalId = AvailableModals.SCOPE_EDIT;

const ScopeEditModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { useClosing, useSuperTarget } = useCalculationConfiguration();
  const open = useSelector((state: ReduxState) => state.modal.openedModal === modalId);
  const scopeId = useSelector((state: ReduxState) => state.modal.modalData[modalId]?.scopeId ?? 0);

  const modalScope = useSelector((state: ReduxState) => state.scope.modalScope);
  const modalType = useSelector((state: ReduxState) => state.scope.modalType);
  const modalChanged = useSelector((state: ReduxState) => state.scope.modalChanged);

  const templateOrCalculation = useTemplateOrCalculation();
  const isCalculation = templateOrCalculation.type === 'Calculation';
  const [postConfigurationRuleMutation] = usePostConfigurationRuleMutation();
  const [putConfigurationRuleMutation] = usePutConfigurationRuleMutation();

  const onCloseHandler = useCallback(() => {
    dispatch(setModalScope(undefined));
    dispatch(closeModal());
  }, [dispatch]);

  const onSubmitHandler = useCallback(() => {
    if (modalScope) {
      const configurationRule = transformToConfigurationRule(modalScope);
      if (modalScope.creating) {
        postConfigurationRuleMutation({
          templateId: templateOrCalculation.id,
          data: configurationRule,
        });
        // dispatch(setScope(data));
      } else {
        putConfigurationRuleMutation({
          id: modalScope.id,
          templateId: templateOrCalculation.id,
          data: configurationRule,
        });
      }
    }
    dispatch(setModalScope(undefined));
    dispatch(closeModal());
  }, [
    dispatch,
    modalScope,
    postConfigurationRuleMutation,
    putConfigurationRuleMutation,
    templateOrCalculation.id,
  ]);

  const includeConfiguration = modalScope?.creating === true;

  const errorType = useMemo(
    () =>
      isScopeCorrect(
        modalScope,
        useClosing,
        useSuperTarget,
        includeConfiguration,
        (modalScope?.priority ?? 0) > 0,
      ),
    [modalScope, includeConfiguration, useClosing, useSuperTarget],
  );

  const okButtonProps = useMemo(
    () => ({
      disabled: errorType !== ScopeConfigurationErrorType.CORRECT,
    }),
    [errorType],
  );

  return (
    <Modal
      width={800}
      title={modalScope?.creating ? 'Create new exception' : 'Edit exception scope'}
      open={open}
      cancelButtonProps={{
        onClick: onCloseHandler,
      }}
      closable={false}
      okText={modalScope?.creating ? 'Create' : 'Save'}
      okButtonProps={okButtonProps}
      onOk={onSubmitHandler}
    >
      <ScopeDefinition
        scopeId={scopeId}
        viewMode={
          modalType === ScopeModalType.EDIT
            ? ViewMode.ADJUST_SCOPE_MODAL
            : ViewMode.NEW_EXCEPTION_MODAL
        }
        disabled={isCalculation}
      />
      {!includeConfiguration &&
        modalChanged &&
        errorType !== ScopeConfigurationErrorType.CORRECT && (
          <Text $type="danger">{scopeConfigurationErrorStrings[errorType]}</Text>
        )}
    </Modal>
  );
};

export default ScopeEditModal;
