import { Dropdown, DropdownProps } from 'antd';
import { AvatarWithLabelWrapper, computeRemSize, StyledAvatar, Text } from '@ydistri/ds';
import { wordInitials } from '../../helpers/wordInitials';
import React, { useMemo } from 'react';
import { icons, IconType } from '../icons/icons';
import { RoutingPage } from '../../routes/routerSlice';
import { apiSlice } from '../../apis/api';
import { LSKey, removeItem } from '../../store/localStore';
import { Link, useNavigate, useParams } from 'react-router';
import { useSider } from '../../hooks/useSider';
import { useUser } from '../../hooks/useUser';
import { FiChevronDown } from 'react-icons/fi';
import { setUser } from '../../store/userSlice';
import { ItemType } from 'antd/es/menu/interface';
import { useAppDispatch } from '../../store';

const dropdownTriggers: DropdownProps['trigger'] = ['click'];
const dropdownOverlayStyle = { width: computeRemSize(200) };
const dropdownAlign: DropdownProps['align'] = { offset: [0, 10] };

const UserMenu: React.FC = () => {
  const { isLeftMenuCollapsed } = useSider();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const projectShortName = params.projectShortName;
  const user = useUser();

  const userOptionDropdownItems: ItemType[] = useMemo(
    () =>
      projectShortName !== undefined
        ? [
            {
              label: <Link to={`/${projectShortName}/${RoutingPage.USER_SETTINGS}`}>Settings</Link>,
              key: '1',
              icon: icons[IconType.SETTING_OUTLINED],
            },
            {
              type: 'divider',
            },
            {
              label: 'Logout',
              key: '3',
              icon: icons[IconType.LOGOUT_OUTLINED],
              onClick: () => {
                dispatch(apiSlice.util.resetApiState());
                removeItem(LSKey.TOKEN);
                removeItem(LSKey.TOKEN_EXPIRATION);
                removeItem(LSKey.USER);
                dispatch(setUser(undefined));
                navigate('/login');
              },
            },
          ]
        : [],
    [projectShortName, navigate, dispatch],
  );

  const dropdownMenu = useMemo(
    () => ({ items: userOptionDropdownItems }),
    [userOptionDropdownItems],
  );

  return (
    <Dropdown
      trigger={dropdownTriggers}
      menu={dropdownMenu}
      overlayStyle={dropdownOverlayStyle}
      align={dropdownAlign}
    >
      {user && (
        <AvatarWithLabelWrapper>
          <StyledAvatar>{wordInitials(user.fullName)}</StyledAvatar>
          {isLeftMenuCollapsed ? null : <Text $size="large">{user.fullName}</Text>}
          <FiChevronDown size={computeRemSize(20)} />
        </AvatarWithLabelWrapper>
      )}
    </Dropdown>
  );
};

export default UserMenu;
