import { TLineType } from '../../../lib/charts/saleChartsTypes';

export enum ContentDataType {
  DETAIL,
  TOTAL,
  SALE_USED_FOR_FORECAST,
  NON_SALE_DATA,
}

export interface TooltipContentSection {
  title: string;
  columnNames: string[];
  data: ContentData[];
}

export interface ContentData {
  label: string;
  primaryColumnValue: string;
  secondaryColumnValue?: string;
  columnValues: string[];
  type: ContentDataType;
  line?: TLineType;
  supplementaryData?: ContentData[];
}

export interface TooltipPosition {
  visible: boolean;
  top: string | number;
  left: string | number;
  right: string | number;
}

export type TooltipType = 'date' | 'date-range';
