import React, { useCallback, useMemo } from 'react';
import { useTemplateOrCalculation } from '../../../../../../../hooks/useTemplateOrCalculation';
import { useGetCalculationSkusQuery } from '../../../../../../Calculations/apiCalculationsSkus';
import LoadingIndicator from '../../../../../../../components/global/LoadingIndicator/LoadingIndicator';
import { useGetCalculationQuery } from '../../../../../apiCalculationDetail';
import CalculationsTable from '../../../../../../Calculations/components/table/CalculationsTable';
import {
  mergeCalculationWithSubordinateCalculations,
  SubcalculationSelectorCalculationData,
  getSubordinateCalculationsTableColumnExtension,
  SubcalculationExtension,
  SUBCALCULATION_SOURCE_SELECTOR_TABLE_ID,
  SUBCALCULATION_TARGET_SELECTOR_TABLE_ID,
} from './subordinateCalculationSelectorLib';
import { CalculationCheckboxMode } from '../../../../../../Calculations/components/table/CalculationCheckbox/calculationCheckboxLib';
import { SkuRedistributionPaneType } from '../../../../redistributionTypes';
import { Column, computeRemSize, Text } from '@ydistri/ds';

interface SubordinateCalculationSelectorProps {
  skuId: number;
  sourceOrTarget: SkuRedistributionPaneType;
}

const SubordinateCalculationSelector: React.FC<SubordinateCalculationSelectorProps> = ({
  skuId,
  sourceOrTarget,
}) => {
  const templateOrCalculation = useTemplateOrCalculation();

  const { data: calculation, isFetching: calculationFetching } = useGetCalculationQuery(
    templateOrCalculation.id,
  );

  const { data: skuConfiguration, isFetching: skuConfigurationFetching } =
    useGetCalculationSkusQuery({
      skuId: skuId,
      calculationId: templateOrCalculation.id,
    });

  const data: SubcalculationSelectorCalculationData[] = useMemo(
    () => mergeCalculationWithSubordinateCalculations(calculation, skuConfiguration),
    [calculation, skuConfiguration],
  );

  const selectableRules = useCallback(
    (c: SubcalculationSelectorCalculationData) => !c.available,
    [],
  );

  const columnsExtension = useMemo(
    () => getSubordinateCalculationsTableColumnExtension(skuId, sourceOrTarget),
    [skuId, sourceOrTarget],
  );

  const isFetching = calculationFetching || skuConfigurationFetching;

  if (isFetching) return <LoadingIndicator />;

  return (
    <Column $gap={computeRemSize(16)}>
      <Text $bold>Show configurations from:</Text>
      <CalculationsTable<SubcalculationExtension>
        calculationsTableId={
          sourceOrTarget === 'Source'
            ? SUBCALCULATION_SOURCE_SELECTOR_TABLE_ID
            : SUBCALCULATION_TARGET_SELECTOR_TABLE_ID
        }
        predefinedCalculations={data}
        variant="compact"
        showActions={false}
        displayCreationTime={false}
        displayFinalRedistribution={false}
        linksEnabled={false}
        selectableMode={CalculationCheckboxMode.SINGLE}
        selectableRules={selectableRules}
        expandableSubmerged={false}
        columnsExtension={columnsExtension}
        autoHeight={true}
      />
    </Column>
  );
};

export default SubordinateCalculationSelector;
