import React, { PropsWithChildren } from 'react';
import { styled } from 'styled-components';
import { computeRemSize } from '@ydistri/ds';
import { Link } from 'react-router';

export type LinkUrlProvider<T extends object> = (record: T) => string | undefined;

export interface LinkCellProps<T extends object> extends PropsWithChildren {
  record: T;
  urlProvider: LinkUrlProvider<T>;
  active?: boolean;
  compact?: boolean;
}

interface WrapperProps {
  $compact?: boolean;
}

export const CellWrapper = styled.div<WrapperProps>`
  padding-top: ${p => computeRemSize(p.$compact ? 4 : 10)} !important;
  padding-right: ${p => computeRemSize(p.$compact ? 4 : 8)} !important;
  padding-bottom: ${p => computeRemSize(p.$compact ? 4 : 10)} !important;
  padding-left: ${p => computeRemSize(p.$compact ? 4 : 8)} !important;
`;

const RecordLink = styled(Link)<WrapperProps>`
  color: inherit;
  text-decoration: none;

  /* to make the link stretch across the cell so the whole cell is clickable */
  display: block;
  width: 100%;
  height: 100%;
  padding-top: ${p => computeRemSize(p.$compact ? 4 : 10)} !important;
  padding-right: ${p => computeRemSize(p.$compact ? 4 : 8)} !important;
  padding-bottom: ${p => computeRemSize(p.$compact ? 4 : 10)} !important;
  padding-left: ${p => computeRemSize(p.$compact ? 4 : 8)} !important;

  &:hover {
    color: inherit;
  }
`;

const LinkCell = <T extends object>({
  record,
  children,
  urlProvider,
  active = true,
  compact = false,
  ...rest // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
}: LinkCellProps<T>): React.ReactElement => {
  const url = urlProvider(record);
  if (url && active) {
    return (
      <RecordLink to={url} rel="noreferrer" $compact={compact} {...rest}>
        {children}
      </RecordLink>
    );
  }

  return <CellWrapper $compact={compact}>{children}</CellWrapper>;
};

export default LinkCell;
