import React, { useEffect, useMemo, useState } from 'react';
import CategoryTree from '../../../components/global/CategoryTree/CategoryTree';
import { setSubpage } from '../../../routes/routerSlice';
import { ConfigurationSubpage } from '../Configuration';
import CategoryDrillDown from './CategoryDrillDown/CategoryDrillDown';
import { MainContent, RowCentered, Section } from '@ydistri/ds';
import StoreDrillDown from './StoreDrillDown/StoreDrillDown';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useSearchParams } from 'react-router';
import { DRILL_DOWN_TYPE_URL_PARAM, SkuTypeForDrilldown } from './drillDownLib';
import { CalculationDetailSubpages } from '../../Calculations/calculationsLib';
import { SkuTypeButton } from './SkuTypeButton';
import AlertOutdated from '../../../components/global/AlertOutdated/AlertOutdated';
import { useGetCalculationQuery } from '../../CalculationDetail/apiCalculationDetail';
import { CalculationMode } from '@ydistri/api-sdk';
import { useAppDispatch } from '../../../store';

const ConfigurationDrillDown: React.FC = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const templateOrCalculation = useTemplateOrCalculation();
  const { data: calculationData } = useGetCalculationQuery(templateOrCalculation.id);
  const [selectedSkuType, setSelectedSkuType] = useState<SkuTypeForDrilldown>('total');

  const displaySkuTypeButtons = calculationData?.mode !== CalculationMode.Computed;
  const takeSupplyAndRevenueFromAttributes = calculationData?.mode !== CalculationMode.Computed;
  const omitPotentialColumns = calculationData?.mode !== CalculationMode.Computed;

  const drillDownType = useMemo(
    () => searchParams.get(DRILL_DOWN_TYPE_URL_PARAM) ?? 'categories',
    [searchParams],
  );

  useEffect(() => {
    dispatch(
      setSubpage(
        templateOrCalculation.type === 'Calculation'
          ? CalculationDetailSubpages.DRILLDOWN
          : ConfigurationSubpage.DRILLDOWN,
      ),
    );
  }, [dispatch, templateOrCalculation.type]);

  const headerActions = useMemo(() => {
    if (displaySkuTypeButtons) return null;
    return (
      <RowCentered $gap="2rem">
        {drillDownType === 'categories' && (
          <RowCentered $gap="0.5rem">
            <SkuTypeButton
              skuType="total"
              selectedSkuType={selectedSkuType}
              setSelectedSkuType={setSelectedSkuType}
            />
            <SkuTypeButton
              skuType="DeadStock"
              selectedSkuType={selectedSkuType}
              setSelectedSkuType={setSelectedSkuType}
            />
            <SkuTypeButton
              skuType="SlowMover"
              selectedSkuType={selectedSkuType}
              setSelectedSkuType={setSelectedSkuType}
            />
            <SkuTypeButton
              skuType="FastMover"
              selectedSkuType={selectedSkuType}
              setSelectedSkuType={setSelectedSkuType}
            />
          </RowCentered>
        )}
      </RowCentered>
    );
  }, [drillDownType, displaySkuTypeButtons, selectedSkuType]);

  return (
    <>
      {drillDownType === 'stores' && <CategoryTree />}
      <MainContent>
        <Section header="Drill down" headerActions={headerActions}>
          <AlertOutdated />
          {drillDownType === 'categories' && (
            <CategoryDrillDown
              selectedSkuType={selectedSkuType}
              takeSupplyAndRevenueFromAttributes={takeSupplyAndRevenueFromAttributes}
              omitPotentialColumns={omitPotentialColumns}
            />
          )}
          {drillDownType === 'stores' && <StoreDrillDown />}
        </Section>
      </MainContent>
    </>
  );
};

export default ConfigurationDrillDown;
