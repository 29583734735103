import {
  LineTooltipConfig,
  LineTypesTuple,
  MonthlyTransactionsData,
  TLineType,
  TParsedTransactions,
  Units,
} from '../../../../../../../../lib/charts/saleChartsTypes';
import {
  ContentData,
  ContentDataType,
  TooltipContentSection,
} from '../../../../../../../../components/global/SalesChartTooltip/saleChartTooltipTypes';
import { addVat, NonSaleDataByDay } from '../../../../../../../../lib/charts/saleChartsLib';
import { formatMonetaryValue, formatNumber } from '@ydistri/utils';

/**
 * Generates tooltip data for SkuSaleChart
 * For each line type it generates a TooltipContentSection with data for each line of this type
 */
export class SkuSaleChartTooltipGenerator {
  // eslint-disable-next-line max-params -- we need all these parameters
  public generateTooltipData = (
    lines: TLineType[],
    saleData: TParsedTransactions,
    unit: Units,
    currency: string,
    isFuture: boolean,
    vat: number,
    includeLineTypes: string[],
    lineTypesConfig: Record<LineTypesTuple[number], LineTooltipConfig>,
    showAverageValue: boolean = false,
    nonSaleDataByDay?: NonSaleDataByDay,
  ): TooltipContentSection[] => {
    const result: TooltipContentSection[] = [];

    // first find out which line types we can actually use
    const availableLineTypes = this.getAvailableLineTypes(includeLineTypes, lines, saleData);

    if (availableLineTypes.length > 0) {
      return availableLineTypes.map(lineType => {
        const availableLinesOfSameType = lines.filter(line =>
          this.isLineTypeAvailable(lineType, line, saleData),
        );
        const lineTypeConfig = lineTypesConfig[lineType];
        return this.generateSection(
          availableLinesOfSameType,
          saleData,
          unit,
          currency,
          isFuture,
          vat,
          lineTypeConfig,
          lineType === 'Sale' && showAverageValue,
          nonSaleDataByDay,
        );
      });
    }
    return result;
  };

  /**
   * Get line types that are not hidden and have data in saleData
   * @param requestedLineTypes
   * @param lines
   * @param saleData
   */
  private getAvailableLineTypes = (
    requestedLineTypes: string[],
    lines: TLineType[],
    saleData: TParsedTransactions,
  ): string[] => {
    return requestedLineTypes.filter(requestedLineType => {
      const linesOfSameType = lines.filter(line => line.type === requestedLineType);
      return linesOfSameType.some(line =>
        this.isLineTypeAvailable(requestedLineType, line, saleData),
      );
    });
  };

  /**
   * Checks if lineType is the same type as line, is not hidden and saleData has data for this line, and it is not null and quantity is more than zero
   * @param lineType required line type
   * @param line line data
   * @param saleData sale data
   */
  private isLineTypeAvailable = (
    lineType: string,
    line: TLineType,
    saleData: TParsedTransactions,
  ): boolean => {
    if (line.type !== lineType) {
      return false;
    }

    const info = 'lineInfo' in line ? line.lineInfo : line.barInfo;
    if (info.hidden) {
      return false;
    }
    const lineData = saleData.data[line.name];
    if (lineData === null) {
      return false;
    }

    const quantity = this.getQuantity(line, lineData);
    return quantity !== null;
  };

  /**
   * Get quantity from lineData. If line is SaleUsedForForecast, return quantityToThisPriority if it is not null and more than zero, otherwise return quantity
   * @param line
   * @param lineData
   * @return quantity or null if quantity is not available
   */
  private getQuantity = (line: TLineType, lineData: MonthlyTransactionsData): number | null => {
    if (line.name === 'SaleUsedForForecast') {
      if (lineData.quantityToThisPriority !== null && lineData.quantityToThisPriority > 0) {
        return lineData.quantityToThisPriority;
      }
    } else {
      return lineData.quantity;
    }

    return null;
  };

  private generateSection = (
    lines: TLineType[],
    saleData: TParsedTransactions,
    unit: Units,
    currency: string,
    isFuture: boolean,
    vat: number,
    lineTypeConfig: LineTooltipConfig,
    showAverageValue: boolean = false,
    nonSaleDataByDay?: NonSaleDataByDay,
  ): TooltipContentSection => {
    const contentSection: TooltipContentSection = {
      title: lineTypeConfig.title,
      data: [],
      columnNames: [],
    };

    const insertAverageValue = showAverageValue && unit === Units.QUANTITY;

    switch (unit) {
      case Units.VALUE: {
        contentSection.columnNames.push('Value');
        break;
      }
      case Units.QUANTITY: {
        contentSection.columnNames.push('Qty');
        break;
      }
    }

    if (isFuture) {
      contentSection.columnNames.push('Cumulative');
    }

    if (insertAverageValue) {
      contentSection.columnNames.push('Avg. price');
    }

    let total = 0;
    let totalCumulative = 0;

    lines.forEach(line => {
      const lineData = saleData.data[line.name];

      if (!lineData) {
        return;
      }
      const isSalesUsedForForecast = line.name === 'SaleUsedForForecast';

      // no SaleUsedForForecast line if we are in the future
      if (isSalesUsedForForecast && isFuture) {
        return;
      }

      const contentData: ContentData = {
        type: isSalesUsedForForecast
          ? ContentDataType.SALE_USED_FOR_FORECAST
          : ContentDataType.DETAIL,
        label: line.label,
        primaryColumnValue: '',
        columnValues: [],
        line: line,
      };

      switch (unit) {
        case Units.VALUE: {
          if (lineData.value !== null) {
            const lineDataValue = isSalesUsedForForecast
              ? lineData.valueToThisPriority
              : lineData.value;

            const primaryValue = formatMonetaryValue(currency, lineDataValue);
            contentData.primaryColumnValue = primaryValue;
            contentData.columnValues.push(primaryValue);

            if (isFuture) {
              const secondaryValue = formatMonetaryValue(currency, lineData.valueCumulative);
              contentData.secondaryColumnValue = secondaryValue;
              contentData.columnValues.push(secondaryValue);
              totalCumulative += lineData.valueCumulative ?? 0;
            } else {
              total += lineData.value;
            }
          }
          break;
        }
        case Units.QUANTITY: {
          if (lineData.quantity !== null) {
            const lineDataQuantity = isSalesUsedForForecast
              ? (lineData.quantityToThisPriority ?? 0)
              : lineData.quantity;

            const primaryValue = formatNumber(lineDataQuantity);
            contentData.primaryColumnValue = primaryValue;
            contentData.columnValues.push(primaryValue);

            if (isFuture) {
              const secondaryValue = formatNumber(lineData.quantityCumulative ?? 0);
              contentData.secondaryColumnValue = secondaryValue;
              contentData.columnValues.push(secondaryValue);
              totalCumulative += lineData.quantityCumulative ?? 0;
            }

            //avg value of sales - not shown in case of row "sales used for forecast", "dash" in case of Quantity = 0, value with currency otherwise
            if (insertAverageValue) {
              if (lineData.value !== null && lineData.value > 0) {
                const avgValue = formatMonetaryValue(currency, addVat(lineData.avgValue, vat), 2);
                contentData.secondaryColumnValue = avgValue;
                contentData.columnValues.push(avgValue);
              } else {
                const noValue = '—';
                contentData.secondaryColumnValue = noValue;
                contentData.columnValues.push(noValue);
              }
            }
            total += lineData.quantity;

            if (line.type === 'InboundOutbound' && nonSaleDataByDay) {
              const nonSalesData =
                line.name === 'Inbound'
                  ? nonSaleDataByDay[saleData.dates.stringFrom]?.inbounds
                  : nonSaleDataByDay[saleData.dates.stringFrom]?.outbounds;
              contentData.supplementaryData = nonSalesData?.map(inbound => {
                return {
                  type: ContentDataType.NON_SALE_DATA,
                  label: inbound.customerTypeId,
                  primaryColumnValue: formatNumber(inbound.quantity),
                  columnValues: [formatNumber(inbound.quantity)],
                };
              });
            }
          }

          break;
        }
      }
      contentSection.data.push(contentData);
    });

    //insert total line if specified in lineTypeConfig
    if (lineTypeConfig.showTotal) {
      const primaryColumnValue =
        unit === Units.VALUE ? formatMonetaryValue(currency, total) : formatNumber(total);
      const cumulativeTotalValue =
        unit === Units.VALUE
          ? formatMonetaryValue(currency, totalCumulative)
          : formatNumber(totalCumulative);

      const columnValues = [primaryColumnValue];
      if (isFuture) {
        columnValues.push(cumulativeTotalValue);
      }

      contentSection.data.push({
        type: ContentDataType.TOTAL,
        label: 'Total',
        primaryColumnValue,
        secondaryColumnValue: isFuture ? cumulativeTotalValue : undefined,
        columnValues,
      });
    }

    contentSection.data.sort((left, right) => {
      return left.type - right.type;
    });

    return contentSection;
  };
}
