import { CalculationWithOverviewResponse } from '@ydistri/api-sdk';
import { Column, Row, Text, Title } from '@ydistri/ds';
import CalculationsTable from '../../../Calculations/components/table/CalculationsTable';
import React, { useMemo } from 'react';
import LoadingIndicator from '../../../../components/global/LoadingIndicator/LoadingIndicator';
import { InfoIconTooltip } from '../../../../components/icons/InfoIconTooltip';
import { SkuRedistributionPaneType } from '../../../CalculationDetail/Redistribution/redistributionTypes';
import { getSkuCalculationsTableColumnExtension } from './getSkuCalculationsTableColumnExtension';

interface SkuCalculationsProps {
  id: string;
  skuId: number;
  sourceOrTarget: SkuRedistributionPaneType;
  calculations: CalculationWithOverviewResponse[] | undefined;
  fetching: boolean;
  title: string;
}

const SkuCalculations: React.FC<SkuCalculationsProps> = ({
  id,
  skuId,
  sourceOrTarget,
  calculations,
  title,
  fetching,
}) => {
  const columnsExtension = useMemo(
    () => getSkuCalculationsTableColumnExtension(skuId, sourceOrTarget),
    [skuId, sourceOrTarget],
  );

  if (fetching) {
    return <LoadingIndicator />;
  } else {
    return (
      <Column $gap="4pt">
        <Row $gap="0.5rem">
          <Title $size="small">{title}</Title>
          <InfoIconTooltip content="Shows Production calculations created in the last 12 months" />
        </Row>
        {calculations && calculations.length > 0 ? (
          <CalculationsTable
            calculationsTableId={id}
            predefinedCalculations={calculations}
            variant="compact"
            showActions={false}
            selectable={false}
            autoHeight={true}
            displaySkuTypeValues={false}
            columnsExtension={columnsExtension}
          />
        ) : (
          <Text>None</Text>
        )}
      </Column>
    );
  }
};

export default SkuCalculations;
