import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { setSubpage } from '../../../routes/routerSlice';
import { Helmet } from 'react-helmet-async';
import { Label, MainContent, MainContentWrapper, RowWrap, Section } from '@ydistri/ds';
import SectionOverallStatistics from './SectionOverallStatistics';
import SectionRoutes from './SectionRoutes';
import SectionStores from './SectionStores';
import SectionRegions from './SectionRegions';
import StatisticsModal from './modal/StatisticsModal';
import { ReduxState, useAppDispatch } from '../../../store';
import { CalculationDetailSubpages } from '../../Calculations/calculationsLib';
import { Space } from 'antd';
import { useGetDepartmentsQuery } from '../../../apis/apiLists';
import DepartmentSelector, {
  DepartmentOption,
} from '../../Detail/sections/DetailContentHeaderSection/components/DepartmentSelector/DepartmentSelector';
import { setClusterType, setDepartmentId } from './statisticsSlice';
import { CalculationStatisticClusterType } from '@ydistri/api-sdk';
import { SegmentedValue } from 'antd/es/segmented';
import { useApplicationData } from '../../../hooks/useApplicationData';
import { useCurrency } from '../../../hooks/useCurrency';
import { formatNumber } from '@ydistri/utils';
import ButtonOptions from '../../../components/global/ButtonOptions/ButtonOptions';

export const clusterTypeOptions = [
  {
    label: 'Stores',
    value: CalculationStatisticClusterType.Store,
  },
  {
    label: 'Store-departments',
    value: CalculationStatisticClusterType.StoreDepartment,
  },
];

const CalculationStatistics: React.FC = () => {
  const dispatch = useAppDispatch();
  const { departments: hasDepartments } = useApplicationData();
  const templateOrCalculation = useTemplateOrCalculation();
  const { data: departments } = useGetDepartmentsQuery(templateOrCalculation);
  const modalType = useSelector((state: ReduxState) => state.statistics.modalOpened);
  const departmentId = useSelector((state: ReduxState) => state.statistics.departmentId);
  const clusterType = useSelector((state: ReduxState) => state.statistics.clusterType);
  const currencyId = useCurrency() ?? '...';

  useEffect(() => {
    dispatch(setSubpage(CalculationDetailSubpages.STATISTICS));
  }, [dispatch]);

  const onDepartmentChange = useCallback(
    (did: number | undefined) => {
      dispatch(setDepartmentId(did));
    },
    [dispatch],
  );

  const onClusterTypeChange = useCallback(
    (ct: SegmentedValue) => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      dispatch(setClusterType(ct as CalculationStatisticClusterType));
    },
    [dispatch],
  );

  const departmentSelectHeaderActions = useMemo(() => {
    let options: DepartmentOption[] = [];

    if (departments) {
      options = departments.array
        .filter(d => {
          if ('totalValue' in d && 'isEnabled' in d) {
            return d['totalValue'] > 0 && d['isEnabled'];
          }
          return true;
        })
        .map(d => ({
          id: d.id,
          name:
            d.name + ('totalValue' in d ? ` (${formatNumber(d['totalValue'])} ${currencyId})` : ''),
          customerDepartmentId: d.customerDepartmentId ?? null,
        }))
        .sort((left, right) => left.name.localeCompare(right.name));
    }

    return (
      <Space>
        <Label>Group by: </Label>
        <ButtonOptions
          options={clusterTypeOptions}
          value={clusterType}
          setValue={onClusterTypeChange}
        />
        <DepartmentSelector options={options} value={departmentId} onChange={onDepartmentChange} />
      </Space>
    );
  }, [clusterType, currencyId, departmentId, departments, onClusterTypeChange, onDepartmentChange]);

  return (
    <>
      <Helmet title={`Calculation ${templateOrCalculation.id} - Statistics`} />
      <MainContentWrapper $flexDirection="column">
        {hasDepartments && (
          <Section
            $ratio={0}
            data-id="StatisticsDepartmentSelector"
            header={`Statistics for ${
              departmentId
                ? 'department "' + departments?.object[departmentId]?.name + '"'
                : 'all departments'
            } grouped by ${
              clusterType === CalculationStatisticClusterType.Store ? 'stores' : 'store-departments'
            }`}
            headerActions={departmentSelectHeaderActions}
          />
        )}
        <MainContent>
          <Section data-id="StatisticsOverall">
            <RowWrap>
              <SectionOverallStatistics />
              <SectionRoutes />
            </RowWrap>
          </Section>
          <Section data-id="StatisticsStores">
            <SectionStores />
          </Section>
          <Section data-id="StatisticsRegions">
            <SectionRegions />
          </Section>
          {modalType && <StatisticsModal statisticsModalType={modalType} />}
        </MainContent>
      </MainContentWrapper>
    </>
  );
};

export default CalculationStatistics;
