import React, { useCallback, useEffect } from 'react';
import { EntityListType } from '@ydistri/api-sdk';
import { Button, Result } from 'antd';
import { IMPORT_STEPS, titleByEntityListType } from '../../entityListsLib';
import { ActionsBar, Column } from '@ydistri/ds';
import {
  setActiveScreenType,
  setImportStep,
  setSelectedEntityList,
} from '../../entityListAdministrationSlice';
import { ActiveScreenType } from '../../../common/administrationItemsLib';
import { useSelector } from 'react-redux';
import { useGetEntityListQuery } from '../../apiEntityLists';
import { ReduxState, useAppDispatch } from '../../../../../store';

interface EntityListStepFinalizeProps {
  entityListType: EntityListType;
}

const EntityListStepFinalize: React.FC<EntityListStepFinalizeProps> = ({ entityListType }) => {
  const dispatch = useAppDispatch();
  const selectedEntityList = useSelector(
    (state: ReduxState) => state.entityListsAdministration[entityListType].selectedEntityList,
  );

  const { data: entityListData, isFetching } = useGetEntityListQuery({
    entityListType,
    entityListId: selectedEntityList?.entityListId ?? 0,
  });

  useEffect(() => {
    if (!isFetching && entityListData) {
      dispatch(setSelectedEntityList({ entityListType, data: entityListData }));
    }
  }, [isFetching, entityListData, dispatch, entityListType]);

  const onClickHandler = useCallback(() => {
    dispatch(setActiveScreenType({ entityListType, data: ActiveScreenType.DETAIL, forced: true }));
    dispatch(setImportStep({ entityListType, data: IMPORT_STEPS.ITEM_IMPORT }));
  }, [entityListType, dispatch]);

  return (
    <Column $gap="1rem" $alignItems="center">
      <Result
        status="success"
        title={`${titleByEntityListType[entityListType]} finalized`}
        subTitle="You can now use it"
      />
      <ActionsBar>
        <Button data-testid="view-detail" type="primary" onClick={onClickHandler}>
          View detail
        </Button>
      </ActionsBar>
    </Column>
  );
};

export default EntityListStepFinalize;
