import { Chart, ChartDataset, TooltipModel } from 'chart.js';
import React from 'react';
import { NonSaleDataByDay, ParseTransactionsInput } from './saleChartsLib';

export const LINE_TYPES = ['Sale', 'Forecast', 'Supply', 'InboundOutbound'];
export type LineTypesTuple = typeof LINE_TYPES;

export interface LineTooltipConfig {
  title: string;
  showTotal: boolean;
}

export const lineTypeTooltipConfig: Record<LineTypesTuple[number], LineTooltipConfig> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Sale: { title: 'Sales', showTotal: true },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Forecast: { title: 'Forecasts', showTotal: false },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Supply: { title: '', showTotal: false },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  InboundOutbound: { title: '', showTotal: false },
};

export type TChartLineInfo = ChartDataset<'line'>;
export type TChartBarInfo = ChartDataset<'bar'>;

export interface MonthlyTransactionsData {
  quantity: number | null;
  /** Sum o quantities of all lines below this line's priority and this line's quantity */
  quantityToThisPriority: number | null;
  quantityCumulative: number | null;
  /** Sum of cumulative quantities of all lines below this line's priority and this line's cumulative quantity */
  quantityToThisPriorityCumulative: number | null;
  value: number | null;
  /** Sum o values of all lines below this line's priority and this line's value */
  valueToThisPriority: number | null;
  /** Sum of cumulative values of all lines below this line's priority and this line's cumulative value */
  valueToThisPriorityCumulative: number | null;
  valueCumulative: number | null;
  avgValue: number;
}

/**
 * Key of this object is a name of line (TLineType.name) for example
 * "SaleRegular" or "SalePromo".
 * We create a set of such objects for each line.
 */
export type TParsedMonthlyTransactionsData = Record<string, MonthlyTransactionsData | null>;

export type TParsedDailyTransactionsData = TParsedMonthlyTransactionsData;

export interface TParsedTransactions {
  month: number;
  year: number;
  dates: {
    stringFrom: string;
    stringTo: string;
    dateFrom: Date;
    dateTo: Date;
  };
  data: TParsedMonthlyTransactionsData;
}

export interface TParsedDailyTransactions {
  dates: {
    string: string;
    date: Date;
  };
  data: TParsedDailyTransactionsData;
}

export enum Units {
  VALUE = 'Value',
  QUANTITY = 'Quantity',
}

/**
 * Definition of a chart line like Sale Regular, Sale Promo, Forecast Min, Forecast Max
 */
export type TLineType = {
  /** Each line has a priority within its type. Lines are stacked on top of each other based on priority (from lower to higher) */
  priority: number;
  /** Code name of the line */
  name: string;
  /** Either Sale or Forecast */
  type: LineTypesTuple[number];
  /** Display label of the lin e*/
  label: string;
  /** How this line type affects the supply - just a multiplier! So +/-/0
   *  1 = increases supply by its quantity
   *  0 = doesn't change supply
   * -1 = decreases supply by its quantity
   * */
  supplyMultiplier?: number;
  displayLegend?: boolean;
  stack?: string;
} & (
  | {
      chartDatasetType?: 'line';
      lineInfo: TChartLineInfo;
    }
  | {
      chartDatasetType: 'bar';
      barInfo: TChartBarInfo;
    }
);

export type TCumulativeLine = Record<
  string,
  {
    quantity: number;
    value: number;
  }
>;

export type TChartLine = Record<string, (number | null)[]>;

export type SkuSaleGraphValueColumnType = 'avg' | 'value' | 'none';

export type TooltipCallback = (
  tooltipModel: TooltipModel<'line'>,
  lines: TLineType[],
  datasets: ChartDataset[],
  parsedMonthlyTransactions: TParsedTransactions[],
  applicationDate: Date,
  chartRef: React.RefObject<Chart<'line'>>,
  currency: string,
  vat: number,
  valueColumnType: SkuSaleGraphValueColumnType,
  chartType: ParseTransactionsInput['type'],
  nonSaleDataByDay?: NonSaleDataByDay,
) => Date | undefined;
