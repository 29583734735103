import { ConfigurationFieldStatus } from '@ydistri/utils';
import type { RequestStatusFlags } from '@reduxjs/toolkit/src/query/core/apiState';

export const rtkQueryPatchStatusToConfigurationFieldStatus = (
  patchConfigurationStatus: RequestStatusFlags,
): ConfigurationFieldStatus => {
  if (patchConfigurationStatus.isUninitialized) {
    return ConfigurationFieldStatus.DEFAULT;
  } else if (patchConfigurationStatus.isLoading) {
    return ConfigurationFieldStatus.LOADING;
  } else if (patchConfigurationStatus.isSuccess) {
    return ConfigurationFieldStatus.SUCCESS;
  } else {
    // if all previous conditions are false, then isError is true
    return ConfigurationFieldStatus.ERROR;
  }
};
