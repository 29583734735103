import { RedistributionImportInputData } from '../../../redistributionImportTypes';
import { useSelector } from 'react-redux';
import { ReduxState, useAppDispatch } from '../../../../../../../../store';
import React, { useCallback, useMemo } from 'react';
import {
  getParseableStringFromPastedData,
  selectRedistributionImportParser,
} from '../../../redistributionImportLib';
import { setImportContent } from '../../../../../../../Calculations/calculationsSlice';
import { Column } from '@ydistri/ds';
import ListItemsTextArea from '../../common/ListItemsTextArea';
import { ImportedItemRow } from '../../../importLib';
import useRedistributionImportFormat from '../../../useRedistributionImportFormat';
import useRedistributionImportKeys from '../../../useRedistributionImportKeys';

interface RedistributionImportTextAreaProps {
  label?: string;
  maxWidth?: string;
}

const RedistributionImportTextArea: React.FC<RedistributionImportTextAreaProps> = ({
  label,
  maxWidth,
}) => {
  const dispatch = useAppDispatch();
  const importContent = useSelector((state: ReduxState) => {
    return state.calculations.redistributionImport.inputContent;
  });

  const textAreaChangeHandler = useCallback(
    (newValue: string) => {
      dispatch(setImportContent(newValue));
    },
    [dispatch],
  );

  const importFormat = useRedistributionImportFormat();
  const redistributionKeys = useRedistributionImportKeys();

  const itemsPlaceholder = useMemo(() => {
    const identifiers = importFormat.map(identifier => identifier.label).join(', ');
    return `Paste ${identifiers} from Excel (or separated by semicolons)`;
  }, [importFormat]);

  const itemSerializer = useCallback(
    (data: ImportedItemRow<RedistributionImportInputData>[]) => {
      return getParseableStringFromPastedData(data, ',', redistributionKeys);
    },
    [redistributionKeys],
  );

  return (
    <Column $maxWidth={maxWidth}>
      <ListItemsTextArea<RedistributionImportInputData>
        id="RedistributionImportTextArea"
        label={label}
        value={importContent}
        placeholder={itemsPlaceholder}
        parserProvider={selectRedistributionImportParser}
        onValueChanged={textAreaChangeHandler}
        serializer={itemSerializer}
        parsedObjectKeys={redistributionKeys}
      />
    </Column>
  );
};

export default RedistributionImportTextArea;
