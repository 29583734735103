import React, { useCallback, useMemo } from 'react';
import { useTemplateOrCalculation } from '../../../../hooks/useTemplateOrCalculation';
import { useGetTargetListsForConfigurationQuery } from '../../apiTargetListConfiguration';
import { Popover, Spin } from 'antd';
import { styled } from 'styled-components';
import { MdContentPasteSearch, MdQueryStats } from 'react-icons/md';
import { AvailableModals, openModal } from '../../../Modals/modalSlice';
import { computeRemSize } from '@ydistri/ds';
import { useAppDispatch } from '../../../../store';

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const ClickableIconWrapper = styled.div`
  cursor: pointer;
`;

const TargetListConfigurationInfo: React.FC = () => {
  const templateOrCalculation = useTemplateOrCalculation();
  const dispatch = useAppDispatch();

  const { data: targetListConfig, isFetching } =
    useGetTargetListsForConfigurationQuery(templateOrCalculation);

  const targetList = useMemo(() => {
    if (targetListConfig && targetListConfig.length > 0) {
      return targetListConfig[0];
    }
  }, [targetListConfig]);

  const openTargetListModal = useCallback(() => {
    if (targetList) {
      dispatch(
        openModal({
          type: AvailableModals.TARGET_LIST_CONTENTS,
          data: {
            targetList,
          },
        }),
      );
    }
  }, [dispatch, targetList]);

  const icon: React.ReactNode = useMemo(() => {
    if (templateOrCalculation.type === 'Template') {
      return (
        <Popover content="Open contents of target list">
          <ClickableIconWrapper>
            <MdContentPasteSearch
              size={computeRemSize(20)}
              color="grey"
              onClick={openTargetListModal}
            />
          </ClickableIconWrapper>
        </Popover>
      );
    }
    return (
      <Popover content="Open statistics">
        <ClickableIconWrapper>
          <MdQueryStats size={computeRemSize(20)} color="grey" onClick={openTargetListModal} />
        </ClickableIconWrapper>
      </Popover>
    );
  }, [openTargetListModal, templateOrCalculation.type]);

  if (isFetching) {
    return <Spin spinning />;
  }
  if (!targetList) return null;

  return (
    <InfoWrapper>
      {`Item count: ${targetList.skuCount}`} {icon}{' '}
    </InfoWrapper>
  );
};

export default TargetListConfigurationInfo;
