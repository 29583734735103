import { CalculationDataType, CalculationTableParams } from '../../calculationsTypes';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  computeRemSize,
  InfiniteScrollParams,
  InfiniteScrollTable,
  Panel,
  Row,
  YColumnsType,
} from '@ydistri/ds';
import { generatePath, useParams } from 'react-router';
import { shallowEqual, useSelector } from 'react-redux';
import {
  useDeleteCalculationMutation,
  useGetCalculationsQuery,
  useLoadCalculationToTemplateMutation,
} from '../../apiCalculations';
import { ReduxState, useAppDispatch } from '../../../../store';
import { useUser } from '../../../../hooks/useUser';
import {
  CalculationMode,
  CalculationStatus,
  CalculationType,
  SortDirection,
} from '@ydistri/api-sdk';
import CalculationTableText from '../row/CalculationTableText';
import CalculationFinalRedistribution from '../row/CalculationFinalRedistribution';
import CalculationStatusComponent from '../row/CalculationStatusComponent';
import CalculationOwner from '../row/CalculationOwner';
import { format } from 'date-fns';
import { ExpandableConfig } from 'antd/es/table/interface';
import CalculationExpandController from '../statistics/CalculationExpandController';
import { addToast } from '@ydistri/utils';
import {
  setCalculationsTableParams,
  toggleExpandedRowKey,
  toggleExpandedSubmergedCalculations,
} from '../../calculationsSlice';
import { ROUTES } from '../../../../components/menu/menuLeftItemTemplate';
import { CalculationDetailSubpages, getRedistributionValues } from '../../calculationsLib';
import { Overlay } from '../../../../components/global/Overlay';
import OverlayMessage, {
  OverlayMessageIcon,
} from '../../../../components/global/ContentOverlay/OverlayComponents';
import { CSSProperties, styled } from 'styled-components';
import { useCurrency } from '../../../../hooks/useCurrency';
import LinkCell from './LinkCell';
import { createDebugLog } from '../../../../lib/utils/logging';
import CalculationRowTitle from '../row/CalculationRowTitle';
import { v4 as uuidv4 } from 'uuid';
import { produce } from 'immer';
import { useSelectedProjectCode } from '../../../../hooks/useSelectedProjectCode';
import CalculationCheckbox, {
  CALCULATION_CHECKBOX_CLASS_NAME,
} from './CalculationCheckbox/CalculationCheckbox';
import { skipToken } from '@reduxjs/toolkit/query';
import { CalculationCheckboxMode } from './CalculationCheckbox/calculationCheckboxLib';

const loadingOverlayContent = <OverlayMessage title="Loading" icon={OverlayMessageIcon.SPINNER} />;

const extractCellTitleParts = <T = object,>(
  calculation: CalculationExtended<T>,
): {
  title: string;
  description: string | null;
  status: CalculationStatus;
  type: CalculationType;
  isPap: boolean;
  finishExecutionUntil?: string | null;
  subordinateCalculationStatuses?: string;
} => {
  return {
    title: calculation.data.title,
    description: calculation.data.description ?? null,
    status: calculation.data.status,
    type: calculation.data.type,
    isPap: calculation.data.isPap,
    finishExecutionUntil: calculation.data.finishExecutionUntil,
    subordinateCalculationStatuses:
      'subordinateCalculations' in calculation.data
        ? calculation.data.subordinateCalculations?.map(c => c.status).join('-')
        : undefined,
  };
};

const CalculationsTableStyled = styled(
  InfiniteScrollTable<CalculationExtended, InfiniteScrollParams>,
)<{ $hasSelectedCalculation: boolean }>`
  .ant-table-wrapper .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 0;
  }

  .ant-table-cell.final-redistribution-cell {
    position: relative;
    padding: 0;
  }

  .calculationRowIncomplete {
    cursor: not-allowed;
  }

  .calculationRow {
    &.privateRow {
      background-color: #fffcf6;

      &:hover > td {
        background: #ffefcf !important;
      }
    }

    &.productionRow {
      background-color: #fafdfa;

      &:hover > td {
        background: #dff1df !important;
      }
    }

    &.expandedRow {
      background-color: #f9f9f9;

      & > td {
        border-bottom: 0;
      }

      &:hover > td {
        background: #f9f9f9 !important;
      }
    }

    &.selectedRow {
      background: #ffefcf !important;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }

      &:hover > td {
        background: #ffefcf !important;
      }
    }

    td.final-redistribution-cell {
      padding: 0;
    }
  }

  tr.ant-table-expanded-row {
    background-color: #fefefe;
  }

  .ant-table-middle .ant-table-tbody > tr:not(:first-child) > td {
    padding: 0;
  }

  .${CALCULATION_CHECKBOX_CLASS_NAME} {
    opacity: ${p => (p.$hasSelectedCalculation ? 1 : 0)};
  }

  .ant-table-cell-row-hover {
    .${CALCULATION_CHECKBOX_CLASS_NAME} {
      opacity: 1;
    }
  }
`;

export const CALCULATIONS_BLOCK_SIZE = 60;

const debugLog = createDebugLog('Calculations', 'CalculationsTable');
const debugLogRender = createDebugLog('Calculations', 'CalculationsTable', 'render');

export interface CalculationExtended<T = object> {
  data: CalculationDataType<T>;
  isExpanded: boolean;
  isBeingDeleted: boolean;
}

export interface ColumnsExtension<T = object> {
  afterKey: string;
  columns: YColumnsType<CalculationExtended<T>>[];
}

interface CalculationsTableProps<T = object> {
  calculationsTableId: string;
  variant?: 'full' | 'compact';
  linksEnabled?: boolean;
  showActions?: boolean;
  minHeight?: CSSProperties['minHeight'];
  autoHeight?: boolean;
  displaySkuTypeValues?: boolean;
  displayFinalRedistribution?: boolean;
  displayCreationTime?: boolean;
  selectable?: boolean;
  selectableMode?: CalculationCheckboxMode;
  selectableRules?: (c: CalculationDataType<T>) => boolean;
  predefinedCalculations?: CalculationDataType<T>[];
  expandableSubmerged?: boolean;
  columnsExtension?: ColumnsExtension<T>[];
}

const CalculationsTable = <T = object,>({
  calculationsTableId,
  variant = 'full',
  linksEnabled = true,
  showActions = true,
  minHeight,
  autoHeight = false,
  displaySkuTypeValues = true,
  displayFinalRedistribution = true,
  displayCreationTime = true,
  selectable = true,
  selectableMode = CalculationCheckboxMode.MULTIPLE,
  selectableRules,
  predefinedCalculations,
  expandableSubmerged = true,
  columnsExtension,
}: CalculationsTableProps<T>): React.ReactElement => {
  const renderingId = uuidv4();
  const startTime = new Date();
  const compact = variant === 'compact';

  debugLogRender(`${renderingId}: ${startTime.toISOString()} | START rendering`);

  const { projectShortName } = useParams();
  const projectCode = useSelectedProjectCode();

  const dispatch = useAppDispatch();
  const [deleteCalculation] = useDeleteCalculationMutation();
  const calculationFilters = useSelector(
    (state: ReduxState) => state.calculations.calculationFilters,
  );
  const expandedRowKeys = useSelector(
    (state: ReduxState) => state.calculations.expandedRowKeys[calculationsTableId],
  );
  const expandedSubmergedCalculations = useSelector(
    (state: ReduxState) => state.calculations.expandedSubmergedCalculations[calculationsTableId],
  );
  const hasSelectedCalculations = useSelector(
    (state: ReduxState) => state.calculations.hasSelectedRowKeys[calculationsTableId],
  );

  const expandedKeys = useMemo(
    () => [...new Set([...(expandedRowKeys ?? []), ...(expandedSubmergedCalculations ?? [])])],
    [expandedRowKeys, expandedSubmergedCalculations],
  );

  const user = useUser();
  const [isBusy, setIsBusy] = useState(true);

  useEffect(() => {
    //eslint-disable-next-line @ydistri/react/no-useless-setstate-in-effect -- params and other stuff need to change when projectCode changes
    setIsBusy(true);
    setCalculations([]);
    setParams(prevParams => {
      return {
        ...prevParams,
        skip: 0,
        top: CALCULATIONS_BLOCK_SIZE,
        projectCode: projectCode,
      };
    });
  }, [projectCode]);

  const currency = useCurrency();

  const [, loadCalculationToTemplateStatus] = useLoadCalculationToTemplateMutation({
    fixedCacheKey: 'loading-to-template',
  });

  const toggleExpandedRowHandler = useCallback(
    (rowKey: number) => {
      dispatch(toggleExpandedRowKey({ tableId: calculationsTableId, data: rowKey }));
    },
    [calculationsTableId, dispatch],
  );

  const toggleExpandedSubmergedCalculationsHandler = useCallback(
    (rowKey: number) => {
      dispatch(toggleExpandedSubmergedCalculations({ tableId: calculationsTableId, data: rowKey }));
    },
    [calculationsTableId, dispatch],
  );

  const deleteCalculationHandler = useCallback(
    (calculation: CalculationDataType) => {
      setCalculations(
        produce(draft => {
          const deletedCalculationIndex = draft.findIndex(
            tmpCalculation => tmpCalculation.data.id === calculation.id,
          );
          if (deletedCalculationIndex !== -1) {
            draft[deletedCalculationIndex].isBeingDeleted = true;
          }
        }),
      );
      debugLog(`Deleting calculation ${calculation.id}`);
      deleteCalculation(calculation.id)
        .unwrap()
        .then(() => {
          //remove the calculation from our data
          setCalculations(
            produce(draft => {
              const deletedCalculationIndex = draft.findIndex(
                tmpCalculation => tmpCalculation.data.id === calculation.id,
              );
              if (deletedCalculationIndex !== -1) {
                draft.splice(deletedCalculationIndex, 1);
              }
            }),
          );

          setParams(prevParams => {
            const loadItemsCount =
              (prevParams.top ?? CALCULATIONS_BLOCK_SIZE) + (prevParams.skip ?? 0);
            const newParams = {
              ...prevParams,
              skip: 0,
              top: loadItemsCount,
            };
            debugLog('Calculation deleted, setting new params', newParams);
            return newParams;
          });
          dispatch(addToast({ message: 'Calculation deleted' }));
        })
        .catch(() => {
          dispatch(addToast({ message: 'Calculation was not deleted', isError: true }));
        });
    },
    [deleteCalculation, dispatch],
  );

  const urlProvider = useCallback(
    (calculation: CalculationDataType) => {
      if (
        calculation.status === CalculationStatus.Completed ||
        (calculation.status === CalculationStatus.Pending &&
          calculation.mode === CalculationMode.Manual)
      ) {
        return generatePath(ROUTES.calculationDetail, {
          projectShortName: projectShortName ?? '',
          calculationId: `${calculation.id}`,
          subpage: CalculationDetailSubpages.REDISTRIBUTION,
          slug: '1',
        });
      }
    },
    [projectShortName],
  );

  const columns: YColumnsType<CalculationExtended<T>>[] = useMemo(() => {
    const cols: YColumnsType<CalculationExtended<T>>[] = [
      {
        title: 'ID',
        key: 'id',
        width: computeRemSize(90),
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        apiColumnName: 'Id',
        apiFilterable: true,
        apiFilterType: 'number',
        render: (calculation: CalculationExtended<T>) => (
          <LinkCell<CalculationDataType>
            record={calculation.data}
            urlProvider={urlProvider}
            active={linksEnabled}
            compact={compact}
          >
            <Row $gap="0.5rem" $alignItems="center">
              <CalculationTableText>{calculation.data.id}</CalculationTableText>
              {selectable ? (
                <CalculationCheckbox
                  calculationId={calculation.data.id}
                  calculationsTableId={calculationsTableId}
                  disabled={selectableRules ? selectableRules(calculation.data) : false}
                  compact={compact}
                  mode={selectableMode}
                />
              ) : null}
            </Row>
          </LinkCell>
        ),
      },
      {
        title: 'Title',
        key: 'title',
        dataIndex: ['data', 'title'],
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        apiColumnName: 'Title',
        apiFilterable: true,
        apiFilterType: 'text',
        shouldCellUpdate: (record, prevRecord) =>
          !shallowEqual(extractCellTitleParts(record), extractCellTitleParts(prevRecord)),
        render: (title: string, calculation: CalculationExtended<T>) => {
          return (
            <LinkCell<CalculationDataType>
              record={calculation.data}
              urlProvider={urlProvider}
              active={linksEnabled}
              compact={compact}
            >
              <CalculationRowTitle
                calculation={calculation.data}
                onDelete={deleteCalculationHandler}
                onToggleSubmergedCalculations={toggleExpandedSubmergedCalculationsHandler}
                onToggleStatistics={toggleExpandedRowHandler}
                disabled={calculation.isBeingDeleted}
                showActions={showActions}
                compact={compact}
                expandableSubmerged={expandableSubmerged}
              />
            </LinkCell>
          );
        },
      },
    ];

    if (displayFinalRedistribution) {
      cols.push({
        title: 'Final Redistribution',
        key: 'finalRedistribution',
        className: 'final-redistribution-cell',
        width: computeRemSize(displaySkuTypeValues ? 380 : 140),
        shouldCellUpdate: (record, prevRecord) =>
          !shallowEqual(extractCellTitleParts(record), extractCellTitleParts(prevRecord)),
        render: (calculation: CalculationExtended<T>) => {
          if (calculation.isBeingDeleted) {
            return (
              <CalculationStatusComponent
                status={CalculationStatus.SoftDeleted}
                mode={calculation.data.mode}
              />
            );
          }

          //for merged calculations, we need to check their submerged calculations for crashed or running state
          const merged = calculation.data.mode === CalculationMode.Merged;
          let hasFailedSubmergedCalculations = false;
          let hasRunningSubmergedCalculations = false;
          if (merged && 'subordinateCalculations' in calculation.data) {
            const submerged = calculation.data.subordinateCalculations ?? [];
            hasFailedSubmergedCalculations =
              submerged.find(s => s.status === CalculationStatus.Crashed) !== undefined;
            hasRunningSubmergedCalculations =
              submerged.find(s => s.status === CalculationStatus.Running) !== undefined;
          }

          let finalStatus = calculation.data.status;
          if (hasFailedSubmergedCalculations) {
            finalStatus = CalculationStatus.Crashed;
          } else if (hasRunningSubmergedCalculations) {
            finalStatus = CalculationStatus.Running;
          }

          if (finalStatus === CalculationStatus.Completed) {
            const redistributionValues = getRedistributionValues(
              calculation.data.rootCategoryRedistributions,
              calculation.data.type,
            );
            return (
              <LinkCell<CalculationDataType>
                record={calculation.data}
                urlProvider={urlProvider}
                active={linksEnabled}
                compact={compact}
              >
                <CalculationFinalRedistribution
                  production={redistributionValues.isProduction}
                  manualCalculation={calculation.data.mode === CalculationMode.Manual}
                  totalValue={redistributionValues.totalValue}
                  deadStockValue={redistributionValues.deadStockValue}
                  slowMoverValue={redistributionValues.slowMoverValue}
                  fastMoverValue={redistributionValues.fastMoverValue}
                  calculationId={calculation.data.id}
                  currency={currency}
                  displaySkuTypeValues={displaySkuTypeValues}
                />
              </LinkCell>
            );
          } else {
            return <CalculationStatusComponent status={finalStatus} mode={calculation.data.mode} />;
          }
        },
      });
    }

    if (!compact) {
      cols.push({
        title: 'Owner',
        key: 'owner',
        render: (calculation: CalculationExtended<T>) => (
          <LinkCell<CalculationDataType>
            record={calculation.data}
            urlProvider={urlProvider}
            active={linksEnabled}
            compact={compact}
          >
            <CalculationOwner
              fullName={
                'owner' in calculation.data ? (calculation.data.owner?.fullName ?? 'N/A') : 'N/A'
              }
              avatarOnly={compact}
            />
          </LinkCell>
        ),
        width: computeRemSize(200),
        shouldCellUpdate: () => false,
      });
    }

    if (displayCreationTime) {
      cols.push({
        title: 'Created',
        key: 'createdAt',
        width: '11rem',
        sorter: true,
        sortDirections: ['descend'],
        defaultSortOrder: 'descend',
        apiColumnName: 'Created',
        render: (calculation: CalculationExtended<T>) => (
          <LinkCell<CalculationDataType>
            record={calculation.data}
            urlProvider={urlProvider}
            active={linksEnabled}
            compact={compact}
          >
            <CalculationTableText>
              {format(new Date(calculation.data.created), 'd. M. yyyy HH:mm')}
            </CalculationTableText>
          </LinkCell>
        ),
        shouldCellUpdate: () => false,
      });
    }

    if (columnsExtension) {
      const newCols: YColumnsType<CalculationExtended<T>>[] = [];
      cols.forEach(c => {
        newCols.push(c);
        const addColsAfterThisKey = columnsExtension.find(ce => ce.afterKey === c.key);
        if (addColsAfterThisKey) {
          newCols.push(...addColsAfterThisKey.columns);
        }
      });
      return newCols;
    }

    return cols;
  }, [
    displayFinalRedistribution,
    compact,
    displayCreationTime,
    columnsExtension,
    urlProvider,
    linksEnabled,
    selectable,
    calculationsTableId,
    selectableRules,
    selectableMode,
    deleteCalculationHandler,
    toggleExpandedSubmergedCalculationsHandler,
    toggleExpandedRowHandler,
    showActions,
    expandableSubmerged,
    displaySkuTypeValues,
    currency,
  ]);

  const [params, setParams] = useState<CalculationTableParams>(() => {
    return {
      skip: 0,
      top: CALCULATIONS_BLOCK_SIZE,
      sortings: [
        {
          fieldName: 'Created',
          direction: SortDirection.Desc,
        },
      ],
      includePrivateCalculations: calculationFilters.showAllPrivateCalculations,
      productionCalculationsOnly: calculationFilters.showProductionOnly,
      calculationOwner: user?.id,
      showOwnersCalculationsOnly: calculationFilters.showCurrentUserCalculationsOnly,
      projectCode: projectCode,
    };
  });

  useEffect(() => {
    dispatch(setCalculationsTableParams(params));
  }, [dispatch, params]);

  useEffect(() => {
    //eslint-disable-next-line @ydistri/react/no-useless-setstate-in-effect -- params need to change when user or calculationFilters change
    setIsBusy(true);
    setParams(prevParams => {
      return {
        ...prevParams,
        skip: 0,
        top: CALCULATIONS_BLOCK_SIZE,
        includePrivateCalculations: calculationFilters.showAllPrivateCalculations,
        showOwnersCalculationsOnly: calculationFilters.showCurrentUserCalculationsOnly,
        productionCalculationsOnly: calculationFilters.showProductionOnly,
        calculationOwner: user?.id,
      };
    });
  }, [calculationFilters, user]);

  const ref = useRef<HTMLDivElement>(null);
  const [tableHeight, setTableHeight] = useState<number>(compact ? 325 : 500);

  const { data } = useGetCalculationsQuery(predefinedCalculations ? skipToken : params);

  const [calculations, setCalculations] = useState<CalculationExtended<T>[]>([]);

  const expandableConfig: ExpandableConfig<CalculationExtended<T>> = useMemo(
    () => ({
      expandedRowKeys: expandedKeys,
      expandedRowRender: record => (
        <CalculationExpandController
          calculationsTableId={calculationsTableId}
          calculationId={record.data.id}
          subordinateCalculations={
            'subordinateCalculations' in record.data
              ? record.data.subordinateCalculations
              : undefined
          }
          displaySkuTypeValues={displaySkuTypeValues}
        />
      ),
      showExpandColumn: false,
    }),
    [calculationsTableId, displaySkuTypeValues, expandedKeys],
  );

  useEffect(() => {
    setCalculations(
      (predefinedCalculations ?? data?.data ?? []).map(calculation => {
        return {
          data: calculation,
          isExpanded: (expandedKeys ?? []).includes(calculation.id),
          isBeingDeleted: false,
        } as CalculationExtended<T>;
      }),
    );
    if (predefinedCalculations ?? data?.data) {
      // eslint-disable-next-line @ydistri/react/no-useless-setstate-in-effect
      setIsBusy(false);
    }
  }, [predefinedCalculations, data, expandedKeys]);

  const resizeTable = useCallback(() => {
    const TABLE_OFFSET = 16;
    if (ref.current) {
      let tableHeaderSize = 37; //to compensate for the table's header as it is rendered outside the table's container
      const tableHeader = ref.current.querySelector('.ant-table-header');
      if (tableHeader) {
        tableHeaderSize = tableHeader.clientHeight;
      }

      const parentHeight = ref.current.clientHeight;
      const newHeight = parentHeight - (tableHeaderSize + TABLE_OFFSET);

      debugLog(
        'resizeTable: parentHeight: %d, newHeight: %d, headerSize: %d',
        parentHeight,
        newHeight,
        tableHeaderSize,
      );
      //initial height
      setTableHeight(newHeight);
    }
  }, [ref]);

  useEffect(() => {
    resizeTable();
    //compute height on window resize
    window.addEventListener('resize', resizeTable, { passive: true });
    return () => window.removeEventListener('resize', resizeTable);
  }, [resizeTable]);

  const getRowClassName = useCallback((calculation: CalculationExtended<T>) => {
    if (calculation.isExpanded) {
      return 'calculationRow expandedRow';
    } else if (calculation.data.type === CalculationType.Production) {
      return `calculationRow productionRow`;
    } else if (calculation.data.type === CalculationType.Private) {
      return `calculationRow privateRow`;
    } else if (calculation.data.status !== CalculationStatus.Completed) {
      return 'calculationRowIncomplete';
    }
    return '';
  }, []);

  const rowKeyProvider = useCallback(
    (calculation: CalculationExtended<T>) => calculation.data.id,
    [],
  );

  const result = (
    <Panel ref={ref} $grow={1} $noAutoHeight={true}>
      <Overlay
        active={isBusy || loadCalculationToTemplateStatus.isLoading}
        contentIsCentered={true}
        overlayContent={loadingOverlayContent}
      >
        {/** @ts-expect-error: styled components can't pass generics for some reason*/}
        <CalculationsTableStyled<T>
          id={calculationsTableId}
          columns={columns}
          height={autoHeight ? 'fit-content' : `${tableHeight}px`}
          minHeight={minHeight}
          setParams={setParams}
          dataSource={calculations}
          rowKey={rowKeyProvider}
          expandable={expandableConfig}
          size="middle"
          rowClassName={getRowClassName}
          totalRowCount={data?.totalCount ?? 0}
          showHeader={!compact}
          $hasSelectedCalculation={hasSelectedCalculations}
        />
      </Overlay>
    </Panel>
  );

  const endTime = new Date();
  debugLogRender(
    `${renderingId}: ${endTime.toISOString()} | END returning result from render, isBusy: ${isBusy}; took ${
      endTime.getTime() - startTime.getTime()
    } ms`,
  );
  return result;
};

export default CalculationsTable;
