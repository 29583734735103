import React, { useCallback, useMemo } from 'react';
import { MdOutlineImportExport } from 'react-icons/md';
import { Button, IconButton_IconSizes } from '@ydistri/ds';
import { useRunManualCalculationMutation } from './apiCalculations';
import { useTemplateOrCalculation } from '../../hooks/useTemplateOrCalculation';
import { CalculationStatus, CalculationType } from '@ydistri/api-sdk';
import { useGetCalculationQuery } from '../CalculationDetail/apiCalculationDetail';
import { useNavigate, useParams } from 'react-router';
import { RoutingPage } from '../../routes/routerSlice';
import { addToast } from '@ydistri/utils';
import { Popover } from 'antd';
import { useAppDispatch } from '../../store';

interface RecomputeStatisticsButtonProps {
  calculationStatus?: CalculationStatus;
  calculationType?: CalculationType;
  disabled?: boolean;
}

const importIcon = <MdOutlineImportExport size={IconButton_IconSizes.small} />;

const RecomputeStatisticsButton: React.FC<RecomputeStatisticsButtonProps> = ({
  calculationStatus,
  calculationType,
  disabled = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { projectShortName } = useParams();

  const templateOrCalculation = useTemplateOrCalculation();
  const { data: calculationData } = useGetCalculationQuery(templateOrCalculation.id);
  const [runManualCalculation, { isLoading: sendingRunCommand }] =
    useRunManualCalculationMutation();

  const onClickHandler = useCallback(() => {
    if (templateOrCalculation.type === 'Calculation') {
      const createErrorToast = () =>
        dispatch(
          addToast({
            message: `There was an error when trying to recompute statistics`,
            isError: true,
          }),
        );

      runManualCalculation(templateOrCalculation.id)
        .unwrap()
        .then(() => {
          dispatch(addToast({ message: 'Statistics recomputation will start shortly' }));
          navigate(`/${projectShortName}/${RoutingPage.CALCULATIONS}`);
        })
        .catch(() => {
          createErrorToast();
        });
    }
  }, [
    dispatch,
    navigate,
    projectShortName,
    runManualCalculation,
    templateOrCalculation.id,
    templateOrCalculation.type,
  ]);

  if (calculationStatus === CalculationStatus.Running) {
    disabled = true;
  }

  const loading = sendingRunCommand || calculationData?.status === CalculationStatus.Running;
  const isDisabled = loading || disabled || calculationType === CalculationType.Production;

  const button = useMemo(
    () => (
      <Button
        $colorVariant={isDisabled ? 'ghost' : 'primary'}
        $textVariant="semibold"
        icon={importIcon}
        type="primary"
        onClick={onClickHandler}
        loading={loading}
        disabled={isDisabled}
      >
        Recompute statistics
      </Button>
    ),
    [isDisabled, loading, onClickHandler],
  );

  if (calculationType === CalculationType.Production) {
    return (
      <Popover content="Production calculations can't be recomputed" placement="left">
        {button}
      </Popover>
    );
  }

  return button;
};

export default RecomputeStatisticsButton;
