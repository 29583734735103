import React, { useMemo, useState } from 'react';
import { InfoIconTooltip } from '../../icons/InfoIconTooltip';
import { Column, Row, Text } from '@ydistri/ds';
import {
  CalculationCategoryPairingRequest,
  useGetCalculationCategoryPairingsQuery,
} from '../../../screens/Calculations/apiCalculationsCategories';
import { LogicalOperator, Operation } from '@ydistri/api-sdk';
import { SkuRedistributionPaneType } from '../../../screens/CalculationDetail/Redistribution/redistributionTypes';

interface SkuAvailabilityRendererProps {
  sourceOrTarget: SkuRedistributionPaneType;
  available: boolean;
  manual?: boolean;
  calculationId: number;
  skuId: number;
}

const SkuAvailabilityRenderer: React.FC<SkuAvailabilityRendererProps> = ({
  sourceOrTarget,
  available,
  manual = false,
  calculationId,
  skuId,
}) => {
  const [params] = useState<CalculationCategoryPairingRequest>({
    calculationId,
    categoryId: 1,
    skip: 0,
    top: 100,
    conditions: [
      {
        fieldName: `${sourceOrTarget}SkuId`,
        operation: Operation.Eq,
        value: skuId,
        logicalOperator: LogicalOperator.Or,
      },
    ],
  });

  const { data: redistributionData } = useGetCalculationCategoryPairingsQuery(params);

  const hasPickingPositions = (redistributionData?.data ?? []).length > 0;

  const content = useMemo(() => {
    if (manual) {
      return <Text>Manual calculations do not have configurations.</Text>;
    }
    if (!hasPickingPositions) {
      if (available) {
        return (
          <Column $gap="0.5rem">
            <Text $type="light">
              This SKU is in the scope and can have {sourceOrTarget.toLowerCase()} potential.
            </Text>
            <Text $type="light">
              This does <Text $bold>not</Text> mean it is in the redistribution.
            </Text>
          </Column>
        );
      } else {
        return (
          <Text>
            {sourceOrTarget} SKU of this picking line is not available as{' '}
            {sourceOrTarget.toLowerCase()} in this calculation
          </Text>
        );
      }
    }

    if (!available) {
      return (
        <Text $type="light">
          This SKU is probably out of scope and can not have {sourceOrTarget.toLowerCase()}{' '}
          potential.
        </Text>
      );
    }

    return (
      <Text $type="light">
        This SKU is in the scope and has {sourceOrTarget.toLowerCase()} potential.
      </Text>
    );
  }, [manual, hasPickingPositions, available, sourceOrTarget]);

  return (
    <Row $alignItems="center" $gap="0.25rem">
      <InfoIconTooltip content={content} />
      {manual ? (
        <Text>Manual</Text>
      ) : (
        <Text $type={available ? 'success' : 'danger'}>
          <Text>{available ? 'Available' : 'Not available'}</Text>
        </Text>
      )}
    </Row>
  );
};

export default SkuAvailabilityRenderer;
