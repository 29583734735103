import React, { CSSProperties, useMemo } from 'react';

import { Column } from '@ydistri/ds';
import { styled } from 'styled-components';
import { TooltipContentSection, TooltipPosition, TooltipType } from './saleChartTooltipTypes';
import TooltipTitle from './TooltipTitle';
import TooltipContent from './TooltipContent';
import { ContentTable } from './TooltipContentHelperComponents';

const SalesChartTooltipWrapper = styled.div`
  transition: 0.5s all cubic-bezier(0.17, 1.09, 0.78, 0.87);
  background-color: white;
  box-shadow: 0 calc(2rem / ${({ theme }) => theme.rootFontSize})
    calc(50rem / ${({ theme }) => theme.rootFontSize}) #888;
  padding: 0.8rem;
  text-align: center;
  font-size: calc(12rem / ${({ theme }) => theme.rootFontSize});
  position: fixed;
  pointer-events: none;
  z-index: 999;
`;

export interface SalesChartTooltipProps {
  rootId: string;
  position: TooltipPosition;
  data: TooltipContentSection[];
  dateFrom: Date;
  currentDate?: Date;
  dateTo?: Date;
  tooltipType?: TooltipType;
  wide?: boolean;
}

/**
 * Tooltip renders the tooltip for the sales chart. It does not make any choices
 * just renders the data it receives on a position it receives.
 * The data is a table with given headers and data. Table data are rows in table
 * with label, line information and primary and secondary value.
 * It is up to the caller to decide what data should go to each of the columns.
 * @param wrapperId ID of the wrapping component
 * @param position Where to render the tooltip if at all (visible property)
 * @param data data to render as table
 * @param currentDate Date in the application
 * @param dateFrom
 * @param dateTo
 * @constructor
 */
const SalesChartTooltip: React.FC<SalesChartTooltipProps> = ({
  rootId,
  position,
  data,
  currentDate,
  dateFrom,
  dateTo,
  wide,
  tooltipType = 'date-range',
}) => {
  const tooltipStyle = useMemo((): CSSProperties => {
    return {
      opacity: position.visible ? '1' : '0',
      top: position.top,
      left: position.left,
      right: position.right,
      minWidth: wide ? '28rem' : '22rem',
    };
  }, [position.left, position.right, position.top, position.visible, wide]);

  return (
    <SalesChartTooltipWrapper style={tooltipStyle} id={rootId}>
      <Column $gap="0.75rem">
        <TooltipTitle
          type={tooltipType}
          currentDate={currentDate}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
        <ContentTable>
          {data.map(dataItem => {
            return (
              <TooltipContent
                key={dataItem.title}
                title={dataItem.title}
                columnNames={dataItem.columnNames}
                data={dataItem.data}
              />
            );
          })}
        </ContentTable>
      </Column>
    </SalesChartTooltipWrapper>
  );
};

export default SalesChartTooltip;
