import React, { useMemo } from 'react';
import OverlayMessage, { OverlayCoverDiv, OverlayMessageIcon } from './OverlayComponents';
import { useTemplateStatus } from '../../../hooks/useTemplateStatus';
import { CalculationStatus } from '@ydistri/api-sdk';

/**
 * Overlay that is displayed when template is queued, running or deleted (should not happen)
 * Must be placed inside div with "position: relative"
 * @constructor
 */
const TemplateStatusOverlay: React.FC = () => {
  const { isRunning: isActive, niceName, status } = useTemplateStatus();

  const icon: OverlayMessageIcon | undefined = useMemo(() => {
    switch (status) {
      case CalculationStatus.Pending:
      case CalculationStatus.Queued:
      case CalculationStatus.ToBeQueued:
        return OverlayMessageIcon.PENDING;
      case CalculationStatus.Running:
        return OverlayMessageIcon.RUNNING;
      case CalculationStatus.SoftDeleted:
        return OverlayMessageIcon.LOCKED;
      case CalculationStatus.Completed:
      case CalculationStatus.Crashed:
      case CalculationStatus.Outdated:
        return undefined;
    }
  }, [status]);

  const message: string | undefined = useMemo(() => {
    switch (status) {
      case CalculationStatus.Pending:
      case CalculationStatus.Queued:
      case CalculationStatus.ToBeQueued:
        return 'Computation will start soon';
      case CalculationStatus.Running:
        return 'Computation is running';
      case CalculationStatus.SoftDeleted:
        return 'This template is deleted, please select another one';
      case CalculationStatus.Completed:
      case CalculationStatus.Crashed:
      case CalculationStatus.Outdated:
        return undefined;
    }
  }, [status]);

  return isActive ? (
    <OverlayCoverDiv $topOffset={-45}>
      <OverlayMessage icon={icon} title={niceName} content={message} />
    </OverlayCoverDiv>
  ) : null;
};

export default TemplateStatusOverlay;
