import {
  CalculationDetailResponse,
  CalculationMode,
  SkuCalculationConfigurationResponse,
} from '@ydistri/api-sdk';
import React from 'react';
import {
  CalculationExtended,
  ColumnsExtension,
} from '../../../../../../Calculations/components/table/CalculationsTable';
import { CalculationDataType } from '../../../../../../Calculations/calculationsTypes';
import SkuAvailabilityRenderer from '../../../../../../../components/global/CalculationTableExtensions/SkuAvailabilityRenderer';
import { SkuRedistributionPaneType } from '../../../../redistributionTypes';
import SkuPickingLineRenderer from '../../../../../../../components/global/CalculationTableExtensions/SkuPickingLineRenderer';
import SkuOpenRenderer from '../../../../../../../components/global/CalculationTableExtensions/SkuOpenRenderer';

export interface SubcalculationExtension {
  available: boolean;
}
export type SubcalculationSelectorCalculationData = CalculationDataType<SubcalculationExtension>;

export const SUBCALCULATION_SOURCE_SELECTOR_TABLE_ID = 'subcalculation-source-selector';
export const SUBCALCULATION_TARGET_SELECTOR_TABLE_ID = 'subcalculation-target-selector';

export const mergeCalculationWithSubordinateCalculations = (
  calculation: CalculationDetailResponse | undefined,
  skuConfiguration: SkuCalculationConfigurationResponse | undefined = undefined,
): SubcalculationSelectorCalculationData[] =>
  calculation
    ? [
        {
          ...calculation,
          title: `Resulting merged calculation - ${calculation.title}`,
          available: true,
        },
        ...(calculation.subordinateCalculations ?? []).map(c => ({
          ...c,
          available: !!skuConfiguration?.subordinateCalculationSkus?.find(
            s => s.calculation.id === c.id,
          ),
        })),
      ]
    : [];

export const getSubordinateCalculationsTableColumnExtension = (
  skuId: number,
  sourceOrTarget: SkuRedistributionPaneType,
): ColumnsExtension<SubcalculationExtension>[] => [
  {
    afterKey: 'title',
    columns: [
      {
        title: 'Available',
        key: 'available',
        dataIndex: ['data', 'available'],
        width: '8rem',
        render: (
          available: boolean,
          record: CalculationExtended<SubcalculationExtension>,
        ): React.ReactNode => {
          return (
            <SkuAvailabilityRenderer
              available={available}
              sourceOrTarget={sourceOrTarget}
              manual={record.data.mode === CalculationMode.Manual}
              calculationId={record.data.id}
              skuId={skuId}
            />
          );
        },
      },
      {
        title: 'PickingLines',
        key: 'available',
        dataIndex: ['data', 'available'],
        width: '7.5rem',
        render: (
          available: boolean,
          record: CalculationExtended<SubcalculationExtension>,
        ): React.ReactNode => {
          return (
            <SkuPickingLineRenderer
              available={available}
              sourceOrTarget={sourceOrTarget}
              manual={record.data.mode === CalculationMode.Manual}
              calculationId={record.data.id}
              skuId={skuId}
            />
          );
        },
      },
      {
        title: 'OpenLink',
        key: 'available',
        dataIndex: ['data', 'available'],
        width: '3rem',
        render: (
          _: boolean,
          record: CalculationExtended<SubcalculationExtension>,
        ): React.ReactNode => {
          return (
            <SkuOpenRenderer
              sourceOrTarget={sourceOrTarget}
              calculationId={record.data.id}
              skuId={skuId}
            />
          );
        },
      },
    ],
  },
];
