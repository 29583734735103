import React from 'react';
import { ActiveScreenType } from '../../common/administrationItemsLib';
import ProductListItemsSection from './detail/ProductListItemsSection';
import ProductListEditModeSection from './edit/ProductListEditModeSection';
import ProductListValidationSection from './validation/ProductListValidationSection';
import NoProductListSelected from '../components/NoProductListSelected';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../store';

const ProductListAdministrationContent: React.FC = () => {
  const activeScreenType = useSelector(
    (state: ReduxState) => state.productListsAdministration.activeScreenType,
  );

  switch (activeScreenType) {
    case ActiveScreenType.NO_SELECTION:
      return <NoProductListSelected />;
    case ActiveScreenType.DETAIL:
      return <ProductListItemsSection />;
    case ActiveScreenType.EDIT:
      return <ProductListEditModeSection />;
    case ActiveScreenType.VALIDATION:
      return <ProductListValidationSection />;
    case ActiveScreenType.DONE:
      return null;
  }
};

export default ProductListAdministrationContent;
