import React, { useCallback } from 'react';
import { AvailableModals, openModal } from '../../Modals/modalSlice';
import { BsSliders } from 'react-icons/bs';
import { Button } from '@ydistri/ds';
import { useAppDispatch } from '../../../store';

const ConfigurationExceptions: React.FC = () => {
  const dispatch = useAppDispatch();

  const openCategoryExceptionsModal = useCallback(() => {
    dispatch(openModal({ type: AvailableModals.CATEGORY_EXCEPTIONS, data: undefined }));
  }, [dispatch]);

  return (
    <Button
      loading={false}
      $colorVariant="ghostPrimary"
      suffix={null}
      onClick={openCategoryExceptionsModal}
      icon={<BsSliders />}
    >
      Exception overview
    </Button>
  );
};

export default ConfigurationExceptions;
