import { Link } from 'react-router';
import { IconButton_IconSizes } from '@ydistri/ds';
import React from 'react';
import { MdOpenInNew } from 'react-icons/md';
import { styled } from 'styled-components';

interface LinkButtonProps {
  url: string;
}

const icon = <MdOpenInNew size={IconButton_IconSizes.xSmall} />;

const IconLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.dark};
`;

/**
 * Link with a standard icon
 * @param url
 * @param tooltip
 * @constructor
 */
const LinkIcon: React.FC<LinkButtonProps> = ({ url }) => {
  return <IconLink to={url}>{icon}</IconLink>;
};

export default LinkIcon;
