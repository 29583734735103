import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { setSubpage } from '../../../routes/routerSlice';
import { ConfigurationSubpage } from '../Configuration';
import { useSelector } from 'react-redux';
import PlaygroundDefinition from './PlaygroundDefinition';
import { ReduxState, store, useAppDispatch } from '../../../store';
import ExceptionDefinition from './ExceptionDefinition';
import { createEmptyScope, ScopeModalType } from './scopeLib';
import { Reorder } from 'framer-motion';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import {
  useDeleteConfigurationRulesMutation,
  useGetConfigurationRulesQuery,
  usePatchConfigurationRuleMutation,
} from './apiScopes';
import { createDebugLog } from '../../../lib/utils/logging';
import {
  setDraggingInfo,
  setModalChanged,
  setModalScope,
  setModalType,
  toggleRearrangeMode,
} from './scopeConfigurationSlice';
import {
  ActionsBar,
  Button,
  GenericConfirmationModal,
  IconButton_IconSizes,
  Section,
  Text,
} from '@ydistri/ds';
import { AvailableModals, openModal } from '../../Modals/modalSlice';
import { MdAdd, MdDelete } from 'react-icons/md';
import { TbArrowsMoveVertical } from 'react-icons/tb';
import LoadingIndicator from '../../../components/global/LoadingIndicator/LoadingIndicator';
import { useConfigurationRuleOverlap } from './useConfigurationRuleOverlap';
import { Empty } from 'antd';

const debugLog = createDebugLog('Scopes', 'ConfigurationPlayground');

const ConfigurationStoresAndDepartments: React.FC = () => {
  const dispatch = useAppDispatch();

  const isLoadingRuleOverlap = useConfigurationRuleOverlap();
  const templateOrCalculation = useTemplateOrCalculation();

  const { data: scopes } = useGetConfigurationRulesQuery(templateOrCalculation);

  const [patchConfigurationRuleMutation] = usePatchConfigurationRuleMutation();
  const [deleteConfigurationRulesMutation] = useDeleteConfigurationRulesMutation();

  const exceptions = useMemo(
    () =>
      (scopes ?? [])
        .filter(scope => scope.priority > 0)
        .sort((a, b) => a.priority - b.priority)
        .map(scope => scope.id),
    [scopes],
  );

  const rearrangeMode = useSelector((state: ReduxState) => state.scope.rearrangeMode);
  const draggingFinished = useSelector(
    (state: ReduxState) => state.scope.draggingInfo?.finished ?? false,
  );

  const [showConfirmation, setShowConfirmation] = useState(false);
  const showConfirmationModal = useCallback(() => setShowConfirmation(true), []);
  const hideConfirmationModal = useCallback(() => setShowConfirmation(false), []);

  const deleteExceptionsHandler = useCallback(() => {
    deleteConfigurationRulesMutation({
      templateId: templateOrCalculation.id,
    });
    hideConfirmationModal();
  }, [deleteConfigurationRulesMutation, hideConfirmationModal, templateOrCalculation.id]);

  const [items, setItems] = useState(exceptions);

  useEffect(() => {
    debugLog('ConfigurationStores - ', exceptions);
    setItems(exceptions);
  }, [rearrangeMode, exceptions]);

  const onReorder = useCallback(
    (x: number[]) => {
      const draggingInfo = store.getState().scope.draggingInfo;
      debugLog("onReorder - Draggin' info", draggingInfo);
      if (rearrangeMode && draggingInfo?.scopeId) {
        setItems(x);
        const endingPriority = x.indexOf(draggingInfo.scopeId) + 1;
        if (endingPriority !== draggingInfo.startingPriority) {
          dispatch(setDraggingInfo({ endingPriority }));
        }
      }
    },
    [dispatch, rearrangeMode],
  );

  useEffect(() => {
    if (draggingFinished) {
      const draggingInfo = store.getState().scope.draggingInfo;
      debugLog("draggingFinished - Draggin' info", draggingInfo);

      if (draggingInfo) {
        const { scopeId: id, endingPriority: priority } = draggingInfo;
        if (id) {
          patchConfigurationRuleMutation({
            templateId: templateOrCalculation.id,
            id,
            data: {
              priority,
            },
          });
          dispatch(setDraggingInfo(undefined));
        }
      }
    }
  }, [dispatch, draggingFinished, patchConfigurationRuleMutation, templateOrCalculation.id]);

  useEffect(() => {
    dispatch(setSubpage(ConfigurationSubpage.STORES));
  }, [dispatch]);

  const exceptionCount = exceptions.length;

  const openCreateExceptionModalHandler = useCallback(() => {
    const newExceptionScope = createEmptyScope(-1, undefined, exceptionCount + 1, true);
    dispatch(setModalType(ScopeModalType.CREATE));
    dispatch(setModalChanged(false));
    dispatch(setModalScope(newExceptionScope));
    dispatch(
      openModal({
        type: AvailableModals.SCOPE_EDIT,
        data: {
          scopeId: -1,
        },
      }),
    );
  }, [dispatch, exceptionCount]);

  const toggleRearrangeModeHandler = useCallback(() => dispatch(toggleRearrangeMode()), [dispatch]);

  const hasException = items.length > 0;

  const headerActions = useMemo(() => {
    if (templateOrCalculation.type === 'Calculation') return null;

    return (
      <ActionsBar moveToRight={true}>
        <Button
          $colorVariant="ghost"
          onClick={openCreateExceptionModalHandler}
          data-testid="create_new_exception"
        >
          <MdAdd size={IconButton_IconSizes.small} />
          Create
        </Button>
        <Button
          $colorVariant={rearrangeMode ? 'selected' : 'ghost'}
          onClick={toggleRearrangeModeHandler}
          data-testid="rearrange_exceptions"
        >
          <TbArrowsMoveVertical size={IconButton_IconSizes.small} />
          Rearrange
        </Button>
        {hasException && (
          <Button
            $colorVariant="ghostDanger"
            onClick={showConfirmationModal}
            data-testid="delete_all_exceptions"
          >
            <MdDelete size={IconButton_IconSizes.small} />
            Delete all
          </Button>
        )}
      </ActionsBar>
    );
  }, [
    templateOrCalculation.type,
    openCreateExceptionModalHandler,
    rearrangeMode,
    toggleRearrangeModeHandler,
    hasException,
    showConfirmationModal,
  ]);

  return (
    <>
      <PlaygroundDefinition />
      <Section
        $scrollableContent={true}
        header="Exceptions"
        $ratio={1}
        $basis={50}
        headerActions={headerActions}
      >
        <Reorder.Group as="div" axis="y" values={items} onReorder={onReorder}>
          {items.map(exceptionScopeId => (
            <ExceptionDefinition key={exceptionScopeId} scopeId={exceptionScopeId} />
          ))}
        </Reorder.Group>
        {isLoadingRuleOverlap && <LoadingIndicator text="Loading exception overlap data..." />}
        {!isLoadingRuleOverlap && items.length === 0 && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No exceptions" />
        )}
        <Text $center>
          Exceptions on the bottom have the highest priority and will partially overwrite settings
          above them in case their scope overlaps
        </Text>
      </Section>
      {showConfirmation && (
        <GenericConfirmationModal
          title="Delete all exception"
          message="You are about to delete all exceptions"
          confirmationButtonLabel="Delete all exceptions"
          onConfirmed={deleteExceptionsHandler}
          onCanceled={hideConfirmationModal}
        />
      )}
    </>
  );
};

export default ConfigurationStoresAndDepartments;
