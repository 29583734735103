import { useSelector } from 'react-redux';
import { getSectionTitle } from '../../productListsLib';
import React, { useCallback, useEffect } from 'react';
import { ActiveScreenType } from '../../../common/administrationItemsLib';
import {
  clearValidation,
  setActiveScreenType,
  setProductItemsContent,
  setProductItemsError,
} from '../../productListAdministrationSlice';
import ValidationSection from '../../../common/ValidationSection';
import ProductListValidationResults from './ProductListValidationResults';
import { ReduxState, useAppDispatch } from '../../../../../store';

const ProductListValidationSection: React.FC = () => {
  const selectedProductList = useSelector(
    (state: ReduxState) => state.productListsAdministration.selectedProductList,
  );
  const productItemsErrors = useSelector(
    (state: ReduxState) => state.productListsAdministration.productItemsErrors,
  );

  const dispatch = useAppDispatch();

  /**
   * Content of the text area is set to error data if any
   */
  useEffect(() => {
    if (productItemsErrors.length > 0) {
      dispatch(setProductItemsContent(productItemsErrors));
      dispatch(setProductItemsError(''));
    }
  }, [dispatch, productItemsErrors]);

  const onExitEditMode = useCallback(() => {
    dispatch(clearValidation());
    dispatch(setActiveScreenType(ActiveScreenType.DETAIL));
  }, [dispatch]);

  if (selectedProductList) {
    return (
      <ValidationSection title={getSectionTitle(selectedProductList)} onClose={onExitEditMode}>
        <ProductListValidationResults />
      </ValidationSection>
    );
  } else {
    return null;
  }
};

export default ProductListValidationSection;
