import { ActionsBar, Column, Row } from '@ydistri/ds';
import IdentificationTypeComponent from '../../../common/IdentificationTypeComponent';
import React, { useCallback } from 'react';
import { IdentificationType } from '@ydistri/api-sdk';
import { useSelector } from 'react-redux';
import { setProductIdentificationType } from '../../productListAdministrationSlice';
import { Button } from 'antd';

import { AdministrationItemListHandlingMethod } from '../../../common/administrationItemsTypes';
import LoadingIndicator from '../../../../../components/global/LoadingIndicator/LoadingIndicator';
import ProductListItemsTextArea from './ProductListItemsTextArea';
import useValidateItems from '../../useValidateItems';
import { ReduxState, useAppDispatch } from '../../../../../store';

const ProductListEditor: React.FC = () => {
  const selectedProductList = useSelector(
    (state: ReduxState) => state.productListsAdministration.selectedProductList,
  );
  const productIdentificationType = useSelector(
    (state: ReduxState) => state.productListsAdministration.productIdentificationType,
  );
  const productItemsContent = useSelector(
    (state: ReduxState) => state.productListsAdministration.productItemsContent,
  );
  const isLoading = useSelector((state: ReduxState) => state.productListsAdministration.isLoading);

  const dispatch = useAppDispatch();
  const validateItems = useValidateItems();

  const selectProductIdentificationHandler = useCallback(
    (newValue: IdentificationType) => {
      dispatch(setProductIdentificationType(newValue));
    },
    [dispatch],
  );

  const addItems = useCallback(() => {
    validateItems(AdministrationItemListHandlingMethod.ADD);
  }, [validateItems]);
  const replaceItems = useCallback(() => {
    validateItems(AdministrationItemListHandlingMethod.REPLACE);
  }, [validateItems]);

  if (!selectedProductList) {
    return null;
  }
  if (isLoading) {
    return (
      <Column $gap="0.5rem" $minWidth="45rem">
        <LoadingIndicator text="Validating..." />
      </Column>
    );
  } else {
    return (
      <Column $gap="1rem">
        <Row>
          <IdentificationTypeComponent
            type="Product"
            value={productIdentificationType}
            onChange={selectProductIdentificationHandler}
            id="ProductIdentificationTypeSelect"
          />
        </Row>
        <Row $gap="1rem">
          <Column $gap="0.5rem" $minWidth="45rem">
            <ProductListItemsTextArea label="Products" />

            <ActionsBar data-testid="ProductListEditorActions">
              <Button
                disabled={productItemsContent.length === 0}
                data-testid="add-to-product-list"
                type="primary"
                onClick={addItems}
              >
                Add to Product List...
              </Button>
              <Button
                disabled={productItemsContent.length === 0}
                data-testid="replace-product-list"
                type="primary"
                onClick={replaceItems}
              >
                Replace Product List...
              </Button>
            </ActionsBar>
          </Column>
        </Row>
      </Column>
    );
  }
};

export default ProductListEditor;
