import React, { useEffect, useMemo } from 'react';
import { Section, SectionProps } from '@ydistri/ds';
import CalculationRedistributionBody from './CalculationRedistributionBody';
import CalculationRedistributionActions from './CalculationRedistributionActions';
import { useGetCalculationQuery } from '../../../../apiCalculationDetail';
import useCategoryId from '../../../../../../hooks/useCategoryId';
import { useTemplateOrCalculation } from '../../../../../../hooks/useTemplateOrCalculation';
import {
  resetFilters,
  searchRedistribution,
  selectSKURedistribution,
  setRedistributionPairingCount,
} from '../../../../../Calculations/calculationsSlice';
import { NO_VALUE } from '../../../../../../lib/utils/utilsTypes';
import { useAppDispatch } from '../../../../../../store';

const CalculationRedistributionSection: React.FC<SectionProps> = props => {
  const calculationData = useTemplateOrCalculation();
  const { data: calculation } = useGetCalculationQuery(calculationData.id);
  const selectedCategoryId = useCategoryId();
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
      dispatch(setRedistributionPairingCount(NO_VALUE));
      dispatch(searchRedistribution('')); //using undefined does not trigger the reset in components
      dispatch(selectSKURedistribution(undefined));
    };
  }, [dispatch]);

  const headerActions = useMemo(() => {
    if (calculation) {
      return <CalculationRedistributionActions calculation={calculation} />;
    }
  }, [calculation]);

  return (
    <Section
      header="Redistribution"
      headerActions={headerActions}
      data-id="RedistributionSection"
      $contentMarginTop={false}
      {...props}
    >
      {calculation && (
        <CalculationRedistributionBody
          calculationId={calculation.id}
          categoryId={selectedCategoryId}
        />
      )}
    </Section>
  );
};

export default CalculationRedistributionSection;
