import React from 'react';
import { Column, Section } from '@ydistri/ds';
import { useGetCalculationsAsSourceQuery, useGetCalculationsAsTargetQuery } from '../../apiDetail';
import SkuCalculations from './SkuCalculations';
import { styled } from 'styled-components';

interface CalculationsSectionProps {
  skuId: number;
}

const ColumnWithMargin = styled(Column)`
  margin-bottom: 12pt;
`;

/**
 * Section that displays calculations for the SKU where sku is as a source and as a target
 * @param skuId
 * @constructor
 */
const CalculationsSection: React.FC<CalculationsSectionProps> = ({ skuId }) => {
  const { data: calculationsAsSource, isFetching: sourceIsFetching } =
    useGetCalculationsAsSourceQuery(skuId);

  const { data: calculationsAsTarget, isFetching: targetIsFetching } =
    useGetCalculationsAsTargetQuery(skuId);

  return (
    <Section header="Calculations">
      <ColumnWithMargin $gap="32pt">
        <SkuCalculations
          id="SkuAsSourceCalculations"
          skuId={skuId}
          sourceOrTarget="Source"
          calculations={calculationsAsSource}
          title="With SKU as source:"
          fetching={sourceIsFetching}
        />
        <SkuCalculations
          id="SkuAsTargetCalculations"
          skuId={skuId}
          sourceOrTarget="Target"
          calculations={calculationsAsTarget}
          title="With SKU as target:"
          fetching={targetIsFetching}
        />
      </ColumnWithMargin>
    </Section>
  );
};

export default CalculationsSection;
