import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RoutingPage, setPage } from '../../../../routes/routerSlice';
import {
  adjustPointSizeInLines,
  changeFillInLineTypesBasedOnHiddenLines,
  createChartDataFromDatasetsAndParsedMonthlyTransactions,
  createDatasetsFromLineTypesAndGraphLines,
  getChartPointSizeBasedOnDisplayedDays,
  hideNullLineTypes,
  inboundOutboundTypesDefault,
  mergeDailyInventoryMovements,
  NonSaleDataByDay,
  parseTransactions,
  ParseTransactionsInput,
  saleTypesDefault,
  setupChartLines,
  supplyLineDefault,
} from '../../../../lib/charts/saleChartsLib';
import {
  createInitialDateRangeData,
  DateRangeData,
  scaleTicksCallback,
} from './dailyTransactionsChartLib';

import {
  Chart,
  Chart as ChartJS,
  ChartDataset,
  ChartOptions,
  TimeUnit,
  TooltipModel,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import dayjs from 'dayjs';
import {
  useGetDailyInboundsQuery,
  useGetDailyOutboundsQuery,
  useGetDailySalesQuery,
} from '../../apiDetail';
import SkuDailyTransactionChartSlider from './SkuDailyTransactionChartSlider';
import { useCurrency } from '../../../../hooks/useCurrency';
import { chartScrollZoomHandler } from './chartScrollZoomHandler';
import { ChartDatasetMetaExtension } from '@shared-types/chartTypes';
import { Column } from '@ydistri/ds';
import {
  LINE_TYPES,
  lineTypeTooltipConfig,
  SkuSaleGraphValueColumnType,
  TLineType,
  TooltipCallback,
  TParsedTransactions,
  Units,
} from '../../../../lib/charts/saleChartsTypes';
import SaleChartTooltip from '../../../../components/global/SalesChartTooltip/SaleChartTooltip';
import {
  TooltipContentSection,
  TooltipPosition,
} from '../../../../components/global/SalesChartTooltip/saleChartTooltipTypes';
import { computeTooltipPosition } from '../../../../lib/charts/saleChartsLibUI';
import isEqual from 'lodash/isEqual';
import { SkuSaleChartTooltipGenerator } from '../../../CalculationDetail/Redistribution/parts/listing/components/SkuSaleChart/SkuSaleChartLib';
import { useAppDispatch } from '../../../../store';

const currentDate = new Date();
const tooltipId = 'SkuDailyTransactionsChartTooltip';

interface SkuDailyTransactionsChartProps {
  skuId: number;
}

const SkuDailyTransactionsChart: React.FC<SkuDailyTransactionsChartProps> = ({ skuId }) => {
  const apiParams = useMemo(
    () => ({
      skuId,
      monthsCount: 36,
    }),
    [skuId],
  );

  const { data: dailySales, isFetching: isFetchingDailySales } = useGetDailySalesQuery(apiParams);
  const { data: dailyInbounds, isFetching: isFetchingDailyInbounds } =
    useGetDailyInboundsQuery(apiParams);
  const { data: dailyOutbounds, isFetching: isFetchingDailyOutbounds } =
    useGetDailyOutboundsQuery(apiParams);

  const fetching = isFetchingDailySales || isFetchingDailyInbounds || isFetchingDailyOutbounds;

  const dispatch = useAppDispatch();
  const currency = useCurrency();
  const chartRef = useRef<ChartJS<'line'>>(null);
  const [dateRangeData, setDateRangeData] = useState<DateRangeData>(createInitialDateRangeData());

  const [tooltipPosition, setTooltipPosition] = useState<TooltipPosition>({
    visible: false,
    left: 0,
    top: 0,
    right: 0,
  });
  const [tooltipSalesData, setTooltipSalesData] = useState<TParsedTransactions>();
  const [tooltipData, setTooltipData] = useState<TooltipContentSection[]>([]);

  useEffect(() => {
    dispatch(setPage(RoutingPage.CHART));
  }, [dispatch]);

  const chartDateMin = dateRangeData.chart.min;
  const chartDateMax = dateRangeData.chart.max;
  const chartPointRadius = getChartPointSizeBasedOnDisplayedDays(
    chartDateMax.diff(chartDateMin, 'day'),
  );

  const data = useMemo(() => {
    if (fetching || !dailySales || !dailyInbounds || !dailyOutbounds)
      return {
        lineTypes: [],
        p: [],
        chartLines: {},
        lineTypesAfterHiding: [],
        datasets: [],
        nonSaleDataByDay: {},
        chartdata: { datasets: [] },
      };
    let lineTypes = [...saleTypesDefault].concat([...inboundOutboundTypesDefault]);

    const { summedData: mergedTransactions, nonSaleDataByDay } = mergeDailyInventoryMovements(
      dailySales,
      dailyInbounds,
      dailyOutbounds,
    );

    const p = parseTransactions({
      type: 'daily',
      transactions: mergedTransactions,
      lineTypes,
      dateToStartCalculatingCumulativeValues: new Date(),
    });

    if (p.length > 0) {
      setDateRangeData(prev => ({
        chart: prev.chart.min.isSame(dayjs(), 'day')
          ? {
              min: dayjs(p[p.length > 365 ? 365 : p.length - 1].dates.dateFrom),
              max: dayjs(p[0].dates.dateFrom),
            }
          : prev.chart,
        data: {
          min: dayjs(p[p.length - 1].dates.dateFrom),
          max: dayjs(p[0].dates.dateFrom),
        },
      }));
    }

    lineTypes.push(supplyLineDefault);
    lineTypes = adjustPointSizeInLines(lineTypes, chartPointRadius);

    const chartLines = setupChartLines(lineTypes, p, false, new Date(), 'Quantity');

    let lineTypesAfterHiding = hideNullLineTypes(lineTypes, p); //sets "hidden: true" to LineTypes in case of only 0 or null in data
    lineTypesAfterHiding = changeFillInLineTypesBasedOnHiddenLines(lineTypesAfterHiding); //sets fill property based on missing data

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- mixing of line and bar types
    const datasets = createDatasetsFromLineTypesAndGraphLines(
      lineTypesAfterHiding,
      chartLines,
    ) as unknown as ChartDataset<'line'>[];

    const chartdata = createChartDataFromDatasetsAndParsedMonthlyTransactions(datasets, p);

    return {
      lineTypes,
      p,
      chartLines,
      lineTypesAfterHiding,
      datasets,
      chartdata,
      nonSaleDataByDay,
    };
  }, [dailySales, dailyInbounds, dailyOutbounds, fetching, chartPointRadius]);

  const chartTooltipCallback: TooltipCallback = useCallback(
    (
      tooltipModel: TooltipModel<'line'>,
      lines: TLineType[],
      datasets: ChartDataset[],
      transactions: TParsedTransactions[],
      applicationDate: Date,
      chartRef: React.RefObject<Chart<'line'>>,
      currency: string,
      vat: number,
      valueColumnType: SkuSaleGraphValueColumnType,
      chartType: ParseTransactionsInput['type'],
      nonSaleDataByDay?: NonSaleDataByDay,
      // eslint-disable-next-line max-params -- mandated by the TooltipCallback type
    ): Date | undefined => {
      let result: Date | undefined = undefined;
      let isFuture = false;

      const dataPoint = tooltipModel.dataPoints[0];

      const monthIndex: number = dataPoint.dataIndex || 0;
      const monthlyData = transactions[monthIndex];

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- eslint thinks that monthlyData can NOT be undefined (but it can)
      if (monthlyData) {
        isFuture = monthlyData.dates.dateTo > applicationDate;
        result = monthlyData.dates.dateFrom;
      }

      const newPosition = computeTooltipPosition(chartRef, tooltipModel, `[id="${tooltipId}"]`);

      if (!isEqual(tooltipPosition, newPosition)) {
        if (newPosition.visible) {
          setTooltipSalesData(monthlyData);

          const tooltipGenerator = new SkuSaleChartTooltipGenerator();
          const tooltipData = tooltipGenerator.generateTooltipData(
            lines,
            monthlyData,
            Units.QUANTITY,
            currency,
            isFuture,
            vat,
            LINE_TYPES,
            lineTypeTooltipConfig,
            valueColumnType === 'avg',
            nonSaleDataByDay,
          );
          setTooltipData(tooltipData);
        }
        setTooltipPosition(newPosition);
      }

      return result;
    },
    [tooltipPosition],
  );

  const chartOptions: ChartOptions<'line'> = useMemo(() => {
    const dayDiff = chartDateMax.diff(chartDateMin, 'day');

    let xScaleTimeUnit: TimeUnit = 'day';
    if (dayDiff > 180) {
      xScaleTimeUnit = 'month';
    } else if (dayDiff > 90) {
      xScaleTimeUnit = 'week';
    }

    return {
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            filter: (legendItem, chartData) => {
              const { datasetIndex } = legendItem;
              if (datasetIndex !== undefined) {
                const dataset = chartData.datasets[datasetIndex];
                if ('metaExtensions' in dataset) {
                  return (
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- problems with module typing
                    (dataset.metaExtensions as ChartDatasetMetaExtension).displayLegend ?? false
                  );
                }
              }
              return false;
            },
          },
        },
        annotation: {},
        tooltip: {
          enabled: false,
          external: ({ tooltip }) => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- tooltip may be undefined when mouse is moved over the chart just after the modal appears
            if (!tooltip?.dataPoints || !chartRef.current) {
              // Handle cases where tooltip or dataPoints is undefined when mouse is moved over the chart just after the modal appears
              return;
            }

            return chartTooltipCallback(
              tooltip,
              data.lineTypesAfterHiding,
              data.datasets,
              data.p,
              currentDate,
              chartRef,
              currency,
              0,
              'none',
              'daily',
              data.nonSaleDataByDay,
            );
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          stacked: false,
          beginAtZero: false,
          ticks: {
            stepSize: 1,
          },
        },
        x: {
          type: 'time',
          min: chartDateMin.format('YYYY-MM-DD'),
          max: chartDateMax.format('YYYY-MM-DD'),
          time: {
            unit: xScaleTimeUnit,
            displayFormats: {
              day: 'dd MMM YYYY',
            },
          },
          ticks: {
            source: 'data',
            autoSkip: false,
            callback: scaleTicksCallback(xScaleTimeUnit),
          },
        },
      },
    };
  }, [
    chartDateMax,
    chartDateMin,
    chartTooltipCallback,
    currency,
    data.datasets,
    data.lineTypesAfterHiding,
    data.nonSaleDataByDay,
    data.p,
  ]);

  const scrollHandler: React.WheelEventHandler<HTMLCanvasElement> = useMemo(
    () => chartScrollZoomHandler(setDateRangeData, chartRef),
    [],
  );

  return (
    <Column $gap="1rem" $padding={1} $unit={1}>
      <Column $minHeight="500px" data-type="chart-container">
        <Line
          id="yd-chart"
          onWheel={scrollHandler}
          data={data.chartdata}
          options={chartOptions}
          ref={chartRef}
        />
        {tooltipSalesData && (
          <SaleChartTooltip
            data={tooltipData}
            position={tooltipPosition}
            rootId={tooltipId}
            tooltipType="date"
            dateFrom={tooltipSalesData.dates.dateFrom}
          />
        )}
      </Column>
      <SkuDailyTransactionChartSlider
        dateRangeData={dateRangeData}
        setDateRangeData={setDateRangeData}
      />
    </Column>
  );
};

export default SkuDailyTransactionsChart;
