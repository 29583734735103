import {
  CalculationFileResponse,
  CalculationFileType,
  DataRetrievalStatus,
} from '@ydistri/api-sdk';
import { COLORS, computeRemSize } from '@ydistri/ds';
import LoadingIndicator from '../../components/global/LoadingIndicator/LoadingIndicator';
import React, { useCallback } from 'react';
import { styled } from 'styled-components';
import { Link } from 'react-router';
import { Popover } from 'antd';
import { ImCloudDownload } from 'react-icons/im';

const CalculationFileLoadingIndicator = styled(LoadingIndicator).attrs({
  size: 'normal',
  bold: false,
})``;

const FileLink = styled(Link)`
  display: flex;
  flex-direction: row;
  color: black;
  text-decoration: none;
  cursor: pointer;
  gap: 0.5rem;

  &:hover {
    color: ${() => COLORS.PRIMARY};
  }
`;

interface CalculationFileProps {
  file: CalculationFileResponse;
  onFileClick?: (file: CalculationFileResponse) => void;
}

const fileLabels = new Map([
  [CalculationFileType.PairingXlsx, 'xlsx'],
  [CalculationFileType.PairingSpecific, 'erp'],
]);

const CalculationFile: React.FC<CalculationFileProps> = ({ file, onFileClick }) => {
  const label = fileLabels.get(file.calculationFileType) ?? 'file';

  const linkClickHandler = useCallback(() => {
    if (onFileClick) {
      onFileClick(file);
    }
  }, [file, onFileClick]);

  if (file.dataRetrievalStatus !== DataRetrievalStatus.Saved) {
    return <CalculationFileLoadingIndicator text={`Preparing ${label} file...`} />;
  }

  return (
    <Popover content={file.fileName}>
      <FileLink to={file.url} onClick={linkClickHandler}>
        <ImCloudDownload size={computeRemSize(20)} />
        {label}
      </FileLink>
    </Popover>
  );
};

export default CalculationFile;
