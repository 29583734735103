import { useCallback } from 'react';
import { setModalChanged, setModalScope, setModalType } from './scopeConfigurationSlice';
import { Scope, ScopeModalType } from './scopeLib';
import { AvailableModals, openModal } from '../../Modals/modalSlice';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useAppDispatch } from '../../../store';

export const useOpenScopeModal = (scope: Scope | undefined): (() => void) => {
  const templateOrCalculation = useTemplateOrCalculation();
  const dispatch = useAppDispatch();

  return useCallback(() => {
    if (templateOrCalculation.type === 'Calculation') return;
    dispatch(setModalScope(scope));
    dispatch(setModalType(ScopeModalType.EDIT));
    dispatch(setModalChanged(true));
    if (scope) {
      dispatch(
        openModal({
          type: AvailableModals.SCOPE_EDIT,
          data: {
            scopeId: scope.id,
          },
        }),
      );
    }
  }, [dispatch, scope, templateOrCalculation.type]);
};
