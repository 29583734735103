import { GenericInputModal } from '@ydistri/ds';
import React, { useCallback, useMemo, useState } from 'react';

import { addToast } from '@ydistri/utils';
import { EntityListResponse, EntityListType } from '@ydistri/api-sdk';
import { ModalMode } from '../../common/administrationItemsTypes';
import { ErrorType } from '../../../../apis/api';
import {
  EntityListUpdateRequest,
  useCreateEntityListMutation,
  useUpdateEntityListMutation,
} from '../apiEntityLists';
import { setSelectedEntityList } from '../entityListAdministrationSlice';
import { titleByEntityListType, urlByEntityListType } from '../entityListsLib';
import { useNavigate, useParams } from 'react-router';
import { RoutingPage } from '../../../../routes/routerSlice';
import { useAppDispatch } from '../../../../store';

interface CreateEntityListModalProps {
  entityListType: EntityListType;
  onClose: () => void;
  entityList?: EntityListResponse;
  confirmButtonLabel?: string;
  restrictedNames?: string[];
}

interface FormType {
  name: string;
}

const CreateEntityListModal: React.FC<CreateEntityListModalProps> = ({
  entityListType,
  onClose,
  entityList,
  confirmButtonLabel,
  restrictedNames,
}) => {
  const { projectShortName } = useParams();
  const navigate = useNavigate();
  const [isBusy, setIsBusy] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [addEntityList] = useCreateEntityListMutation();
  const [updateEntityList] = useUpdateEntityListMutation();

  const modalMode = useMemo(() => {
    if (entityList) {
      return ModalMode.EDIT;
    } else {
      return ModalMode.CREATE;
    }
  }, [entityList]);

  const dispatch = useAppDispatch();

  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]); //setIsOpened

  const createEntityList = useCallback(
    (values: FormType) => {
      if (!values.name || values.name.length === 0) {
        return;
      }

      setIsBusy(true);

      addEntityList({ entityListTypeId: entityListType, name: values.name })
        .unwrap()
        .then(result => {
          dispatch(setSelectedEntityList({ entityListType, data: result }));
          navigate(
            `/${projectShortName}/${RoutingPage.PROJECT_ADMINISTRATION}/${urlByEntityListType[entityListType]}/${result.entityListId}`,
          );
        })
        .catch((error: ErrorType) => {
          dispatch(
            addToast({
              message: `List was not created: ${error.response.data.Messages.join(', ')}`,
              isError: true,
            }),
          );
        })
        .finally(() => {
          setIsError(false);
          setIsBusy(false);
          closeModal();
        });
    },
    [addEntityList, entityListType, navigate, projectShortName, dispatch, closeModal],
  );

  const editEntityList = useCallback(
    (values: FormType) => {
      if (entityList) {
        setIsBusy(true);

        const request: EntityListUpdateRequest = {
          entityListId: entityList.entityListId,
          entityListTypeId: entityListType,
          name: values.name,
        };

        updateEntityList(request)
          .unwrap()
          .then(result => {
            dispatch(setSelectedEntityList({ entityListType, data: result }));
          })
          .catch((error: ErrorType) => {
            dispatch(
              addToast({
                message: `Product List was not updated: ${error.response.data.Messages.join(', ')}`,
                isError: true,
              }),
            );
          })
          .finally(() => {
            closeModal();
          });
      }
    },
    [closeModal, dispatch, entityList, entityListType, updateEntityList],
  );

  const onSubmit = useCallback(
    (value: string) => {
      if (modalMode === ModalMode.CREATE) {
        createEntityList({ name: value });
      } else {
        editEntityList({ name: value });
      }
    },
    [createEntityList, editEntityList, modalMode],
  );

  const validator = useCallback(
    (newValue: string) => {
      if (restrictedNames?.includes(newValue)) {
        return `${titleByEntityListType[entityListType]} with this name already exists, choose different name`;
      }
    },
    [entityListType, restrictedNames],
  );

  return (
    <GenericInputModal
      title={
        modalMode === ModalMode.CREATE
          ? `Create ${titleByEntityListType[entityListType]}`
          : `Edit ${titleByEntityListType[entityListType]} "${entityList?.name ?? ''}"`
      }
      placeholder={`Name of your ${titleByEntityListType[entityListType]}`}
      onConfirmed={onSubmit}
      onClose={closeModal}
      disabled={isBusy}
      submitButtonLabel={confirmButtonLabel ?? 'OK'}
      requiredMessage={`Name of the ${titleByEntityListType[entityListType]} is required`}
      error={isError}
      defaultValue={entityList?.name ?? ''}
      data-testid="CreateEntityListModal"
      validator={validator}
    />
  );
};
export default CreateEntityListModal;
