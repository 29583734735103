import {
  filterValidItems,
  getParseableString,
  getParserForContentType,
  validateTargetListContent,
} from './targetListsLib';
import {
  setHandlingMethod,
  setIsLoading,
  setTargetListItems,
  validationFinished,
} from './targetListAdministrationSlice';
import { useTargetListAdministrationState } from './useTargetListAdministrationState';
import { useCallback } from 'react';
import { IdentificationType } from '@ydistri/api-sdk';
import { AdministrationItemListHandlingMethod } from '../common/administrationItemsTypes';
import { markDuplicates } from '../common/administrationItemsLib';
import { addToast } from '@ydistri/utils';
import { TargetListItemsCompared, TargetListRow } from './targetListsTypes';
import { ErrorType } from '../../../apis/api';
import { useAppDispatch } from '../../../store';

export type ValidateItems = (newHandlingMethod?: AdministrationItemListHandlingMethod) => void;

const isAllParsed = (
  parsedTargetListItems: TargetListRow[],
  validation: TargetListItemsCompared,
) => {
  return filterValidItems(parsedTargetListItems).every(item => {
    const exactItemValidated = validation.items.findIndex(
      validatedItem =>
        validatedItem.inputData.productId === item.inputData.productId &&
        validatedItem.inputData.storeId === item.inputData.storeId &&
        validatedItem.inputData.count === item.inputData.count,
    );

    return exactItemValidated !== -1;
  });
};

const useValidateItems = (): ValidateItems => {
  const dispatch = useAppDispatch();
  const {
    validation,
    selectedTargetList,
    handlingMethod,
    warehouseIdentificationType,
    productIdentificationType,
    productItemsContent,
  } = useTargetListAdministrationState();

  return useCallback(
    (newHandlingMethod?: AdministrationItemListHandlingMethod) => {
      if (productItemsContent.length === 0) {
        return;
      }

      const parser = getParserForContentType('text/plain');
      if (!parser) {
        return;
      }

      const parsedRows = parser.parse(productItemsContent);
      if (!(parsedRows.length > 0 && selectedTargetList)) {
        return;
      }

      markDuplicates(parsedRows, (left, right) => {
        return (
          left.inputData.productId === right.inputData.productId &&
          left.inputData.storeId === right.inputData.storeId
        );
      });

      if (validation) {
        if (isAllParsed(parsedRows, validation)) {
          return;
        }
      }

      dispatch(setIsLoading(true));
      dispatch(setTargetListItems(parsedRows));

      validateTargetListContent(
        selectedTargetList,
        parsedRows,
        productIdentificationType,
        warehouseIdentificationType,
      )
        .then(data => {
          const errorItems = data.items.filter(validatedItem => validatedItem.isError);
          const plainErrorData = getParseableString(
            errorItems,
            data.result?.warehouseIdentificationTypeId ?? IdentificationType.CustomerId,
            data.result?.productIdentificationTypeId ?? IdentificationType.CustomerId,
            ';',
          );

          if (!handlingMethod) {
            dispatch(setHandlingMethod(AdministrationItemListHandlingMethod.ADD));
          }

          dispatch(validationFinished({ validation: data, errors: plainErrorData }));
          if (newHandlingMethod) {
            dispatch(setHandlingMethod(newHandlingMethod));
          }
        })
        .catch(error => {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const err = error as ErrorType;
          dispatch(
            addToast({
              message: `Validation failed: ${err.response.data.Messages.join(', ')}`,
              isError: true,
            }),
          );
        })
        .finally(() => {
          dispatch(setIsLoading(false));
        });
    },

    [
      dispatch,
      handlingMethod,
      productIdentificationType,
      productItemsContent,
      selectedTargetList,
      validation,
      warehouseIdentificationType,
    ],
  );
};

export default useValidateItems;
