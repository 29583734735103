import CalculationRedistributionSection from './CalculationRedistributionSection';
import { Row } from '@ydistri/ds';
import CalculationRedistributionProductSummary from './CalculationRedistributionProductSummary';
import CalculationRedistributionSourceSection from './CalculationRedistributionSourceSection';
import CalculationRedistributionSummary from './CalculationRedistributionSummary';
import CalculationRedistributionTargetSection from './CalculationRedistributionTargetSection';
import React, { useMemo, useState } from 'react';
import { useWindowDimension } from '../../../../../../hooks/useWindowDimension';
import { useGetCalculationQuery } from '../../../../apiCalculationDetail';
import { useSelectedPickingPositionFromUrl } from '../useSelectedPickingPositionFromUrl';

interface CalculationRedistributionSubpageContentProps {
  calculationId: number;
}

const CalculationRedistributionSubpageContent: React.FC<
  CalculationRedistributionSubpageContentProps
> = ({ calculationId }) => {
  const { data: selectedSKURedistribution } = useSelectedPickingPositionFromUrl();
  const windowDimensions = useWindowDimension();

  const [sourcePaneExpanded, setSourcePaneExpanded] = useState(false);
  const [targetPaneExpanded, setTargetPaneExpanded] = useState(false);

  const { data: calculation } = useGetCalculationQuery(calculationId);

  const height = useMemo(() => {
    const expanded = sourcePaneExpanded || targetPaneExpanded;
    const otherElementsHeight = expanded ? 955 + 400 : 955;
    return selectedSKURedistribution
      ? `${Math.max(windowDimensions.height - otherElementsHeight, 257)}px`
      : 'auto';
  }, [selectedSKURedistribution, sourcePaneExpanded, targetPaneExpanded, windowDimensions.height]);

  return (
    <>
      <CalculationRedistributionSection $height={height} $minHeight={height} />
      {selectedSKURedistribution && (
        <>
          <Row>
            <CalculationRedistributionProductSummary
              product={selectedSKURedistribution.product}
              skuId={selectedSKURedistribution.sourceSkuId}
              brand={selectedSKURedistribution.brand}
              calculationId={calculationId}
              description={selectedSKURedistribution.description}
            />
          </Row>
          <Row $flexWrap="nowrap">
            <CalculationRedistributionSourceSection onShowSkuInfoChanged={setSourcePaneExpanded} />
            <CalculationRedistributionSummary />
            <CalculationRedistributionTargetSection onShowSkuInfoChanged={setTargetPaneExpanded} />
          </Row>
        </>
      )}
    </>
  );
};

export default CalculationRedistributionSubpageContent;
