import React, { useMemo, useState } from 'react';
import { Column, Row, Text } from '@ydistri/ds';
import {
  CalculationCategoryPairingRequest,
  useGetCalculationCategoryPairingsQuery,
} from '../../../screens/Calculations/apiCalculationsCategories';
import { LogicalOperator, Operation } from '@ydistri/api-sdk';
import { SkuRedistributionPaneType } from '../../../screens/CalculationDetail/Redistribution/redistributionTypes';
import { Popover } from 'antd';

interface SkuPickingLineRendererProps {
  sourceOrTarget: SkuRedistributionPaneType;
  available: boolean;
  manual?: boolean;
  calculationId: number;
  skuId: number;
}

const SkuPickingLineRenderer: React.FC<SkuPickingLineRendererProps> = ({
  sourceOrTarget,
  available,
  manual = false,
  calculationId,
  skuId,
}) => {
  const [params] = useState<CalculationCategoryPairingRequest>({
    calculationId,
    categoryId: 1,
    skip: 0,
    top: 100,
    conditions: [
      {
        fieldName: `${sourceOrTarget}SkuId`,
        operation: Operation.Eq,
        value: skuId,
        logicalOperator: LogicalOperator.Or,
      },
    ],
  });

  const { data: redistributionData } = useGetCalculationCategoryPairingsQuery(params);

  const lineQuantities = useMemo(
    () => (redistributionData?.data ?? []).map(d => d.quantity),
    [redistributionData?.data],
  );
  const quantitySum = lineQuantities.reduce((p, c) => p + c, 0);

  const content = useMemo(
    () =>
      manual ? (
        <Text>Manual calculations do not have configurations.</Text>
      ) : (
        <Column $gap="0.5rem">
          {quantitySum > 0 && (
            <>
              <Text $bold>Picking lines of this SKU as {sourceOrTarget.toLowerCase()}:</Text>
              <table>
                <tbody>
                  {redistributionData?.data.map(d => (
                    <tr key={`${d.sourceSkuId}-${d.targetSkuId}`}>
                      <td style={{ textAlign: 'right' }}>
                        <Text $bold>{d.quantity} MU</Text>
                      </td>
                      <td>{sourceOrTarget === 'Source' ? 'to' : 'from'}</td>
                      <td>
                        <Text $bold>
                          {sourceOrTarget === 'Source' ? d.targetStore.name : d.sourceStore.name}
                        </Text>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </Column>
      ),
    [manual, quantitySum, sourceOrTarget, redistributionData?.data],
  );

  return (
    <Row $alignItems="center" $gap="0.25rem">
      {manual && available && quantitySum > 0 && (
        <Popover placement="topLeft" showArrow={false} content={content} trigger="hover">
          <Text $bold>
            ({quantitySum}MU / {lineQuantities.length}pp)
          </Text>
        </Popover>
      )}
      {!manual && available && quantitySum > 0 && (
        <Popover placement="topLeft" showArrow={false} content={content} trigger="hover">
          <Text $type="success" $bold>
            ({quantitySum}MU / {lineQuantities.length}pp)
          </Text>
        </Popover>
      )}
    </Row>
  );
};

export default SkuPickingLineRenderer;
