import { CalculationStatus } from '@ydistri/api-sdk';

/**
 * Return true if the template is editable (crashed, completed or outdated)
 * @param templateStatus
 */
export const isTemplateEditable = (templateStatus: CalculationStatus): boolean => {
  return (
    templateStatus === CalculationStatus.Crashed ||
    templateStatus === CalculationStatus.Completed ||
    templateStatus === CalculationStatus.Outdated
  );
};

/**
 * Return true if the template is running (pending, queued, to be queued or running)
 * @param templateStatus
 */
export const isTemplateRunning = (templateStatus: CalculationStatus): boolean => {
  return (
    templateStatus === CalculationStatus.Pending ||
    templateStatus === CalculationStatus.Queued ||
    templateStatus === CalculationStatus.ToBeQueued ||
    templateStatus === CalculationStatus.Running
  );
};

/**
 * Return true if the template is outdated or crashed
 * @param templateStatus
 */
export const isTemplateOutdated = (templateStatus: CalculationStatus): boolean => {
  return (
    templateStatus === CalculationStatus.Outdated || templateStatus === CalculationStatus.Crashed
  );
};

/**
 *
 * @param calculationStatus
 */
export const calculationStatusToNiceString = (calculationStatus: CalculationStatus): string => {
  switch (calculationStatus) {
    case CalculationStatus.Pending:
      return 'Pending';
    case CalculationStatus.Queued:
      return 'Queued';
    case CalculationStatus.ToBeQueued:
      return 'To be queued';
    case CalculationStatus.Running:
      return 'Running';
    case CalculationStatus.Completed:
      return 'Completed';
    case CalculationStatus.Outdated:
      return 'Outdated';
    case CalculationStatus.Crashed:
      return 'Failed';
    case CalculationStatus.SoftDeleted:
    default:
      return 'Unknown';
  }
};
