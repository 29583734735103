import React, { useEffect, useMemo } from 'react';
import { useTemplateOrCalculation } from '../../../hooks/useTemplateOrCalculation';
import { useGetTargetListsForConfigurationQuery } from '../../Configuration/apiTargetListConfiguration';
import { setSubpage } from '../../../routes/routerSlice';
import { CalculationDetailSubpages } from '../../Calculations/calculationsLib';
import { Helmet } from 'react-helmet-async';
import { MainContent, MainContentWrapper, Section } from '@ydistri/ds';
import { Spin } from 'antd';
import SectionTargetList from './SectionTargetList';
import { useAppDispatch } from '../../../store';

const CalculationTargetListStatistics: React.FC = () => {
  const dispatch = useAppDispatch();
  const templateOrCalculation = useTemplateOrCalculation();

  const { data: targetListConfig, isFetching } =
    useGetTargetListsForConfigurationQuery(templateOrCalculation);

  const targetList = useMemo(() => {
    if (targetListConfig && targetListConfig.length > 0) {
      return targetListConfig[0];
    }
  }, [targetListConfig]);

  useEffect(() => {
    dispatch(setSubpage(CalculationDetailSubpages.TARGET_LIST_STATISTICS));
  }, [dispatch]);

  return (
    <>
      <Helmet title={`Calculation ${templateOrCalculation.id} - Target List Statistics`} />
      <MainContentWrapper $flexDirection="column">
        <MainContent>
          {targetList && (
            <Section data-id="StatisticsTargetLists" id="StatisticsTargetListsSection">
              <Spin spinning={isFetching}>
                <SectionTargetList title={`Target list statistics - ${targetList.name ?? ''}`} />
              </Spin>
            </Section>
          )}
        </MainContent>
      </MainContentWrapper>
    </>
  );
};

export default CalculationTargetListStatistics;
