import { Helmet } from 'react-helmet-async';
import { MainContent, MainContentWrapper } from '@ydistri/ds';
import React from 'react';
import { useTemplateOrCalculation } from '../../../../../hooks/useTemplateOrCalculation';
import { useGetCalculationQuery } from '../../../apiCalculationDetail';
import CalculationRedistributionImportContent from './components/CalculationRedistributionImportContent';

const CalculationRedistributionImport: React.FC = () => {
  const calculationData = useTemplateOrCalculation();
  const { data: calculation, isFetching } = useGetCalculationQuery(calculationData.id);

  if (!isFetching && calculation) {
    return (
      <>
        <Helmet title={`Calculation ${calculation.id} - Redistribution Import`} />
        <MainContentWrapper>
          <MainContent>
            <CalculationRedistributionImportContent />
          </MainContent>
        </MainContentWrapper>
      </>
    );
  }
};

export default CalculationRedistributionImport;
