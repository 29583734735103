import React, { useEffect } from 'react';
import { setSubpage } from '../../../routes/routerSlice';
import { ConfigurationSubpage } from '../Configuration';
import ProductListConfigurations from './ProductListConfiguration/ProductListConfigurations';
import { styled } from 'styled-components';
import ProductListDetail from './ProductListDetail/ProductListDetail';
import { MainContent } from '@ydistri/ds';
import { useAppDispatch } from '../../../store';

const ConfigurationRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

const ConfigurationProductLists: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSubpage(ConfigurationSubpage.PRODUCTLISTS));
  });

  return (
    <MainContent>
      <ConfigurationRow>
        <ProductListConfigurations />
        <ProductListDetail />
      </ConfigurationRow>
    </MainContent>
  );
};

export default ConfigurationProductLists;
