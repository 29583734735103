import React, { useCallback } from 'react';
import { SubPageButton } from './SubPageButton';
import { PickingResultsPart } from '../pickingResultsLib';
import { setCurrentPart } from '../pickingResultsSlice';
import { useSelector } from 'react-redux';
import { ReduxState, useAppDispatch } from '../../../../store';
import { toEnumValue } from '../../../../lib/utils/utils';
import { ActionsBar } from '@ydistri/ds';

interface PickingResultsPartConfig {
  id: PickingResultsPart;
  label: string;
}

const parts: PickingResultsPartConfig[] = [
  { id: PickingResultsPart.EXECUTION, label: 'Execution' },
  { id: PickingResultsPart.ISSUES, label: 'Issues' },
  { id: PickingResultsPart.PERFORMANCE, label: 'Performance' },
];

const PickingResultsNavigation: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentPart = useSelector((state: ReduxState) => state.pickingResults.currentPart);

  const subPageChangeHandler = useCallback(
    (id: string) => {
      const value = toEnumValue(PickingResultsPart, id);
      if (value) {
        dispatch(setCurrentPart(value));
      }
    },
    [dispatch],
  );

  return (
    <ActionsBar moveToRight={true} wrap={false}>
      {parts.map(part => {
        return (
          <SubPageButton
            key={part.id}
            id={part.id}
            label={part.label}
            onClick={subPageChangeHandler}
            selected={currentPart === part.id}
          />
        );
      })}
    </ActionsBar>
  );
};

export default PickingResultsNavigation;
