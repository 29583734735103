/* eslint-disable -- no checks on this file */
//TODO - remove eslint disable

import { InternalAxiosRequestConfig } from 'axios';
import { Condition, LogicalOperator, SortDirection, Sorting } from '@ydistri/api-sdk';

export const runInterceptor = (req: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const queryParams: string[] = [];

  //these params are for OData, and will be prefixed with $ sign... all other params not included in this list will be added after, without dollar sign
  const baseParams: string[] = ['conditions', 'sortings', 'top', 'skip', 'search', 'inlineCount'];

  if (req.params) {
    let joinOperator = LogicalOperator.And;

    const filters: string[] = [];
    if (req.params.conditions && req.params.conditions.length > 0) {
      req.params.conditions.forEach((c: Condition) => {
        if (c.logicalOperator) {
          //TODO - implement this properly
          //conditions are ANDed together by default
          //if any condition in the array uses logicalOperator.Or, all conditions are ORed together
          //backend does not support grouped conditions yet so this is the only way to do it for now
          if (c.logicalOperator === LogicalOperator.Or) {
            joinOperator = LogicalOperator.Or;
          }
          console.log(
            'logicalOperator is not yet fully implemented in axios interceptor - if one condition contains the LogicalOperator.Or all conditions are ored together, otherwise AND is used',
          );
        }

        if (c.rawQuery) {
          filters.push(c.rawQuery);
        } else {
          if (c.fieldName && c.operation && c.value !== undefined) {
            filters.push(`${c.fieldName} ${c.operation} ${c.value}`);
          } else {
            console.log(
              'Something is missing in condition - fieldName, operation or value',
              c.fieldName,
              c.operation,
              c.value,
            );
          }
        }
      });
      if (filters.length > 0) {
        queryParams.push(`$filter=${filters.join(` ${joinOperator.toString()} `)}`);
      }
    }

    const sortings: string[] = [];
    if (req.params.sortings && req.params.sortings.length > 0) {
      req.params.sortings.forEach((s: Sorting) => {
        if (s.fieldName) {
          sortings.push(`${s.fieldName} ${s.direction ?? SortDirection.Asc}`);
        } else {
          console.log('Something is missing in sorting - fieldName', s.fieldName);
        }
      });
      if (sortings.length > 0) {
        queryParams.push(`$orderby=${sortings.join(',')}`);
      }
    }

    if (req.params.top) {
      queryParams.push(`$top=${req.params.top}`);
    }

    if (Object.hasOwn(req.params, 'skip') && req.params.skip >= 0) {
      queryParams.push(`$skip=${req.params.skip}`);
    }

    if (req.params.search) {
      queryParams.push(`$search=${encodeURIComponent(req.params.search)}`);
    }

    if (req.params.inlineCount) {
      queryParams.push(`$inlinecount`);
    }

    Object.keys(req.params).forEach(key => {
      if (!baseParams.includes(key)) {
        const value = req.params[key];
        if (value !== undefined) {
          queryParams.push(`${key}=${encodeURIComponent(value)}`);
        }
      }
    });
  }

  if (queryParams.length > 0) {
    req.url = `${req.url}?${queryParams.join('&')}`;
    req.params = {};
  }

  return req;
};
