import { CalculationActionStatus } from './CalculationActionStatusComponent';
import { getIconBasedOnStatusAndOverwrite } from '@ydistri/ds';
import { ConfigurationFieldStatus, getColorBasedOnStatusAndOverwrite } from '@ydistri/utils';
import React, { CSSProperties } from 'react';
import { CalculationActionType } from '../../Calculations/calculationsTypes';

const transformStatus = (status: CalculationActionStatus): ConfigurationFieldStatus => {
  switch (status) {
    case 'progress':
      return ConfigurationFieldStatus.LOADING;
    case 'success':
      return ConfigurationFieldStatus.SUCCESS;
    case 'error':
      return ConfigurationFieldStatus.ERROR;
    case 'ready':
      return ConfigurationFieldStatus.DEFAULT;
  }
};

export const getLabelBasedOnCalculationActionStatus = (
  status: CalculationActionStatus,
  actionType: CalculationActionType | undefined,
): string => {
  switch (status) {
    case 'progress': {
      switch (actionType) {
        case CalculationActionType.DELETE:
          return 'Deleting...';
        case CalculationActionType.PRIVATE:
          return 'Updating...';
        case CalculationActionType.UNSET_PRIVATE:
          return 'Updating...';
        case undefined:
          return 'In progress...';
      }
    }
    // eslint-disable-next-line no-fallthrough -- no break needed here - all cases return a value
    case 'success':
      return 'Success';
    case 'error':
      return 'Error';
    case 'ready': {
      switch (actionType) {
        case CalculationActionType.DELETE:
          return 'To be deleted';
        case CalculationActionType.PRIVATE:
          return 'To be updated';
        case CalculationActionType.UNSET_PRIVATE:
          return 'To be updated';
        case undefined:
          return 'To be done';
      }
    }
    // eslint-disable-next-line no-fallthrough -- no break needed here - all cases return a value
    default:
      return 'Unknown status';
  }
};

export const getTitleBasedOnCalculationActionType = (
  actionType: CalculationActionType | undefined,
): string => {
  switch (actionType) {
    case CalculationActionType.DELETE:
      return 'Delete calculations';
    case CalculationActionType.PRIVATE:
      return 'Make calculations private';
    case CalculationActionType.UNSET_PRIVATE:
      return 'Make calculations non-private ';
    case undefined:
      return 'Update calculations';
  }
};

export const getIconBasedOnCalculationActionStatus = (
  status: CalculationActionStatus,
): React.ReactNode => getIconBasedOnStatusAndOverwrite(transformStatus(status), false);

export const getColorBasedOnCalculationActionStatus = (
  status: CalculationActionStatus,
): CSSProperties['color'] =>
  getColorBasedOnStatusAndOverwrite(transformStatus(status), false, false);
