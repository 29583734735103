import React, { useCallback } from 'react';
import { Button, RowCentered, Title } from '@ydistri/ds';
import { HiOutlineArrowTopRightOnSquare } from 'react-icons/hi2';
import { StatisticsModalType } from '../statisticsLib';
import { openStatisticsModal } from '../statisticsSlice';
import { useAppDispatch } from '../../../../store';

interface TitleWithModalButtonProps {
  title?: string;
  icon?: React.ReactNode;
  modalType?: StatisticsModalType;
}

const openModalIcon = <HiOutlineArrowTopRightOnSquare />;

const TitleWithModalButton: React.FC<TitleWithModalButtonProps> = ({
  title = '',
  icon = openModalIcon,
  modalType,
}) => {
  const dispatch = useAppDispatch();

  const onClick = useCallback(() => {
    if (modalType) dispatch(openStatisticsModal(modalType));
  }, [dispatch, modalType]);

  return (
    <RowCentered $gap="2rem" $justifyContent="space-between">
      <Title>{title}</Title>
      {modalType && (
        <Button $colorVariant="ghost" icon={icon} onClick={onClick}>
          Show more
        </Button>
      )}
    </RowCentered>
  );
};

export default TitleWithModalButton;
