import React, { useMemo, useState } from 'react';
import {
  CalculationCategoryPairingRequest,
  useGetCalculationCategoryPairingsQuery,
} from '../../../screens/Calculations/apiCalculationsCategories';
import { LogicalOperator, Operation } from '@ydistri/api-sdk';
import { SkuRedistributionPaneType } from '../../../screens/CalculationDetail/Redistribution/redistributionTypes';
import LinkIcon from '../Link/LinkIcon';
import { generatePath, useParams } from 'react-router';
import { ROUTES } from '../../menu/menuLeftItemTemplate';

interface SkuOpenRendererProps {
  sourceOrTarget: SkuRedistributionPaneType;
  calculationId: number;
  skuId: number;
}

const SkuOpenRenderer: React.FC<SkuOpenRendererProps> = ({
  sourceOrTarget,
  calculationId,
  skuId,
}) => {
  const { projectShortName } = useParams();

  const [params] = useState<CalculationCategoryPairingRequest>({
    calculationId,
    categoryId: 1,
    skip: 0,
    top: 100,
    conditions: [
      {
        fieldName: `${sourceOrTarget}SkuId`,
        operation: Operation.Eq,
        value: skuId,
        logicalOperator: LogicalOperator.Or,
      },
    ],
  });

  const { data: redistributionData } = useGetCalculationCategoryPairingsQuery(params);

  const firstRow = useMemo(
    () => (redistributionData?.data ?? []).find(d => d.quantity > 0),
    [redistributionData?.data],
  );

  const calculationDetailPath = generatePath(ROUTES.calculationDetail, {
    projectShortName: projectShortName ?? '',
    calculationId,
    subpage: 'redistribution',
    slug: '1',
  });

  return (
    <LinkIcon
      url={`${calculationDetailPath}${firstRow ? `?p=${firstRow.sourceSkuId}-${firstRow.targetSkuId}` : ''}`}
    />
  );
};

export default SkuOpenRenderer;
