import React, { useMemo } from 'react';
import { fullMonthDiff, padLeft } from '@ydistri/utils';
import { Column, computeRemSize } from '@ydistri/ds';
import { format } from 'date-fns';
import { styled } from 'styled-components';
import { TooltipType } from './saleChartTooltipTypes';

const DATE_FORMAT_STRING = 'dd. MM. yyyy';

const StyledTooltipTitle = styled.div`
  width: 30%;
  min-width: calc(155rem / ${({ theme }) => theme.rootFontSize});
  padding: calc(3rem / ${({ theme }) => theme.rootFontSize});
  background-color: #fff;
  box-shadow: 0 calc(1rem / ${({ theme }) => theme.rootFontSize})
    calc(12rem / ${({ theme }) => theme.rootFontSize}) #888;
  margin: -30px auto auto;
  font-weight: 700;
  font-size: calc(14rem / ${({ theme }) => theme.rootFontSize});
`;

/**
 * Subtitle is displayed in different formatting.
 */
const TooltipSubTitle = styled.span`
  font-size: ${computeRemSize(10)};
  font-style: italic;
  color: darkgray;
`;

interface TooltipTitleProps {
  type: TooltipType;
  dateFrom: Date;
  currentDate?: Date;
  dateTo?: Date;
}

/**
 * Title of the tooltip. The title displays the year and month of the dateTo
 * and the date range of the data presented in the tooltip in the subtitle.
 * If the current application date is before the dateFrom, the title displays
 * the OUTLOOK text.
 * @param dateFrom date from which the data is presented
 * @param dateTo  date to which the data is presented
 * @param currentDate date set in the application
 * @param type indicates if the title should contain single date or month with the date range as subtitle
 * @constructor
 */
const TooltipTitle: React.FC<TooltipTitleProps> = ({ dateFrom, dateTo, currentDate, type }) => {
  const { title, subTitle } = useMemo(() => {
    if (type === 'date-range') {
      const startDateTxt = format(dateFrom, DATE_FORMAT_STRING);
      const endDateTxt = dateTo ? format(dateTo, DATE_FORMAT_STRING) : undefined;
      const subTitle = endDateTxt ? `(${startDateTxt} - ${endDateTxt})` : startDateTxt;

      let title = '';
      if (currentDate && currentDate <= dateFrom) {
        //we present future data
        title = `OUTLOOK ${fullMonthDiff(currentDate, dateTo) + 1}`;
      } else {
        const titleDate = dateTo ?? dateFrom;
        title = `${titleDate.getFullYear()} / ${padLeft(String(titleDate.getMonth() + 1), '0', 2)}`;
      }
      return { title, subTitle };
    } else {
      const dateTxt = format(dateFrom, DATE_FORMAT_STRING);
      return { title: dateTxt, subTitle: '' };
    }
  }, [currentDate, dateFrom, dateTo, type]);

  return (
    <StyledTooltipTitle>
      <Column $gap="0.25rem">
        {title}
        {subTitle.length > 0 && <TooltipSubTitle>{subTitle}</TooltipSubTitle>}
      </Column>
    </StyledTooltipTitle>
  );
};

export default TooltipTitle;
