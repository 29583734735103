import { ContentData } from './saleChartTooltipTypes';
import React from 'react';
import {
  ContentHeader,
  LineLabelHeaderCell,
  ValueHeaderCell,
} from './TooltipContentHelperComponents';
import TooltipContentRow from './TooltipContentRow';

interface TooltipContentProps {
  title: string;
  data: ContentData[];
  columnNames: string[];
}

/**
 * TooltipContent renders the content of the tooltip which is the table.
 * Table contains headers and rows.
 * @param title title of the section
 * @param data will be rendered in the body of the table
 * @param columnNames headers of the table
 * @param narrow
 * @constructor
 */
const TooltipContent: React.FC<TooltipContentProps> = ({ title, data, columnNames }) => {
  return (
    <>
      <thead>
        <ContentHeader>
          <LineLabelHeaderCell colSpan={2} key={title}>
            {title}
          </LineLabelHeaderCell>
          {columnNames.map(columnHead => {
            return <ValueHeaderCell key={columnHead}>{columnHead}</ValueHeaderCell>;
          })}
        </ContentHeader>
      </thead>
      <tbody>
        {data.map(row => (
          <TooltipContentRow data={row} key={row.label} />
        ))}
      </tbody>
    </>
  );
};

export default TooltipContent;
