import { Chart, TooltipModel } from 'chart.js';
import React from 'react';
import { computeRemSize } from '@ydistri/ds';
import { TooltipPosition } from '../../components/global/SalesChartTooltip/saleChartTooltipTypes';

const SLIGHT_TOOLTIP_OFFSET = 20;
const DOUBLE_SLIGHT_TOOLTIP_OFFSET = SLIGHT_TOOLTIP_OFFSET * 2;

/**
 * Given the chart and tooltip model, computes the position of the tooltip
 * @param chartRef Reference to the chart element
 * @param tooltipModel Tooltip model provided by the chart.js
 * @param tooltipSelector selector for the tooltip needed for the height of the tooltip
 */
export const computeTooltipPosition = (
  chartRef: React.RefObject<Chart<'line'>>,
  tooltipModel: TooltipModel<'line'>,
  tooltipSelector: string,
): TooltipPosition => {
  const newPosition: TooltipPosition = {
    visible: tooltipModel.opacity !== 0,
    left: 0,
    top: 0,
    right: 0,
  };

  if (chartRef.current === null) {
    return newPosition;
  }

  const chartPosition = chartRef.current.canvas.getBoundingClientRect();

  const mainElement = document.querySelector('main');
  const mainElementRectangle = mainElement?.getBoundingClientRect();
  const mainTopOffset = mainElementRectangle?.top ?? 0; //main element is not aligned with the viewport, there is a left menu and top part with project selector
  const mainLeftOffset = mainElementRectangle?.left ?? 0;

  const tooltipElement = document.querySelector(tooltipSelector);
  const tooltipElementHeight = tooltipElement?.getBoundingClientRect().height ?? 0;

  const tooltipFallsBelowChart = tooltipModel.caretY + tooltipElementHeight > chartPosition.height;
  if (tooltipFallsBelowChart) {
    newPosition.top =
      chartPosition.top +
      tooltipModel.caretY -
      mainTopOffset -
      (tooltipElementHeight + SLIGHT_TOOLTIP_OFFSET); //position the tooltip slightly above the data point
  } else {
    newPosition.top =
      chartPosition.top + tooltipModel.caretY - mainTopOffset + SLIGHT_TOOLTIP_OFFSET; //position the tooltip slightly below the data point
  }

  const mouseTooFarRight =
    tooltipModel.caretX > (chartPosition.width * 2) / 3 - chartPosition.width / 8;
  if (mouseTooFarRight) {
    newPosition.right = computeRemSize(
      document.body.clientWidth -
        chartPosition.right +
        (chartPosition.width - tooltipModel.caretX) +
        SLIGHT_TOOLTIP_OFFSET,
    );
    newPosition.left = 'auto';
  } else {
    newPosition.left =
      chartPosition.left + tooltipModel.caretX - mainLeftOffset + DOUBLE_SLIGHT_TOOLTIP_OFFSET; //position the tooltip slightly more to the right of the data point
    newPosition.right = 'auto';
  }

  return newPosition;
};
