import React, { useEffect, useMemo } from 'react';
import { createDebugLog } from '../../../lib/utils/logging';
import { Badge, Popover } from 'antd';
import { Text } from '@ydistri/ds';
import {
  getMessageInfoFromValidatorSectionMessages,
  ValidatorMessageSeverity,
  ValidatorStatus,
  validatorStatusBadgeColors,
  validatorStatusTextColors,
} from './validatorLib';
import { ValidatorButton } from './validatorStyledComponents';
import CreateCalculationButton from '../CreateCalculationButton';
import { useSelector } from 'react-redux';
import { ReduxState, useAppDispatch } from '../../../store';
import { setValidatorSectionMessageInfo, setValidatorStatus } from './validatorSlice';
import { useValidator } from './useValidator';
import ValidatorContent from './ValidatorContent';
import { AiOutlineCheck } from 'react-icons/ai';
import { LoadingOutlined } from '@ant-design/icons';

const debugLog = createDebugLog('Validator');

const Validator: React.FC = () => {
  const dispatch = useAppDispatch();
  const isLoading = useValidator();

  const modal = useSelector((state: ReduxState) => state.validator.modal);
  const status = useSelector((state: ReduxState) => state.validator.status);
  const sectionMessages = useSelector((state: ReduxState) => state.validator.sectionMessages);
  const sectionMessageInfo = useSelector((state: ReduxState) => state.validator.sectionMessageInfo);

  const buttonDisabled = sectionMessageInfo[ValidatorStatus.ERROR] > 0 || isLoading;

  useEffect(() => {
    debugLog('sectionMessages', sectionMessages);
    dispatch(
      setValidatorSectionMessageInfo(getMessageInfoFromValidatorSectionMessages(sectionMessages)),
    );
  }, [dispatch, sectionMessages]);

  useEffect(() => {
    if (isLoading) {
      dispatch(setValidatorStatus(ValidatorStatus.RUNNING));
    } else {
      debugLog('sectionMessageInfo', sectionMessageInfo);
      if (sectionMessageInfo[ValidatorMessageSeverity.ERROR] > 0) {
        dispatch(setValidatorStatus(ValidatorStatus.ERROR));
      } else if (sectionMessageInfo[ValidatorMessageSeverity.WARNING] > 0) {
        dispatch(setValidatorStatus(ValidatorStatus.WARNING));
      } else {
        dispatch(setValidatorStatus(ValidatorStatus.INFO));
      }
    }
  }, [dispatch, isLoading, sectionMessageInfo]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const popoverContent = useMemo(() => (modal ? undefined : <ValidatorContent />), [modal]);
  const errorCount = sectionMessageInfo[ValidatorMessageSeverity.ERROR];
  const warningCount = sectionMessageInfo[ValidatorMessageSeverity.WARNING];

  const icon = useMemo(() => {
    if (isLoading) return <LoadingOutlined />;
    if (errorCount === 0 && warningCount === 0) return <AiOutlineCheck />;
    return undefined;
  }, [isLoading, errorCount, warningCount]);

  return (
    <>
      <Popover content={popoverContent} placement="bottom">
        <ValidatorButton>
          <Text $size="large" $type={validatorStatusTextColors[status]}>
            Validator
          </Text>
          {icon && (
            <Text $size="large" $type={validatorStatusTextColors[status]}>
              {icon}
            </Text>
          )}
          {!icon && errorCount > 0 && (
            <Badge color={validatorStatusBadgeColors[ValidatorStatus.ERROR]} count={errorCount} />
          )}
          {!icon && warningCount > 0 && (
            <Badge
              color={validatorStatusBadgeColors[ValidatorStatus.WARNING]}
              count={warningCount}
            />
          )}
        </ValidatorButton>
      </Popover>
      <CreateCalculationButton disabled={buttonDisabled} />
    </>
  );
};

export default Validator;
