/* eslint-disable @typescript-eslint/naming-convention */
// noinspection JSUnusedGlobalSymbols

import * as Sentry from '@sentry/react';
import { HttpClient, Me } from '@ydistri/identity-sdk';
import { getOAuthUrl } from '../helpers';
import { runInterceptor } from '@ydistri/utils';

export const IdentityAPI = new HttpClient({
  baseURL: getOAuthUrl(),
});

IdentityAPI.instance.interceptors.request.use(
  req => runInterceptor(req),
  error => {
    Sentry.captureException(error);
    const errorMessage = error instanceof Error ? error.message : String(error);
    return Promise.reject(new Error(errorMessage));
  },
);

IdentityAPI.instance.interceptors.response.use(
  req => req,
  error => {
    /*
     When incorrect email is sent to "sendLinkForgetPasswordCreate", we get response in this format:

     {
       "Messages": [
          "Cannot find the user xxx@ydistri.com, because it does not exist."
       ]
     }

     */
    if (error.response?.data?.Messages) {
      return Promise.reject(new Error(error.response.data.Messages[0]));
    }
    return Promise.reject(new Error(error instanceof Error ? error.message : String(error)));
  },
);

export const MeCollection = new Me(IdentityAPI);
